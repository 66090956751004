import { requestPost } from "@/utils/request";
import store from '@/store'

// 获取短信详情
export function getSmsDetail(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/sms/detail`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 切换短信提醒关闭/打开
export function toggleSms(params) {
  return new Promise((resolve, reject) => {
    requestPost('/sms/toggle', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 更新短信提醒明细
export function modifySms(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/sms/modify`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 计算提醒周期
export function calcSms(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/sms/cal`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
