<template>
  <div class="visit-date option-out-div">
    <div v-for="(item, index) in visitSteps"
      :key="index"
      :class="handleClass(item.step_status, item.activation_status, index)"
    >
      <el-popover
        trigger="hover"
        title=""
        popper-class="define-visit-popover-expired"
        class="define-visit-popover new-visit"
        placement="bottom"
        :disabled="item.step_status != '85'"
        :open-delay="300">
        <div class="define-content">
          <img src="@/assets/image/warning_dialog.svg" width="18px" />
          该受试者的术后访视时间已过，请及时记录术后访视数据
        </div>
        <template slot="reference">
          <div>
            <div class="visit-period" @click="openQuestionaire(item)">
              <template v-if="item.step_status === '30'">
                <template v-if="['100', '220'].includes(row.group_type) && !(item.cure_status === '10' && item.cure_correlation_indices)">
                  <i class="el-icon-circle-check" />
                </template>
                <template v-else>
                  <i class="el-icon-success icon-submit" />
                </template>
              </template>
              <span v-else-if="item.step_status === '20'" class="circle-flag green"></span>
              <span v-else-if="item.step_status === '10'" class="circle-flag"></span>
              {{ item.step_display_name }}
            </div>
            <span class="divide-line"></span>
            <div class="visit-status" @click="openMRDStatus(item)">
            <i v-if="item.mrd.ctdna_result == 'Positive'" class="el-icon-circle-plus gene-positive" />
            <i v-else-if="item.mrd.ctdna_result == 'Negative'" class="el-icon-remove gene-nagative"/>
            <span v-else class="circle-flag"></span>
          </div>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    },
    stepList: {
      default: () => [],
      type: Array
    },
    stepCode: {
      default: '',
      type: String
    },
    type: {
      default: '',
      type: String
    }
  },
  computed: {
    visitSteps() {
      const stepItems = this.stepList.filter(item => {
        return ['CTPL00020070'].indexOf(item.pipeline_code) !== -1
      })
      stepItems.map(step => {
        step.mrd = step.task_list.find(item => item.task_display_name === 'MRD结果')
      })
      return stepItems
    }
  },
  methods: {
    openQuestionaire(item) {
      this.$emit('openQuestionaire', this.row, item)
    },
    handleClass(status, activationStatus, alert_interval) {
      const intervalClass = alert_interval >= 8 ? ' bigger' : ''
      let newClass = activationStatus === '10' ? ' not-allow' + intervalClass : intervalClass
      if (status == '30' || status == '29') {
        return 'option-inside-true' + newClass 
      } else if (status == '85') {
        return 'option-inside-expired' + newClass
      } else {
        return 'option-inside-false' + newClass
      }
    },
    openMRDStatus(item) {
      this.$emit('openMRD', this.row, item.step_code, item.mrd)
    }
  }
}
</script>
<style lang="scss" scoped>
.visit-date .icon-submit {
  margin-right: 3px;
  &.not-allow {
    cursor: not-allowed;
  }
}
.circle-flag {
  align-items: center;
  border: 1px solid #333;
  border-radius: 50%;
  display: flex;
  height: 8px;
  justify-content: center;
  margin-right: 3px;
  width: 8px;
  &.green {
    border-color: #50b240;
  }
}
.divide-line {
  width: 1px;
  height: 12px;
  margin-left: 3px;
  background: linear-gradient(to bottom, #fff 0%, #fff 8.3%, #d4d4d4 8.3%, #d4d4d4 91.7%, #fff 91.7%, #fff 100%);
}
.option-inside-true.clock, .option-inside-false.clock {
  width: 34px;
}
.visit-date div {
  width: 60px;
  .visit-period {
    width: 32px;
    line-height: 24px;
  }
  .visit-status {
    width: 16px;
    text-align: center;
    .circle-flag {
      margin-right: 0;
    }
    .gene-positive {
      color: #F6CAAA;
      margin-left: 3px;
      margin-bottom: 0;
    }
    .gene-nagative {
      color: #A0D797;
      margin-left: 3px;
      margin-bottom: 0;
    }
  }
  &.not-allow {
    cursor: not-allowed;
    color: #d4d4d4;
    border-color: #d4d4d4;
    .circle-flag {
      border: 1px solid #d4d4d4;
      background: #f2f2f2;
    }
    .visit-period {
      cursor: not-allowed;
    }
    .visit-status {
      cursor: not-allowed;
    }
    .gene-positive {
      color: #D4D4D4;
    }
    .gene-nagative {
      color: #D4D4D4;
    }
  }
  &.bigger {
    flex: 1 1 120px;
    width: 120px;
    .visit-period {
      width: 50px;
    }
    .visit-status {
      margin-left: 4px;
    }
  }
  .clock {
    width: 12px;
    margin: 0;
  }
}

.visit-date {
  display: inline-flex;
}
</style>
<style lang="scss">

.define-visit-popover.new-visit {
  height: 24px;
  line-height: 24px;
  .el-popover__reference-wrapper {
    display: inline-block;
    width: 60px;
  }
}
.define-visit-popover-expired {
  padding: 8px;
  font-size: 12px;
  &.el-popover[x-placement^=bottom] {
    margin-top: 8px;
  }
  > .define-content {
    display: flex;
    align-items: center;
    > img {
      margin-right: 2px;
    }
  }
}
</style>