<template>
  <el-dialog
    title="提醒"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="genetrials-common-dialog modal-action-remind"
    width="400px"
    append-to-body
    @close="closeDialog"
  >
    <div class="modal-main">
      <div v-if="!actionRemindInfo.isEmptyStep">
        该受试者已经提交过CRF表，为防止误操作，禁止删除已经提交数据的受试者。如果一定要删除，请联系博瑞哲工作人员操作。
      </div>
      <div v-else>
        是否确定删除受试者?
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <template v-if="!actionRemindInfo.isEmptyStep">
        <el-button type="primary" @click="closeDialog" style="border-radius">确定</el-button>
      </template>
      <template v-else>
        <el-button @click="closeDialog" style="border-radius">取消</el-button>
        <el-button type="primary" @click="handleConfirm" style="border-radius">确定</el-button>
      </template>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    actionRemindInfo: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    handleConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss">
.genetrials-common-dialog.modal-action-remind {
  .el-dialog__body {
    .modal-main {
      padding-bottom: 15px;
      color: #333;
    }
  }
}
</style>