<template>
  <div class="sys-header-container" :style="{'background': isShow ? '#f8f9fb' : ''}">
    <div class="header-name">
      {{ showHeaderName }}
      <span class="subName">{{ showSubHeaderName }}</span>
    </div>
    <el-button-group class="header-btn-group">
      <el-dropdown>
        <span class="el-dropdown-link welcome-guest">
          {{ userName }}，您好<i class="el-icon-arrow-right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-on:click.native="logOut"
            >退出登录</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </el-button-group>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import router from "../router";
import store from "../store";
import { getHeaderInfo, getMenuInfo } from '@/utils/menuInfo'

export default {
  name: "SysHeader",
  props: {},
  data() {
    return {
      pageUrl: '',
      headerName: {},
      subHeaderName: {}
    };
  },
  watch: {
    '$store.state.currentProject': {
      handler(val) {
        if (val) {
          if (val =='CTPJ00000001') {
            const info = getHeaderInfo(val)
            this.headerName = info.headerName
            this.subHeaderName = info.subHeaderName
          } else {
            const info = getMenuInfo(val)
            this.headerName = info.reduce((temp, cur) => {
              temp[cur.url] = cur.label
              return temp
            }, {})
            this.subHeaderName = {}
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    isShow: {
      get() {
        if (this.$route.path.includes("/home")) {
          return true
        } else {
          return false
        }
      }
    },
    userName: {
      get() {
        if (store.state.user.userName) {
          return store.state.user.userName
        } else {
          return localStorage.getItem('userName') || ''
        }
      }
    },
    showHeaderName: {
      get() {
        let path = this.$route.path
        if (this.$route.path === '/CTPJ00000001/detail') {
          let query = this.$route?.query?.page.replace('ctac_group_', '')
          return  this.headerName[path + '/' + query]
        } else {
          return this.headerName[path]
        }
      }
    },
    showSubHeaderName: {
      get() {
        let path = this.$route.path
        if (this.$route.path === '/CTPJ00000001/detail') {
          let query = this.$route?.query?.page.replace('ctac_group_', '')
          if (this.subHeaderName[path + '/' + query]) {
            return '(' + this.subHeaderName[path + '/' + query]  + ')'
          } else {
            return ''
          }
        } else {
          return ''
        }
      }
    },
  },
  created() {
    // 获取当前可选项目
    store.dispatch('getAllProjects')
  },
  mounted() {
    // 获取用户
    if (store.state.accessToken) {
      if (store.state.user.userId == "") {
        store
          .dispatch("fetchCurrUser")
          .catch((e) => {
            store.commit("logout");
          });
      }
    }
  },
  methods: {
    logOut() {
      store.dispatch("logout");
      router.push("/login");
    },
  }
};
</script>

<style lang="scss">
.sys-header-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  min-height: 32px;
  /* width: 99%; */
  background: #fff;
  border-bottom: 1px solid #DCDFE6;
  .header-name {
    flex: 1;
    font-size: 15px;
    color: #1c1f26;
    font-weight: 600;
    .subName {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.sys-header-container .header-btn-group {
  
}

.sys-header-container .welcome-guest {
}
.sys-header-container .welcome-guest:hover {
  cursor: pointer;
}
</style>
