<template>
  <div class="option-out-div">
    <div v-for="(item, key) in p1p2Steps"
      class="option-content"
      :key="key"
      :class="item.step_status === '30' ?
        'option-inside option-inside-true' : 'option-inside option-inside-false'"
      >
        <div class="visit-period" @click="openQuestionaire(item)">
          <i v-if="item.step_status === '30'" class="el-icon-success icon-submit" />
          <span v-else-if="item.step_status === '20'" class="circle-flag green"></span>
          <span v-else-if="item.step_status === '10'" class="circle-flag"></span>
          <img v-else class="icon-submit" src="@/assets/image/gene-expired.svg" />
          {{ item.step_display_name }}
        </div>
        <span class="divide-line"></span>
        <div class="visit-status" @click="openMRDStatus(item)">
          <i v-if="item.mrd.ctdna_result == 'Positive'" class="el-icon-circle-plus gene-positive" />
          <i v-else-if="item.mrd.ctdna_result == 'Negative'" class="el-icon-remove gene-nagative"/>
          <span v-else class="circle-flag"></span>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    },
    stepList: {
      default: () => {
        return []
      },
      type: Array
    },
    type: {
      default: '',
      type: String
    }
  },
  computed: {
    p1p2Steps() {
      const stepItems = this.stepList.filter(item => {
        return ['CTST00020050'].indexOf(item.step_code) !== -1
      })
      stepItems.map(step => {
        step.mrd = step.task_list.find(item => item.task_display_name === 'MRD结果')
      })
      return stepItems
    },
  },
  methods: {
    openQuestionaire(item = {}) {
      this.$emit('openQuestionaire', this.row, item)
    },
    openMRDStatus(item) {
      this.$emit('openMRD', this.row, item.step_code, item.mrd)
    }
  }
}
</script>
<style lang="scss" scoped>
.option-inside-true {
  vertical-align: bottom;
  .el-icon-circle-plus {
    color: #F6CAAA;
    margin-left: 3px;
  }
  .el-icon-remove {
    color: #A0D797;
    margin-left: 3px;
  }
}
.icon-submit {
  margin-right: 3px;
}
.divide-line {
  width: 1px;
  height: 12px;
  margin-left: 3px;
  background: linear-gradient(to bottom, #fff 0%, #fff 8.3%, #d4d4d4 8.3%, #d4d4d4 91.7%, #fff 91.7%, #fff 100%);
}
.circle-flag {
  align-items: center;
  border: 1px solid #333;
  border-radius: 50%;
  display: flex;
  height: 8px;
  justify-content: center;
  margin-right: 3px;
  width: 8px;
  &.green {
    border-color: #50b240;
  }
}
.visit-period {
  width: 32px;
  line-height: 24px;
}
.visit-status {
  width: 16px;
  text-align: center;
  .circle-flag {
    margin-right: 0;
  }
  .gene-positive {
    color: #F6CAAA;
    margin-left: 3px;
    margin-bottom: 0;
  }
  .gene-nagative {
    color: #A0D797;
    margin-left: 3px;
    margin-bottom: 0;
  }
}
</style>