import { render, staticRenderFns } from "./LogDetail.vue?vue&type=template&id=196f3a92"
import script from "./LogDetail.vue?vue&type=script&lang=js"
export * from "./LogDetail.vue?vue&type=script&lang=js"
import style0 from "./LogDetail.vue?vue&type=style&index=0&id=196f3a92&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_npm_i_ctms_front/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports