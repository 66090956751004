export default {
  type: 'ngs-result',
  config: [
  {
    key: 'ctdna_result',
    label: 'MRD结果',
    type: 2,
    span: 7,
    options: [
      {
        label: 'Positive',
        value: '阳性（+）'
      },
      {
        label: 'Negative',
        value: '阴性（-）'
      }
    ]
  }
]}