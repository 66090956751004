import Home from '@/views/NSCLC/Home'
import Index from '@/views/NSCLC/Index'
import Standard from '@/views/NSCLC/Standard'
import MRD from '@/views/NSCLC/MRD'
import User from '@/views/NSCLC/User'
import Log from '@/views/NSCLC/Log'
import Disenroll from '@/views/NSCLC/Disenroll'

const CTPJ00000050Routes = [
  {
    path: '/CTPJ00000050',
    component: {render: (h) => h('router-view')},
    children: [
      {
        path: 'home',
        component: Home,
        meta: { requireLogin: true }
      },
      {
        path: 'index',
        component: Index,
        meta: { requireLogin: true }
      },
      {
        path: 'standard',
        component: Standard,
        meta: { requireLogin: true }
      },
      {
        path: 'MRD',
        component: MRD,
        meta: { requireLogin: true }
      },
      {
        path: 'disenroll',
        component: Disenroll,
        meta: { requireLogin: true }
      },
      {
        path: 'user',
        component: User,
        meta: { requireLogin: true }
      },
      {
        path: 'log',
        component: Log,
        meta: { requireLogin: true }
      }
    ]
  }
]

export default CTPJ00000050Routes
