import { render, staticRenderFns } from "./ScreenEnrollmentCol.vue?vue&type=template&id=d810bb50&scoped=true"
import script from "./ScreenEnrollmentCol.vue?vue&type=script&lang=js"
export * from "./ScreenEnrollmentCol.vue?vue&type=script&lang=js"
import style0 from "./ScreenEnrollmentCol.vue?vue&type=style&index=0&id=d810bb50&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_npm_i_ctms_front/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d810bb50",
  null
  
)

export default component.exports