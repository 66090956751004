export default {
  type: 'driver-gene',
  config: [
  {
    key: 'driver_gene',
    label: 'NGS驱动基因',
    type: 2,
    span: 24,
    options: [
      {
        label: '10',
        value: 'EGFR敏感突变'
      },
      {
        label: '20',
        value: 'ALK融合'
      },
      {
        label: '30',
        value: '检测到其他驱动基因变异，请说明',
        showValue: '检测到其他驱动基因变异',
        define_input_key: 'driver_gene_remark'
      },
      {
        label: '00',
        value: '未检测到驱动基因'
      }
    ],
  }
]}