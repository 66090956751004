<template>
  <div class="questionaire-preview-wrapper">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="问卷code">
        <el-input v-model="form.questionnaire_code"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="openQ">打开问卷</el-button>
      </el-form-item>
    </el-form>
    <dialog-questionaire
      v-if="showQuestionaire"
      title="问卷详情"
      :dialogVisible="showQuestionaire"
      :questionnaireInfo="form"
      prefix="CTPJ00000050"
      @closeDialog="showQuestionaire = false"
    />
  </div>
</template>
<script>
import DialogQuestionaire from '@/components/dialog/DialogQuestionaire.vue';
import { notifyError } from "../helpers/message.js";

export default {
  components: {
    DialogQuestionaire
  },
  data() {
    return {
      form: {
        questionnaire_code: ''
      },
      showQuestionaire: false
    }
  },
  methods: {
    openQ() {
      if (!this.form.questionnaire_code) {
        notifyError('请输入问卷code')
        return
      }
      this.showQuestionaire = true
    }
  }
}
</script>
<style lang="scss" scoped>
.questionaire-preview-wrapper {
  width: 300px;
  margin: 50px 100px;
}
</style>