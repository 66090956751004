<template>
  <div class="dialog-gene-state-all">
    <el-dialog
      :title="dialogTitle"
      :visible="step_1"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog dialog-gene-state step_1"
      width="540px"
      append-to-body
      :before-close="closeAllDialog"
    >
      <div class="modal-main">
        <p class="p-info">受试者姓名：{{ geneInfo.name }}</p>
        <p class="p-info define-p-bottom">受试者编号：{{ geneInfo.patient_code }}</p>
        <p class="p-info">请输入订单号</p>
        <el-input size="mini" v-model="inputInfo.lims_order_code" @keyup.enter.native="handleStep('1', '2')"/>
        <span class="span-info">系统将根据订单号获取检测报告，请确保订单号输入无误</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleStep('1', '2')" :loading="loading" class="define-button-width">下一步</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogTitle"
      :visible="step_2"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog dialog-gene-state step_2"
      width="540px"
      append-to-body
      :before-close="closeAllDialog"
    >
      <div class="modal-main">
        <p class="p-info define-p-bottom">您输入的订单号：{{ inputInfo.lims_order_code }}</p>
        <p class="p-info define-p-bottom">订单号姓名：{{ inputInfo.patient_name }}</p>
        <p class="p-info define-p-bottom" style="margin-bottom: 30px">本系统中受试者姓名：{{ geneInfo.name }}</p>
        <el-checkbox v-model="inputInfo.isConfirmed">我确认此订单为该受试者订单</el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleStep('2', '3')" class="define-button-width">下一步</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogTitle"
      :visible="step_3"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog dialog-gene-state step_3"
      width="540px"
      append-to-body
      :before-close="closeAllDialog"
    >
      <div class="modal-main">
        <p class="p-info define-p-bottom">请补充采样日期</p>
        <el-date-picker v-model="inputInfo.sampling_date" size="mini" value-format="yyyy-MM-dd" />
        <p class="p-info" style="margin-top: 10px;margin-bottom: 15px" v-if="geneInfo.number_of_fills > 0">ctDNA结果</p>
        <el-radio-group v-model="inputInfo.ctdna_result" v-if="geneInfo.number_of_fills > 0" @change="handleDNAChange">
          <el-radio 
            v-for="radio in ctdnaArray"
            :key="radio.value"
            :label="radio.value"
          >
            {{ radio.label }}
          </el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleStep('3', '4')" :loading="loading" class="define-button-width">下一步</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogTitle"
      :visible="step_4"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog dialog-gene-state step_4"
      width="540px"
      append-to-body
      :before-close="closeAllDialog"
    >
      <div class="modal-main">
        <p class="p-info define-p-bottom">订单号：{{ inputInfo.lims_order_code }}</p>
        <p class="p-info define-p-bottom">采样日期：{{ inputInfo.sampling_date }}</p>
        <p class="p-info define-p-bottom" v-if="geneInfo.number_of_fills > 0">ctDNA结果：{{ inputInfo.ctdnaResultName }}</p>
        <p class="p-info">检测报告：
          <span v-if="inputInfo.file_list">
            <a
              v-for="file in inputInfo.file_list"
              :key="file.oss_key"
              :href="file.url"
              target="_blank"
              style="display: block;text-decoration: none;font-weight: 500;color: RGB(12,99,230)"
            >
              {{ file.file_name }}
            </a>
          </span>
        </p>
        <span class="span-info">如有问题，点击<a @click="handleReset">重新获取检测报告</a></span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeAllDialog" style="width: 110px">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getReportInfoFromLIMS, postReportLIMS, getReportDetailFromLIMS } from '@/apis/ngsReport'
import axios from 'axios'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    geneInfo: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'geneInfo': {
      handler(val) {
        if (val.lims_order_code) {
          // 到第四页
          this.step_1 = false
          this.step_4 = true
        }
      },
      deep: true,
      immediate: true
    },
    step_4: {
      handler(val) {
        if (val) {
          // 第四页展示时，再次获取信息
          this.loading = true
          getReportDetailFromLIMS(this.geneInfo.patient_id, this.geneInfo.number_of_fills).then(res => {
            if (res.code == 200) {
              let result = res.entity
              if (result.file_list) {
                result.file_list.forEach(ii => {
                  if (ii.url) {
                    ii.url = ii.url.replace('http://', 'https://')
                  }
                })
              }
              this.inputInfo = {
                ...this.inputInfo,
                ...result
              }
              if (res.entity?.ctdna_result == 'Positive' || res.entity?.ctdna_result == 'Negative') {
                this.handleDNAChange(res.entity?.ctdna_result)
              }
            } else {
              this.handleMessage('error', res.message)
            }
          }).finally(() => {
            this.handleLoading()
          })
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      geneTitle: {
        0: '第一次基因检测（术前组织样本）',
        10: '第二次基因检测（术前血液）',
        20: '第三次基因检测（术后血液）',
        30: '第四次基因检测（术后4个月血液）',
        40: '第五次基因检测（术后7个月血液）',
        50: '其他基因检测1',
        60: '其他基因检测2',
        70: '其他基因检测3'
      },
      step_1: true,
      step_2: false,
      step_3: false,
      step_4: false,
      loading: false,
      inputInfo: {
        lims_order_code: '',
        patient_name: '',
        isConfirmed: false,
        sampling_date: '',
        ctdna_result: '',
        ctdnaResultName: ''
      },
      ctdnaArray: [
        {
          label: '阳性（+）',
          value: 'Positive'
        },
        {
          label: '阴性（-）',
          value: 'Negative'
        }
      ],
      isNeedRefresh: false
    }
  },
  computed: {
    dialogTitle: {
      get() {
        return this.geneTitle[this.geneInfo.number_of_fills] ? this.geneTitle[this.geneInfo.number_of_fills] : ''
      }
    }
  },
  methods: {
    closeAllDialog() {
      if (this.isNeedRefresh) {
        this.$emit('refresh')
      }
      this.$emit('closeDialog')
    },
    handleStep(from, to) {
      if (from == '1') {
        // 判断
        if (this.inputInfo.lims_order_code) {
          this.loading = true
          getReportInfoFromLIMS(this.inputInfo.lims_order_code).then(res => {
            if (res.code == 200) {
              this.inputInfo.patient_name = res.entity?.patient_name
              this.inputInfo.sampling_date = res.entity?.sampling_date
              this.inputInfo.ctdna_result = res.entity && res.entity.ctdna_result != '.' ? res.entity.ctdna_result : ''
              if (this.inputInfo.ctdna_result) this.handleDNAChange(this.inputInfo.ctdna_result)
              this.handleNext(from, to)
            } else {
              this.handleMessage('error', '未检测到该订单号的报告，请确认订单号是否正确')
            }
          }).finally(() => {
            this.handleLoading()
          })
        } else {
          this.handleMessage('error', '请先输入订单号')
        }
      } else if (from == '2') {
        if (this.inputInfo.isConfirmed) {
          this.handleNext(from, to)
        } else {
          this.handleMessage('error', '请先确认')
        }
      } else if (from == '3') {
        if (this.inputInfo.sampling_date && (this.geneInfo.number_of_fills == 0 || this.geneInfo.number_of_fills > 0 && this.inputInfo.ctdna_result)) {
          this.loading = true
          const params = {
            patient_id: this.geneInfo.patient_id,
            number_of_fills: this.geneInfo.number_of_fills,
            lims_order_code: this.inputInfo.lims_order_code,
            sampling_date: this.inputInfo.sampling_date,
            receiving_date: '',
            ctdna_result: this.inputInfo.ctdna_result
          }
          postReportLIMS(params).then(res => {
            if (res.code == 200) {
              this.handleNext(from, to)
              this.isNeedRefresh = true
            } else {
              this.handleMessage('error', res.message)
            }
          }).finally(() => {
            this.handleLoading()
          })
        } else {
          this.handleMessage('error', '请先填写')
        }
      }
    },
    handleDNAChange(val) {
      const find = this.ctdnaArray.filter(ii => ii.value == val)
      if (find.length > 0) {
        this.inputInfo.ctdnaResultName = find[0].label
      }
    },
    handleReset() {
      this.inputInfo = {
        lims_order_code: '',
        patient_name: '',
        isConfirmed: false,
        sampling_date: '',
        ctdna_result: '',
        ctdnaResultName: ''
      }
      this.step_4 = false
      this.step_1 = true
    },
    handleReportLook() {
      if (this.inputInfo.url) {
        const opened = window.open(this.inputInfo.url.replace('http://', 'https://'), '_blank')
        opened.onload = function () {
          opened.close()
        }
      }
    },
    handleMessage(type, message) {
      this.$message({
        type,
        message
      })
    },
    handleNext(from ,to) {
      this['step_' + from] = false
      this['step_' + to] = true
    },
    handleLoading() {
      setTimeout(() => {
        this.loading = false
      }, 234)
    }
  }
}
</script>
<style lang="scss">
.genetrials-common-dialog.dialog-gene-state {
  .el-dialog__body {
    padding: 20px;
  }
  .modal-main {
    padding: 10px 60px;
    display: flex;
    flex-direction: column;
    .p-info {
      font-size: 14px;
      margin: 0;
      font-weight: 650;
      color: #333;
      margin-bottom: 8px;
      &.define-p-bottom {
        margin-bottom: 20px;
      }
    }
    .span-info {
      font-size: 12px;
      color: #999;
      margin-top: 8px;
      a {
        color: RGB(12,99,230);
        cursor: pointer;
      }
    }
  }
  .define-button-width {
    width: 320px;
  }
}
</style>
