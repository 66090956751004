const step_1_questionnaire = [
  {
    key: 'external_patient_code',
    label: '受试者编号',
    type: 1,
    maxlength: 20
  },
  {
    key: 'p1_value',
    label: 'P1',
    type: 2,
    options: [
      {
        label: '10',
        value: '阳性+'
      },
      {
        label: '0',
        value: '阴性-'
      }
    ],
  },
  {
    key: 'p2_value',
    label: 'P2',
    type: 2,
    options: [
      {
        label: '10',
        value: '阳性+'
      },
      {
        label: '0',
        value: '阴性-'
      }
    ],
  },
  {
    key: 'stage',
    label: '临床分期',
    type: 2,
    options: [
      {
        label: '20',
        value: 'II期'
      },
      {
        label: '30',
        value: 'IIIA期'
      }
    ]
  },
  {
    key: 'mutation_state',
    label: 'EGFR突变状态',
    type: 2,
    options: [
      {
        label: '10',
        value: '19del'
      },
      {
        label: '20',
        value: 'L858R'
      }
    ]
  }
]
const step_1_questionnaire_form = [
  'name',
  'external_patient_code',
  'principal_id',
  'gender',
  'date_of_birth',
  'ethnic_group',
  'height',
  'weight',
  'blood_pressure_low',
  'blood_pressure_high',
  'heart_rhythm',
  'smoking_history',
  'past_medical_history',
  'past_medical_history_remark',
  'tumor_family_history',
  'tumor_family_history_remark',
  'date_of_surgery',
  'preoperative_blood_type',
  'stage',
  'pathologic_subtype',
]
export {
  step_1_questionnaire,
  step_1_questionnaire_form
}