export const p1p2Options = [{
  key: '10',
  label: '阳性'
},
{
  key: '0',
  label: '阴性'
}]

export const stageOptions = [{
  key: '20',
  label: 'II期'
},
{
  key: '30',
  label: 'IIIA期'
}]

export const mutationOptions = [{
  key: '10',
  label: '19del'
},
{
  key: '20',
  label: 'L858R'
}]

export const groupTypeOptions = [{
  key: '100',
  label: '① 观察组'
},
{
  key: '200',
  label: ' ② 奥希替尼治疗组'
},
{
  key: '300',
  label: ' ③ 含铂双药化疗奥西替尼治疗3年'
}]