<template>
  <div class="option-out-div screen-enrollment-col">
    <div v-for="(item, key) in topSteps"
      :key="key"
      class="option-content"
      :class="item.step_status === '30' ?
        'option-inside option-inside-true' : 'option-inside option-inside-false'"
      >
      <template v-if="item.step_code === 'CTST00020040'">
        <div class="visit-period" @click="openQuestionaire([item])">
          <i v-if="item?.step_status === '30'" class="el-icon-success icon-submit" />
          <span v-else-if="item?.step_status === '20'" class="circle-flag green"></span>
          <span v-else-if="item?.step_status === '10'" class="circle-flag"></span>
          <img v-else class="icon-submit" src="@/assets/image/gene-expired.svg" />
          {{ item.step_display_name }}
        </div>
        <span class="divide-line"></span>
        <div class="visit-status" @click="openMRDStatus(item)">
          <i v-if="item?.mrd?.ctdna_result == 'Positive'" class="el-icon-circle-plus gene-positive" />
          <i v-else-if="item?.mrd?.ctdna_result == 'Negative'" class="el-icon-remove gene-nagative"/>
          <span v-else class="circle-flag"></span>
        </div>
      </template>
      <div v-else @click="openQuestionaire([item])">
        <img v-if="item?.step_status === '30'" class="icon-submit" src="@/assets/image/ok.svg" />
        <span v-else-if="item?.step_status === '20'" class="circle-flag green"></span>
        <span v-else class="circle-flag"></span>
        {{ item.step_display_name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    },
    questionnaireMenu: {
      default: () => {
        return []
      },
      type: Array
    },
    stepList: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    topSteps() {
      if (!this.stepList || this.stepList.length === 0) return []
      let temp = this.stepList.filter(item => {
        return ['CTST00020020', 'CTST00020030'].indexOf(item.step_code) !== -1
      })
      let temp1 = this.stepList.filter(item => {
        return ['CTST00020040'].indexOf(item.step_code) !== -1
      })
      temp1.map(step => {
        if (step.task_list) step.mrd = step.task_list.find(item => item.task_display_name === 'MRD结果')
      })
      temp1.push(temp[1])
      temp1.unshift(temp[0])
      return temp1
    },
  },
  methods: {
    openQuestionaire(item = []) {
      this.$emit('openQuestionaire', this.row, item[0])
    },
    openMRDStatus(item) {
      this.$emit('openMRD', this.row, item.step_code, item.mrd)
    }
  }
}
</script>
<style lang="scss" scoped>
.option-out-div.screen-enrollment-col {
  .option-inside {
    width: 70px;
  }
  > .option-inside {
    &:nth-child(2) {
      width: 60px;
    }
  }
}
.option-inside-true {
  vertical-align: bottom;
  .el-icon-circle-plus {
    color: #F6CAAA;
    margin-left: 3px;
  }
  .el-icon-remove {
    color: #A0D797;
    margin-left: 3px;
  }
}
.icon-submit {
  margin-right: 3px;
}
.divide-line {
  width: 1px;
  height: 12px;
  margin-left: 3px;
  background: linear-gradient(to bottom, #fff 0%, #fff 8.3%, #d4d4d4 8.3%, #d4d4d4 91.7%, #fff 91.7%, #fff 100%);
}
.circle-flag {
  align-items: center;
  border: 1px solid #333;
  border-radius: 50%;
  display: flex;
  height: 8px;
  justify-content: center;
  margin-right: 3px;
  width: 8px;
  &.green {
    border-color: #50b240;
  }
}
.visit-period {
  width: 32px;
  line-height: 24px;
}
.visit-status {
  width: 16px;
  text-align: center;
  .circle-flag {
    margin-right: 0;
  }
  .gene-positive {
    color: #F6CAAA;
    margin-left: 3px;
    margin-bottom: 0;
  }
  .gene-nagative {
    color: #A0D797;
    margin-left: 3px;
    margin-bottom: 0;
  }
}
</style>