<template>
  <div class="gene-state" v-if="row.ngs_list?.length > 0">
    <div
      v-for="(item, index) in row.ngs_list"
      :key="index"
      @click="openDetail(item)"
      class="gene-block"
    >
      <img v-if="item.number_of_fills == 0 && item.lims_order_code" class="gene-ok" src="@/assets/image/ok.svg" />
      <img v-else-if="item.number_of_fills == 0 && !item.lims_order_code" class="gene-none" src="@/assets/image/gene-none.svg" />
      <img v-else-if="item.number_of_fills > 0 && item.ctdna_result == 'Positive'" class="gene-positive" src="@/assets/image/gene-positive.svg" />
      <img v-else-if="item.number_of_fills > 0 && item.ctdna_result == 'Negative'" class="gene-nagative" src="@/assets/image/gene-nagative.svg" />
      <img v-else class="gene-none" src="@/assets/image/gene-none.svg" />
    </div>
    <DialogGeneState
      v-if="dialogGeneState.visible"
      :dialogVisible="dialogGeneState.visible"
      :geneInfo="dialogGeneState.geneInfo"
      @closeDialog="dialogGeneState.visible=false"
      @refresh="handleRefresh"
    />
  </div>
</template>
<script>
import DialogGeneState from '../dialog/DialogGeneState'
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  components: {
    DialogGeneState
  },
  data() {
    return {
      dialogGeneState: {
        visible: false,
        geneInfo: null
      }
    }
  },
  methods: {
    openDetail(item) {
      this.dialogGeneState.geneInfo = {
        ...item,
        name: this.row.name,
        patient_code: this.row.patient_code
      }
      this.dialogGeneState.visible = true
    },
    handleRefresh() {
      this.$emit('refresh')
    }
  }
}
</script>
<style lang="scss">
.gene-state {
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid #d4d4d4;
  height: 24px;
  border-radius: 2px;
  cursor: pointer;
}

.gene-state div.gene-block {
  display: inline-block;
  padding-left: 8px;
}

.gene-state div.gene-block:nth-child(5) {
  border-right: 1px solid #d4d4d4;
  padding-right: 8px;
}

.gene-state div.gene-block:last-child {
  padding-right: 8px;
}

.gene-state img {
  width: 8px;
  box-sizing: border-box;
  padding-bottom: 1px;
}
.gene-nagative {
  margin-bottom: 3px;
}
.gene-state .gene-ok {
  width: 9px;
}
</style>