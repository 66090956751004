import Index from '@/views/ECTOP/Index.vue'
import User from '@/views/ECTOP/User.vue'
import Log from '@/views/ECTOP/Log.vue'
import MbLogin from '@/views/ECTOP/mb/MbLogin'
import MbIndex from '@/views/ECTOP/mb/MbIndex'
const CTPJ00000051Routes = [
  {
    path: '/CTPJ00000051',
    component: {render: (h) => h('router-view')},
    children: [
      {
        path: 'index',
        component: Index,
        meta: { requireLogin: true }
      },
      {
        path: 'user',
        component: User,
        meta: { requireLogin: true }
      },
      {
        path: 'log',
        component: Log,
        meta: { requireLogin: true }
      },
      {
        path: 'mb/login',
        component: MbLogin
      },
      {
        path: 'mb/index',
        component: MbIndex,
      }
    ]
  }
]

export default CTPJ00000051Routes
