<template>
  <user-list projectCode="CTPJ00000050"></user-list>
</template>

<script>
import UserList from "@/components/UserList.vue";
export default {
  name: "UserView",
  components: {
    UserList,
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style>
</style>