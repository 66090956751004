<template>
  <el-form-item :label="question.label" :prop="question.key"
    :rules="[
      { required: true, message: '请选择', trigger: 'change' },
    ]">
    <el-radio-group v-model="localValue" style="width: 100%">
      <el-row style="display: flex; align-items: center;flex-wrap: wrap">
        <el-col :span="question.span" v-for="radio in question.options" :key="radio.label">
          <el-radio :label="radio.label">{{ radio.value }}</el-radio>
          <el-form-item v-if="radio.define_input_key" :prop="radio.define_input_key" :rules="[{ validator: (rule, value, callback) => validatorDefine(rule, value, callback, radio.label), trigger: 'change' }]" style="display: inline-block">
            <el-input :disabled="!(localValue === radio.label)" :value="questionaireForm[radio.define_input_key]" placeholder="请输入" class="radio-define-input" clearable @input="(val) => $emit('defineInput', radio.define_input_key, val)" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-radio-group>
  </el-form-item>
</template>
<script>
export default {
  props: {
    value: {
      default: '',
      type: String
    },
    question: {
      type: Object,
      default: () => {}
    },
    questionaireForm: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    defineInputKeys() {
      return this.question.options.filter(ii => ii.define_input_key)
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (this.localValue) {
          let tt = this.defineInputKeys.filter(ii => ii.label === this.localValue)
          if (tt.length > 0 && tt[0].define_input_key) this.$emit('defineInput', tt[0].define_input_key, '', tt[0].define_input_key)
        }
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    validatorDefine(rule, value, callback, label) {
      if (label !== this.localValue) callback()
      if (value) callback()
      else callback(new Error('请输入'))
    }
  }
}
</script>
<style lang="scss">
.radio-define-input.el-input--mini {
  width: auto;
  > .el-input__inner {
    height: 20px;
    line-height: 20px;
  }
}
</style>