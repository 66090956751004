<template>
<patient-list></patient-list>
</template>

<script scoped>
import PatientList from "@/components/PatientList.vue"

export default {
  name: "HomeIndex",
  components: {
    PatientList
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style>
</style>