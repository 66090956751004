const step_1_questionnaire = [
  {
    key: 'external_patient_code',
    label: '受试者编号',
    type: 1,
    maxlength: 20,
    tips: 'CTONG2301+研究中心编号(2位)+受试者编号(3位) ,例如CTONG230101001'
  },
  {
    key: 'name',
    label: '受试者姓名',
    type: 1,
    maxlength: 100
  },
  {
    key: 'institute_id',
    label: '所属研究中心',
    type: 4, // 从远程获取数据
    options: 'institutes'
  },
  {
    key: 'date_of_icf',
    label: '签署知情同意的日期',
    type: 3,
    initalDate: '1965'
  },
  {
    key: 'file_list',
    label: '上传知情同意书',
    type: 5, // 上传文件
    desc: '文件格式限pdf, word, bmp,jpeg,jpg,png,gif,tiff,pcx,raw'
  }
]
const step_1_questionnaire_form = [
  'external_patient_code',
  'name',
  'institute_id',
  'is_signed_icf',
  'date_of_icf',
]
export {
  step_1_questionnaire,
  step_1_questionnaire_form
}