<template>
  <el-dialog
    title="提醒"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="genetrials-common-dialog modal-action-remind"
    width="400px"
    append-to-body
    @close="closeDialog"
  >
    <div class="modal-main">
      <div v-if="actionRemindInfo.actionType === '1'">
        如无特殊原因，受试者的基本信息不允许编辑，如果确定要编辑，该操作将被记录并且可能被上报，请谨慎操作。
        <span style="font-weight: 650">并且，编辑受试者的信息<span style="color: red">不会</span>重新分组。</span>
      </div>
      <div v-else-if="actionRemindInfo.actionType === '2'">
        如无特殊原因，受试者的基本信息不允许删除，删除受试者将会导致的后果将由您承担。如果确定要删除，该操作将被记录并且可能被上报，请谨慎操作。
      </div>
      <div v-else-if="actionRemindInfo.actionType === '3'">
        是否确定{{ actionRemindInfo.process_status === '2' ? '继续' : '结束' }}该受试者的研究?
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <template v-if="actionRemindInfo.actionType === '1'">
        <el-button @click="handleConfirm" style="border-radius">继续编辑</el-button>
        <el-button type="primary" @click="closeDialog" style="border-radius">不编辑了</el-button>
      </template>
      <template v-else-if="actionRemindInfo.actionType === '2'">
        <el-button @click="handleConfirm" style="border-radius">继续删除</el-button>
        <el-button type="primary" @click="closeDialog" style="border-radius">不删除了</el-button>
      </template>
      <template v-else-if="actionRemindInfo.actionType === '3'">
        <el-button @click="closeDialog" style="border-radius">取消</el-button>
        <el-button type="primary" @click="handleConfirm" style="border-radius">确定</el-button>
      </template>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    actionRemindInfo: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    handleConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss">
.genetrials-common-dialog.modal-action-remind {
  .el-dialog__body {
    .modal-main {
      padding-bottom: 15px;
      color: #333;
    }
  }
}
</style>