<template>
  <div class="patient-add-new">
    <el-dialog
      title="添加受试者入组"
      :visible="step_1"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog patient-add-dialog step_1"
      width="1000px"
      append-to-body
      :before-close="closeAllDialog"
    >
      <el-form
        label-position="top"
        class="patient-add-form"
        size="mini"
        :model="step_1_form"
        :rules="step_1_rules"
        :disabled="actionType === '2'"
        ref="step_1_form"
      >
        <template v-for="(question, index) in step_1_questionnaire">
          <div v-if="question.type === 1" :key="question.key" class="define-form-item">
            <span class="sortNo">{{ handleSortNo(index) }}</span>
            <el-form-item :label="question.label" :prop="question.key">
              <el-input style="display: inline-block;width: 220px" v-model="step_1_form[question.key]" placeholder="请输入" :maxlength="question.maxlength ? question.maxlength : null" />
              <span v-if="question.tips" class="tips">{{ question.tips || '' }}</span>
            </el-form-item>
          </div>
          <div v-else-if="question.type === 2" :key="question.key" class="define-form-item">
            <span class="sortNo">{{ handleSortNo(index) }}</span>
            <el-form-item :label="question.label" :prop="question.key">
              <el-radio-group v-model="step_1_form[question.key]" style="width: 100%">
                <el-row style="display: flex; align-items: center">
                  <el-col :span="radio.desc ? 6.5 : 4" v-for="radio in question.options" :key="radio.label">
                    <el-radio :label="radio.label">{{ radio.value }}</el-radio>
                    <template v-if="radio.desc">
                      <el-form-item :prop="step_1_form[question.key] === radio.label ? radio.desc : radio.desc + '1111'" style="display: inline-block">
                        <el-input
                          :disabled="!(step_1_form[question.key] === radio.label)"
                          style="display: inline-block;width: 160px"
                          v-model="step_1_form[radio.desc]"
                          :placeholder="radio.placeholder"
                          clearable
                        />
                      </el-form-item>
                    </template>
                  </el-col>
                </el-row>
              </el-radio-group>
            </el-form-item>
          </div>
          <div v-else-if="question.type === 3" :key="question.key" class="define-form-item">
            <span class="sortNo">{{ handleSortNo(index) }}</span>
            <el-form-item :label="question.label" :prop="question.key">
              <el-date-picker v-model="step_1_form[question.key]"
                value-format="yyyy-MM-dd"
                placeholder="年-月-日"
                popper-class="define-patient-add-date-picker"
                :picker-options="pickerOptions"
              />
            </el-form-item>
          </div>
          <div v-else-if="question.type === 4" :key="question.key" class="define-form-item">
            <span class="sortNo">{{ handleSortNo(index) }}</span>
            <el-form-item :label="question.label" :prop="question.key">
              <el-radio-group v-model="step_1_form[question.key]" style="width: 100%">
                <el-row>
                  <el-col :span="8" v-for="item in question.options" :key="item.institute_id">
                    <el-radio :label="item.institute_id">
                      {{ item.institute_code }} {{ item.institute_name }}
                    </el-radio>
                  </el-col>
                </el-row>
              </el-radio-group>
            </el-form-item>
          </div>
          <div v-else-if="question.type === 5" :key="question.key" class="define-form-item">
            <span class="sortNo">{{ handleSortNo(index) }}</span>
            <el-form-item :label="question.label" :prop="question.key"
              :rules="[
                { required: true, message: '请上传文件', trigger: 'change' },
              ]">
              <div class="form-desc">{{ question.desc }}</div>
              <upload-item ref="uploadItem"
                :initFileList="initFileList"
                :actionType="actionType"
                @handleUpload="handleUpload"/>
            </el-form-item>
          </div>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleConfirm" :loading="loading">完成</el-button>
      </span>
    </el-dialog>
    <DialogMessage
      :dialogVisible="step_error"
      :message-info="step_error_msg"
      :actionLoading="actionLoading"
      @confirm="handleDialogMessageConfirm"
      @cancel="handleDialogMessageCancel"
      @action="handleDialogMessageAction"
    />
  </div>
</template>
<script>
import { submitResult } from '@/apis/questionnaire'
import { getInstitute, patientModify, patientAdd } from '@/apis/NSCLC/system'
import { patientDetail, checkExternalPatientCode } from '@/apis/user'
import defaultResponseNotify, { notifyError, notifySuccess} from '@/helpers/message'
import DialogMessage from '@/components/dialog/DialogMessage'
import UploadItem from '@/components/UploadItem'
import { step_1_questionnaire, step_1_questionnaire_form } from './patientAddInfo'

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    actionType: {
      type: String,
      default: '1' // 1：新建；2：查看；3：编辑
    },
    fromPatientId: {
      type: String,
      default: ''
    }
  },
  components: {
    DialogMessage,
    UploadItem
  },
  data() {
    const check_external_patient_code = (rule, value, callback) => {
      if (!/^CTONG2301\d{5}$/.test(value)) {
        callback('格式不正确')
      }
      if (this.is_step_1_form_checking) {
        callback()
        return
      }
      let params = {
        external_patient_code: value,
        patient_id: this.fromPatientId ? this.fromPatientId : undefined
      }
      checkExternalPatientCode(params).then(res => {
        if (res.code == 200) {
          if (res.message == '1') {
            callback(new Error('患者编号重复，系统中已经存在此编号的患者。'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      })
    }
    return {
      institutes: [],
      step_1: true,
      step_1_form: {
        name: '',
        institute_id: '',
        external_patient_code: '',
        is_signed_icf: '',
        date_of_icf: '',
        file_list: []
      },
      is_step_1_form_checking: false,
      step_1_rules: {
        external_patient_code: [
          {
            required: true, message: '请输入', trigger: 'change'
          },
          { min: 4, message: '患者编号太短，请重新输入', trigger: 'blur' },
          { validator: check_external_patient_code, trigger: 'blur'}
        ],
        name: [
          {
            required: true, message: '请输入', trigger: 'blur'
          }
        ],
        institute_id: [
          {
            required: true, message: '请选择', trigger: 'change'
          }
        ],
        date_of_icf: [
          {
            required: true, message: '请选择', trigger: 'change'
          }
        ]
      },
      step_error: false,
      step_error_msg: {},
      question_type_set: {},
      loading: false,
      isAddSuccess: false,
      step_1_questionnaire,
      actionLoading: false,
      initFileList: [],
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return time.getTime() > today.getTime();
        },
      },
    }
  },
  created() {
    this.getInstituteList()
  },
  watch: {
    fromPatientId: {
      handler(val) {
        if (val) {
          patientDetail(val).then(result => {
            if (result.code === 200) {
              const info = result.entity
              step_1_questionnaire_form.forEach(ii => {
                this.$set(this.step_1_form, ii, info[ii])
              })
              if (info.file_list && info.file_list.length > 0) {
                this.$set(this.step_1_form, 'file_list', info.file_list)
                this.initFileList = info.file_list
              }
            } else {
              notifyError('受试者问卷信息获取失败')
            }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    getInstituteList() {
      getInstitute().then(res => {
        if (res.code === 200) {
          res.entities.sort((a, b) => {
            return a.sort_no - b.sort_no
          })
          this.institutes = res.entities
          this.step_1_questionnaire.forEach(item => {
            if (item.key === 'institute_id') {
              item.options = this.institutes;
            }
          });
        } else {
          defaultResponseNotify(res)
        }
      })
    },
    handleConfirm() {
      this.$refs['step_1_form'].validate(async valid => {
        if (valid) {
          await this.handleStep1Submit()
        } else {
          this.handleErrorMsg()
        }
      })
    },
    closeAllDialog() {
      this.$emit('closeDialog')
    },
    handleSortNo(index) {
      return index + 1 > 9 ? index + 1 : '0' + (index + 1)
    },
    async handleStep1Submit() {
      this.loading = true
      if (this.actionType === '2') {
        this.closeAllDialog()
        return 
      }
      // 新建受试者
      let hasUploadedFile = false;  // 跟踪是否已添加任何 file_id
      const params = new FormData()
      const fileList = this.step_1_form.file_list
      fileList.forEach((file, index) => {
        if (file.file_id) {
          params.append('uploaded_file_list', file.file_id)
          hasUploadedFile = true
        } else {
          params.append('file_list', file.raw, file.name);
        }
      })
      // 如果没有任何file_id被添加，则添加空字符串的uploaded_file_list
      if (!hasUploadedFile) {
        params.append('uploaded_file_list', '');
      }
      Object.keys(this.step_1_form).map(key => {
        if (key !== 'file_list') {
          params.append(key, this.step_1_form[key])
        }
      })
      if (this.actionType === '3') {
        params.append('patient_id', this.fromPatientId)
      }
      const resultNew = this.actionType === '3' ? await patientModify(params) : await patientAdd(params)
      this.loading = false
      if (resultNew.code === 200) {
        if (this.actionType === '3') {
          notifySuccess('更新受试者成功')
          this.$emit('refresh')
          this.closeAllDialog()
          return
        }
        this.isAddSuccess = true
        this.step_error_msg = {
          type: 'addSuccess',
          msg: resultNew.message
        }
        this.step_error = true
      } else {
        if (this.actionType === '3') {
          notifySuccess('更新受试者失败')
          return
        }
        this.isAddSuccess = false
        this.step_error_msg = {
          type: 'warning',
          msg: resultNew.message
        }
        this.step_error = true
      }
    },
    handleUpload(fileList) {
      this.step_1_form.file_list = fileList
    },
    handleDialogMessageConfirm() {
      this.step_error = false
      if (this.isAddSuccess) {
        this.$emit('refresh')
        this.closeAllDialog()
      }
    },
    handleDialogMessageCancel() {
      this.step_error = false
      if (this.isAddSuccess) {
        this.$emit('refresh')
        this.closeAllDialog()
      }
    },
    async handleDialogMessageAction(action) {
      if (action === 'disenroll') {
        const params = this.handleSubmitParams()
        // 标记未入组
        params.result_info['confirm_not_grouped'] = '1'
        this.actionLoading = true
        const result = await submitResult(params)
        this.actionLoading = false
        if (result.code !== 200) {
          notifyError(result.message)
        } else {
          notifySuccess('添加未入组成功')
          this.step_error = false
          this.$emit('refresh')
          this.closeAllDialog()
        }
      } else if (action === 'preoperative_blood_type') {
        this.step_error = false
        let result = await this.handleStep1Submit()
        if (result) this.handleNextOrBackStep('1', '2')
      }
      this.step_error = false
      if (this.isAddSuccess) {
        this.$emit('refresh')
        this.closeAllDialog()
      }
    },
    handleErrorMsg() {
      this.$message({
        type: 'error',
        message: '您有必填字段未填写。'
      })
    },
    handleDatePickerFocus() {

    }
  }
}
</script>
<style lang="scss">
.genetrials-common-dialog.patient-add-dialog {
  .patient-add-form {
    > .define-form-item {
      padding: 5px 20px 15px;
      font-size: 12px;
      display: flex;
      background: #ffffff;
      .el-form-item {
        flex: 1;
        margin-bottom: 0;
        .el-form-item__label {
          line-height: 16px;
          padding-bottom: 5px;
        }
        .el-form-item__content {
          line-height: 24px;
          .tips {
            color: #999;
            margin-left: 10px;
            font-size: 12px;
          }
          .define-matrix-radio {
            background: #ffffff;
            > .el-row {
              padding: 5px 20px;
              border-bottom: 1px solid #f2f2f2;
            }
            .col-justify-center {
              text-align: center;
            }
          }
          .define-radio-group-vertical {
            display: flex;
            flex-direction: column;
            > label.el-radio {
              line-height: 1.5;
            }
          }
        }
      }
      .sortNo {
        font-weight: 650;
        color: #0079fe;
        margin-right: 11px;
      }
      .form-desc {
        color: #999;
        font-size: 12px;
      }
    }
    > .define-form-item:nth-child(2n+1) {
      background: #f8f9fb;
    }
    .define-form-description {
      padding-left: 20px;
      line-height: 36px;
      background-color: #0079fe;
      color: #ffffff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      p {
        margin: 0;
      }
    }
    .el-row.define-form-item-520-title {
      padding-left: 80%;
      white-space: pre-wrap;
      margin-top: 5px;
    }
    .el-row.define-form-item-520 > .el-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      > label.el-form-item__label {
        padding-bottom: 0;
        padding-right: 24px;
        width: 80%;
        &::before {
          content: '';
          margin-right: 0;
        }
      }
    }
  }
}
.el-date-picker.define-patient-add-date-picker {
  .el-year-table td {
    padding: 28px 3px;
  }
  .el-month-table td {
    padding: 21px 0;
  }
}
</style>
