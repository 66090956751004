import { requestGet, requestPost } from "@/utils/request"
import store from '@/store'

// 获取问卷基础信息
export function getQuestionaireInfo(params, result_id) {
  return new Promise((resolve, reject) => {
    requestGet(store.state.currentPrefix + `/questionnaire/${params}?result_id=${result_id}`).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 暂存填写结果
export function saveResult(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/questionnaire/result/save', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 提交填写结果
export function submitResult(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/questionnaire/result/commit', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 获取问卷结果
export function getQuestionnareResult(params) {
  return new Promise((resolve, reject) => {
    requestGet(store.state.currentPrefix + `/questionnaire/result?result_ids=${params}`).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}