<template>
  <van-dialog v-model="show"
    class="dialog-add-message"
    title=""
    confirm-button-color="#0079fe"
    confirmButtonText="关闭"
    @close="closeDialog">
    <div class="modal-main">
      <div v-if="messageInfo.type === 'warning'">
        <img src="@/assets/image/warning_dialog.svg" />
        <div v-html="messageInfo.msg"></div>
      </div>
      <div v-else-if="messageInfo.type === 'addSuccess'" class="addSuccess">
        <!-- 新建受试者成功 -->
        <img src="@/assets/image/success_dialog.svg" width="40" height="40" />
        <p class="add-title">添加受试者入组成功！</p>
        <div v-html="messageInfo.msg" class="add-msg"></div>
      </div>
    </div>
  </van-dialog>
</template>
<script>
export default {
  name: 'DialogMessage',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    messageInfo: {
      type: Object,
      default: () => {
        return {
          type: 'warning',
          msg: ''
        }
      }
    }
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    dialogVisible(val) {
      this.show = val
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>
<style lang="scss">
.dialog-add-message {
  .addSuccess {
    text-align: center;
    font-size: 14px;
    img {
      padding-top: 30px;
    }
    .add-title {
      padding: 10px;
    }
    .add-msg {

    }
    .label-patient-danger-level {
      font-size: 16px;
      padding: 10px;
    }
  }
}
</style>
