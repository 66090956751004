<template>
  <div class="register-page-wrapper">
    <h2>博瑞哲用户管理</h2>
    <el-form id="searchForm" :inline="true" class="my-row" :model="query" size="mini">
      <el-button type="primary" class="add-btn" @click="openDialog">
        添加用户
      </el-button>
      <el-form-item label="姓名">
        <el-input v-model="query.display_name" placeholder="请输入" clearable style="width: 120px" />
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="query.phone_number" placeholder="请输入" clearable style="width: 150px" />
      </el-form-item>
      <el-form-item label="开通项目">
        <el-select v-model="query.project_codes" placeholder="请选择" style="width: 250px" clearable>
          <el-option
            v-for="ii in projectList"
            :key="ii.project_code"
            :label="ii.project_name"
            :value="ii.project_code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">
          筛选
        </el-button>
      </el-form-item>
      <el-form-item style="margin-right: 0">
        <el-button @click="onReset">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      id="patientTable"
      ref="patientTable"
      :data="tableData"
      stripe
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      height="calc(100vh - 230px)"
      style="width: 100%"
      class="patient-table">
      <el-table-column
        v-for="column in tableColumns"
        :label="column.name"
        :prop="column.field"
        :key="column.field"
        :min-width="column.minWidth"
        :width="column.width"
        fixed="left"
      >
      </el-table-column>
      <el-table-column label="操作" width="75px" fixed="left">
        <template slot-scope="scope">
          <a @click="editItem(scope.row)" class="edit-btn">编辑</a>
        </template>
      </el-table-column>
      <el-table-column
        v-for="project in projectList"
        :label="project.project_name"
        :key="project.project_code"
        min-width="200">
        <template slot-scope="scope">
          {{ scope.row[project.project_code] || '未开通'}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="onPageSizeChange"
      @current-change="onPageIdxChange"
      :current-page="pagination.page"
      :page-sizes="[20, 50, 100]"
      :page-size="pagination.size"
      align="right"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
    <dialog-edit-user
      :dialogVisible="dialogVisible"
      :currentUserId="currentUser.user_id"
      :projectList="projectList"
      @close="dialogVisible = false"
      @refreshList="onReset"
    />
  </div>
</template>
<script>
import {
  apiGetAllProjects,
  apiPostUserList
} from '@/apis/system'
import { notifyError } from "@/helpers/message.js";
import DialogEditUser from './DialogEditUser.vue'
export default {
  name: 'RegisterPage',
  components: { DialogEditUser },
  data() {
    return {
      query: {
        display_name: '',
        phone_number: '',
        project_codes: ''
      },
      projectList: [],
      pagination: {
        page: 1,
        size: 50,
        total: 0
      },
      tableData: [],
      tableColumns: [{
        field: 'display_name',
        name: '用户姓名',
        width: 120
      },{
        field: 'phone_number',
        name: '登录手机号',
        width: 120
      },{
        field: 'email',
        name: '邮箱地址',
        width: 200
      },{
        field: 'department',
        name: '所属单位',
        width: 200
      }],
      dialogVisible: false,
      currentUser: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getProjects()
      this.getUsers()
    },  
    async getProjects() {
      const res = await apiGetAllProjects()
      if (res.code === 200) {
        this.projectList = res.entities
      } else {
        notifyError(res.message)
      }
    },
    async getUsers() {
      const params = {
        ...this.query,
        page: this.pagination.page,
        size: this.pagination.size
      }
      const res = await apiPostUserList(params)
      if (res.code === 200) {
        this.tableData = res.entities
        this.tableData.map(item => {
          item.details.map(project => {
            item[project.project_code] = project.project_auth_detail
          })
        })
        this.pagination.total = res.total
      } else {
        notifyError(res.message)
      }
    },
    openDialog() {
      this.currentUser = {}
      this.dialogVisible = true
    },
    onSearch() {
      this.pagination.page = 1
      this.getUsers()
    },
    onReset() {
      this.query = {
        display_name: '',
        phone_number: '',
        project_codes: ''
      }
      this.onSearch()
    },
    onPageSizeChange(val) {
      this.pagination.size = val
      this.getUsers()
    },
    onPageIdxChange(val) {
      this.pagination.page = val
      this.getUsers()
    },
    editItem(row) {
      this.currentUser = row
      this.dialogVisible = true
    }
  } 
}
</script>
<style lang="scss" scoped>
.register-page-wrapper {
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  h2 {
    text-align: center;
  }
  .add-btn {
    position: absolute;
    left: 0;
  }
  .my-row {
    text-align: right;
    position: relative;
  }
  .edit-btn {
    padding: 10px 10px 10px 0;
    color: #0079FE;
    cursor: pointer;
  }
}
</style>