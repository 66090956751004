<template>
  <div class="mb-login-container">
    <div class="logo">
      <img :src="require('@/assets/image/mb-logo.svg')" />
    </div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="phone_number"
        name="phone_number"
        label=""
        :left-icon="require('@/assets/image/phone-icon.svg')"
        placeholder="请输入手机号"
        :rules="[{ required: true, message: '请输入有效的手机号' },
        {validator: checkPhone, message: '请输入有效的手机号'}]"
      />
      <van-field
        v-model="sms_code"
        type="number"
        name="sms_code"
        label=""
        :left-icon="require('@/assets/image/code-icon.svg')"
        placeholder="请输入验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
      >
        <template #button>
          <count-down-btn :phone_number="phone_number"/>
        </template>
      </van-field>
      <div style="margin: 40px 0;">
        <van-button class="login-btn" block native-type="submit">登录</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import CountDownBtn from './CountDownBtn.vue';
import store from "@/store/index.js";
import router from "@/router/index.js";
import { apiGetProjects } from '@/apis/ECTOP/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      phone_number: '',
      sms_code: '',
    };
  },
  components: { CountDownBtn },
  methods: {
    ...mapMutations(['changeCurrentProject']),
    checkPhone (val) {
      return /(^$|^1\d{10}$)/.test(val)
    },
    onSubmit(values) {
      store.dispatch("login", values).then((data) => {
        if (data.code === 200) {
          apiGetProjects().then(res => {
            if (res.code === 200 && res.entities && res.entities.length > 0) {
              const targetProject = res.entities.find(item => item.project_code === 'CTPJ00000051')
              if (targetProject) {
                Toast.success('登录成功')
                this.changeCurrentProject('CTPJ00000051')
                localStorage.setItem('loginType', 'mb')
                setTimeout(function () {
                  router.push("/CTPJ00000051/mb/index")
                }, 1000);
              } else {
                Toast.fail('手机号尚未注册，或无项目权限，请联系博瑞哲工作人员')
              }
            } else {
              Toast.fail('手机号尚未注册，或无项目权限，请联系博瑞哲工作人员')
            }
          })
        } else {
          Toast.fail(data.message)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.mb-login-container {
  background-image: linear-gradient(#3E9BED, #3BBB95);
  height: 100%;
  .logo {
    width: 200px;
    height: 160px;
    margin: 0 auto;
    padding-top: 150px;
  }
  .login-btn {
    border: none;
    border-radius: 4px;
  }
  .login-btn .van-button__content {
    background-image: linear-gradient(to right, #3E9BED, #3BBB95);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    font-weight: bold;
  }
  .van-form {
    padding: 0 44px;
  }
  .van-cell {
    background: none;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 8px 12px;
    margin-bottom: 26px;
  }
  
}
</style>
<style lang="scss">
.mb-login-container {
  .van-field__control {
    color: #fff;
  }
  .van-field__body {
    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #eee;
    }
    input {
      font-size: 14px;
      -webkit-text-size-adjust: none;
    }
  }
  .van-field__left-icon .van-icon {
    margin-top: 4px;
  }
  .van-field__button {
    line-height: 16px;
  }
}
</style>