<template>
  <el-dialog
    v-if="dialogVisible"
    title="术后访视提醒设置"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="ctms-modal modal-notice"
    width="550px"
    append-to-body
    @close="closeDialog"
  >
    <div class="modal-main">
      <p class="info-wrapper">
        <span class="info-label">受试者姓名：{{ row.name }}</span>
        <span class="info-label">受试者编号：{{ row.patient_code }}</span>
      </p>
      <el-checkbox
        v-model="checked"
        class="notice-checkbox"
        :true-label="1"
        :false-label="0"
      >开启术后访视短信提醒（系统会在当日早上9点发短信提醒您）</el-checkbox>
      <div class="notice-time-list" :class="{'grey' : !checked }">
        <p class="notice-tips">
          <span class="notice-tips-title">提示</span>
          设置首次术后访视时间后，后续时间自动计算得出，暂不支持单个设置
        </p>
        <p v-for="(info, index) in smsList" :key="index" class="notice-item">
          <span>{{ info.alert_display_name }}</span>
          <el-date-picker
            v-if="index === 0"
            v-model="info.alert_except_time"
            type="date"
            size="mini"
            :disabled="!checked"
            :clearable="false"
            value-format="yyyy-MM-dd"
            placeholder="年/月/日"
            @change="changeDate"
          >
          </el-date-picker>
          <span v-else>{{info.alert_except_time_format}}</span>
        </p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="modifyInfo">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getSmsDetail, toggleSms, modifySms, calcSms } from '@/apis/notice'
import { notifyError } from '@/helpers/message'
import dayjs from 'dayjs'

export default {
  props: {
    dialogVisible: {
      default: false,
      type: Boolean
    },
    row: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      checked: 0,
      visitDate: [3, 6, 9, 12, 15, 18, 21, 24, 30, 36, 42, 48, 54, 60],
      smsType: "visit_postoperation",
      selectDate: '',
      smsList: []
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      getSmsDetail({
        patient_id: this.row.patient_id,
        sms_type: this.smsType,
        step_code: this.row.step_code
      }).then(res => {
        if (res.code === 200) {
          const list = res.entity.sms_list.map(item => {
            return {
              ...item,
              alert_except_time_format: item.alert_except_time ? dayjs(item.alert_except_time).format('YYYY年MM月DD日'): ''
            }
          })
          this.smsList = list
          this.checked = res.entity.sms_alert_status
          this.selectDate = this.smsList[0].alert_except_time
        }
      })
    },
    toggleInfo(val) {
      const params = {
        patient_id: this.row.patient_id,
        sms_type: this.smsType,
        sms_alert_status: val
      }
      toggleSms(params).then(res => {
      })
    },
    changeDate(date) {
      this.selectDate = date
      const params = {
        "patient_id": this.row.patient_id,
        "sms_type": this.smsType,
        "sms_id": this.smsList[0].sms_id,
        "alert_except_time": date,
        step_code: this.row.step_code
      }
      calcSms(params).then(res => {
        if (res.code === 200) {
          const list = res.entity.sms_list.map(item => {
            return {
              ...item,
              alert_except_time_format: item.alert_except_time ? dayjs(item.alert_except_time).format('YYYY年MM月DD日'): ''
            }
          })
          this.smsList = list
        }
      })
    },
    modifyInfo() {
      const params = {
        patient_id: this.row.patient_id,
        sms_type: this.smsType,
        sms_id: this.smsList[0]?.sms_id,
        alert_except_time: this.selectDate,
        sms_alert_status: this.checked || 0,
        step_code: this.row.step_code
      }
      modifySms(params).then(res => {
        if (res.code === 200) {
          this.closeDialog()
          this.$emit('refresh')
        } else {
          notifyError(res.message)
        }
      })
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
  }
}
</script>
<style lang="scss">
.modal-notice {
  .info-wrapper {
    .info-label {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  .notice-checkbox {
    font-weight: normal;
    .el-checkbox__label {
      font-size: 12px;
    }
  }
  .notice-time-list {
    background: #f5fafd;
    padding: 15px;
    margin: 13px 20px 0;
    &.grey {
      background: #f8f9fb;
      .notice-tips {
        color: #999;
      }
      .notice-tips-title {
        background: #d4d4d4;
      }
      .notice-item {
        color: #999;
      }
    }
    .notice-tips {
      color:#0079fe;
      margin: 0;
    }
    .notice-tips-title {
      background: #0079fe;
      color: #fff;
      border-radius: 8px;
      padding: 0 4px;
    }
    .notice-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-date-editor {
      width: 169px;
    }
  }
  &.ctms-modal {
    .el-dialog__body {
      .modal-main {
        overflow: auto;
        max-height: 74vh;
      }
    }
  }
}
</style>