<template>
  <div class="patient-add-new">
    <el-dialog
      title="添加受试者入组"
      :visible="step_1"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog patient-add-dialog step_1"
      width="600px"
      append-to-body
      :before-close="closeAllDialog"
    >
      <div class="modal-main">
        <el-form
          label-position="top"
          class="patient-add-form"
          size="mini"
          :model="step_1_form"
          :rules="step_1_rules"
          ref="step_1_form"
        >
          <template v-for="(question, index) in step_1_questionnaire">
            <div v-if="question.type === 1" :key="question.key" class="define-form-item">
              <span class="sortNo">{{ handleSortNo(index) }}</span>
              <el-form-item :label="question.label" :prop="question.key">
                <el-input style="display: inline-block;width: 220px" v-model="step_1_form[question.key]" placeholder="请输入" :maxlength="question.maxlength ? question.maxlength : null" />
                <span v-if="question.tips" class="tips">{{ question.tips || '' }}</span>
              </el-form-item>
            </div>
            <div v-else-if="question.type === 2" :key="question.key" class="define-form-item">
              <span class="sortNo">{{ handleSortNo(index) }}</span>
              <el-form-item :label="question.label" :prop="question.key">
                <el-radio-group v-model="step_1_form[question.key]" style="width: 100%">
                  <el-row style="display: flex; align-items: center">
                    <el-col :span="radio.desc ? 6.5 : 4" v-for="radio in question.options" :key="radio.label">
                      <el-radio :label="radio.label">{{ radio.value }}</el-radio>
                      <template v-if="radio.desc">
                        <el-form-item :prop="step_1_form[question.key] === radio.label ? radio.desc : radio.desc + '1111'" style="display: inline-block">
                          <el-input
                            :disabled="!(step_1_form[question.key] === radio.label)"
                            style="display: inline-block;width: 160px"
                            v-model="step_1_form[radio.desc]"
                            :placeholder="radio.placeholder"
                            clearable
                          />
                        </el-form-item>
                      </template>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
        </el-form>
        <p class="add-patient-tips">受试者信息添加后不能修改，请确认信息无误</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleConfirm" :loading="loading">确定</el-button>
      </span>
    </el-dialog>
    <DialogMessage
      :dialogVisible="step_error"
      :message-info="step_error_msg"
      :actionLoading="actionLoading"
      @confirm="handleDailogMessageConfirm"
      @cancel="handleDailogMessageCancel"
      @action="handleDailogMessageAction"
    />
  </div>
</template>
<script>
import { submitResult } from '@/apis/questionnaire'
import { patientAdd, checkExternalPatientCode } from '@/apis/user'
import { notifyError, notifySuccess} from '@/helpers/message'
import DialogMessage from '@/components/dialog/DialogMessage'
import { step_1_questionnaire } from './patientAddInfo'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    actionType: {
      type: String,
      default: '1' // 1：新建；2：查看；3：编辑
    },
    fromPatientId: {
      type: String,
      default: ''
    }
  },
  components: {
    DialogMessage
  },
  data() {
    const check_external_patient_code = (rule, value, callback) => {
      if (!/^CTONG2301\d{5}$/.test(value)) {
        callback('格式不正确')
      }
      if (this.is_step_1_form_checking) {
        callback()
        return
      }
      let params = {
        external_patient_code: value,
        patient_id: this.patient_id ? this.patient_id : undefined
      }
      checkExternalPatientCode(params).then(res => {
        if (res.code == 200) {
          if (res.message == '1') {
            callback(new Error('患者编号重复，系统中已经存在此编号的患者。'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      })
    }
    return {
      usersList: [],
      step_1: true,
      step_2: false,
      question_2: {},
      step_1_form: {
        p1_value: '',
        p2_value: '',
        external_patient_code: '',
        stage: '',
        mutation_state: ''
      },
      is_step_1_form_checking: false,
      step_1_rules: {
        external_patient_code: [
          {
            required: true, message: '请输入', trigger: 'change'
          }
        ],
        p1_value: [
          {
            required: true, message: '请选择', trigger: 'change'
          }
        ],
        p2_value: [
          {
            required: true, message: '请选择', trigger: 'change'
          }
        ],
        stage: [
          {
            required: true, message: '请选择', trigger: 'change'
          }
        ],
        mutation_state: [
          {
            required: true, message: '请选择', trigger: 'change'
          }
        ]
      },
      patient_id: '',
      step_error: false,
      step_error_msg: {},
      question_type_set: {},
      loading: false,
      isAddSuccess: false,
      step_1_questionnaire,
      actionLoading: false
    }
  },
  methods: {
    handleConfirm() {
      this.$refs['step_1_form'].validate(async valid => {
        if (valid) {
          await this.handleStep1Submit()
        } else {
          this.handleErrorMsg()
        }
      })
    },
    closeAllDialog() {
      this.$emit('closeDialog')
    },
    handleSortNo(index) {
      return index + 1 > 9 ? index + 1 : '0' + (index + 1)
    },
    handleTips(type) {
      if (type === 200) {
        return '请输入'
      } else {
        return '请选择'
      }
    },
    async handleStep1Submit() {
      this.loading = true

      // 新建受试者
      const params = this.step_1_form
      const resultNew = await patientAdd(params)
      this.loading = false
      if (resultNew.code === 200) {
        this.isAddSuccess = true
        this.step_error_msg = {
          type: 'addSuccess',
          msg: resultNew.message
        }
        this.step_error = true
      } else {
        this.isAddSuccess = false
        this.step_error_msg = {
          type: 'warning',
          msg: resultNew.message
        }
        this.step_error = true
      }
    },
    handleDailogMessageConfirm() {
      this.step_error = false
      if (this.isAddSuccess) {
        this.$emit('refresh')
        this.closeAllDialog()
      }
    },
    handleDailogMessageCancel() {
      this.step_error = false
      if (this.isAddSuccess) {
        this.$emit('refresh')
        this.closeAllDialog()
      }
    },
    async handleDailogMessageAction(action) {
      if (action === 'disenroll') {
        const params = this.handleSubmitParams()
        // 标记未入组
        params.result_info['confirm_not_grouped'] = '1'
        this.actionLoading = true
        const result = await submitResult(params)
        this.actionLoading = false
        if (result.code !== 200) {
          notifyError(result.message)
        } else {
          notifySuccess('添加未入组成功')
          this.step_error = false
          this.$emit('refresh')
          this.closeAllDialog()
        }
      } else if (action === 'preoperative_blood_type') {
        this.step_error = false
        let result = await this.handleStep1Submit()
        if (result) this.handleNextOrBackStep('1', '2')
      }
      this.step_error = false
      if (this.isAddSuccess) {
        this.$emit('refresh')
        this.closeAllDialog()
      }
    },
    handleErrorMsg() {
      this.$message({
        type: 'error',
        message: '您有必填字段未填写。'
      })
    }
  }
}
</script>
<style lang="scss">
.genetrials-common-dialog.patient-add-dialog {
  .modal-main {
    background: #fff;
    border-radius: 8px;
    font-size: 12px;
    .patient-add-form {
      > .define-form-item {
        padding: 5px 20px 15px;
        font-size: 12px;
        display: flex;
        background: #ffffff;
        .el-form-item {
          flex: 1;
          margin-bottom: 0;
          .el-form-item__label {
            line-height: 16px;
            padding-bottom: 5px;
          }
          .el-form-item__content {
            line-height: 24px;
            .tips {
              color: #999;
              margin-left: 10px;
              font-size: 12px;
            }
            .define-matrix-radio {
              background: #ffffff;
              > .el-row {
                padding: 5px 20px;
                border-bottom: 1px solid #f2f2f2;
              }
              .col-justify-center {
                text-align: center;
              }
            }
            .define-radio-group-vertical {
              display: flex;
              flex-direction: column;
              > label.el-radio {
                line-height: 1.5;
              }
            }
          }
        }
        .sortNo {
          font-weight: 650;
          color: #0079fe;
          margin-right: 11px;
        }
      }
    }
    .add-patient-tips {
      padding: 20px;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
