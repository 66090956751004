import { render, staticRenderFns } from "./GeneCol.vue?vue&type=template&id=7c3ad56e"
import script from "./GeneCol.vue?vue&type=script&lang=js"
export * from "./GeneCol.vue?vue&type=script&lang=js"
import style0 from "./GeneCol.vue?vue&type=style&index=0&id=7c3ad56e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_npm_i_ctms_front/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports