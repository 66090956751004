<template>
  <div class="patient-list-container">
    <el-form :inline="true" class="my-row" :model="searchForm" size="mini">
      <!-- <div>
        <el-button type="primary" v-if="page_indexOrDetail == 'index'" v-on:click="onAddPatient()">
          <img src="@/assets/image/add.svg" />
          新建受试者信息
        </el-button>
      </div> -->
      <div>
        <el-form-item label="受试者姓名">
          <el-input v-model="searchForm.name" placeholder="请输入" clearable style="width: 120px" />
        </el-form-item>
        <el-form-item label="受试者编号">
          <el-input v-model="searchForm.patient_code" placeholder="请输入" clearable style="width: 120px" />
        </el-form-item>
        <el-form-item label="研究中心">
          <el-select v-model="searchForm.institute_id" placeholder="请选择" size="mini" style="width: 150px" clearable>
            <el-option
              v-for="ii in catcInstitutesList"
              :key="ii.institute_id"
              :label="ii.institute_name"
              :value="ii.institute_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="负责人">
          <el-select v-model="searchForm.principal_id" placeholder="请选择" size="mini" style="width: 120px" clearable>
            <el-option
              v-for="ii in catcUsersList"
              :key="ii.user_id"
              :label="ii.display_name"
              :value="ii.user_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-on:click="onSearch">
            <!-- <img src="@/assets/image/filter.svg" /> -->
            查找
          </el-button>
        </el-form-item>
        <el-form-item style="margin-right: 0">
          <el-button v-on:click="onReset">
            <!-- <i class="el-icon-refresh-right" /> -->
            重置
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table id="patientTable" ref="patientTable" :data="dtDatum" stripe v-loading="loading" element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading" :height="dtHeight" style="width: 100%" class="patient-table">
      <el-table-column show-overflow-tooltip prop="name" label="受试者姓名" min-width="90px" fixed="left">
        <template slot-scope="scope">
          <el-tooltip :disabled="scope.row.process_status !== '2'" effect="dark" content="该受试者已经被结束研究" placement="top">
            <span :class="{'patient-name': true, 'grey': scope.row.process_status === '2'}"
              @click="viewUserInfo(scope.row)">
              {{ scope.row.name }}
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="patient_code" label="受试者编号" min-width="100px" fixed="left">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.process_status === '2'"
            class="item"
            effect="dark"
            content="该受试者已经被结束研究" placement="top">
            <span :style="{'color': scope.row.process_status === '2' ? '#999' : ''}">{{ scope.row.patient_code }}</span>
          </el-tooltip>
          <span v-else>
            {{ scope.row.patient_code }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="column in tableColumns"
        :label="column.label"
        :prop="column.prop"
        :key="column.prop"
        :min-width="column.minWidth"
        :width="column.width"
      >
        <template v-if="['institute_name', 'principal_name', 'join_datetime'].includes(column.prop)" v-slot="scope">
          {{ scope.row[column.prop] || '-' }}
        </template>
        <template v-else-if="'gene_state' == column.prop" v-slot="scope">
          <gene-col :row="scope.row" :key="scope.row.patient_id" @refresh="onSearch" />
        </template>
        <template v-else-if="'questionnaire-state' == column.prop" v-slot="scope">
          <step-col :row="scope.row" :questionnaireMenu="questionnaireMenu" @openQuestionaire="openQuestionaire($event, scope.row)" :key="scope.row.patient_id" />
        </template>
        <template v-else-if="'first-chemotherapy-way' == column.prop" v-slot="scope">
          <chemotherapy-col
            :row="scope.row"
            field="first_chemotherapy_type"
            type="first"
            @openQuestionaire="openQuestionaire($event, scope.row)"
            :key="scope.row.patient_id"
          />
        </template>
        <template v-else-if="'second_ctdna_type' == column.prop" v-slot="scope">
          <div :class="{'second-group': true, 'success': scope.row.second_ctdna_type}"
            @click="onChangePatientGroup(scope.row)" :key="scope.row.patient_id">
            <img v-if="scope.row.second_ctdna_type" class="icon-submit" src="@/assets/image/ok.svg" />
            <img v-else class="icon-submit" src="@/assets/image/gene-none.svg" />ctDNA{{ scope.row.second_ctdna_type ? scope.row.second_ctdna_type === 2 ? '-' : '+' : '' }}
          </div>
        </template>
        <template v-else-if="'second-treatment' == column.prop" v-slot="scope">
          <div class="option-out-div chemotherapy-way" :key="scope.row.patient_id">
            <div v-for="(item, index) in scope.row.questionaireMap['CTPQ00000002']"
              :key="index"
              :class="item.questionnaire_status === 30 ? 'option-inside-true' : 'option-inside-false'"
              @click="openQuestionaire(scope.row.questionaireMap['CTPQ00000002'][index], scope.row)"
              >
              <img v-if="item.questionnaire_status === 30" class="icon-submit" src="@/assets/image/ok.svg" />
              <img v-else class="icon-submit" src="@/assets/image/gene-none.svg" />
              {{ item.questionnaire_abbr_name }}
            </div>
          </div>
        </template>
        <template v-else-if="'second-chemotherapy-way' == column.prop" v-slot="scope">
          <chemotherapy-col
            :row="scope.row"
            field="second_chemotherapy_type"
            type="second"
            @openQuestionaire="openQuestionaire($event, scope.row)"
            :key="scope.row.patient_id"
          />
        </template>
        <template v-else-if="'visit-date' == column.prop" v-slot="scope">
          <visit-col
            :row="scope.row"
            @openQuestionaire="openQuestionaire($event, scope.row)"
            @openNoticeDialog="openNoticeDialog"
            :key="scope.row.patient_id"
          />
        </template>
        <template v-else-if="'business_group_code' == column.prop" v-slot="scope">
          <div v-if="scope.row.business_group_name"
            :class="currentPage === '20' && scope.row.business_group_code === 'CTPJ00000007' ? 'project-name' : 'project-name can-click'"
            @click="jumpDetail(scope.row.business_group_code, '')"
            :key="scope.row.patient_id">
            {{ scope.row.business_group_name }}
          </div>
          <span v-else>未分组</span>
        </template>
        <template v-else-if="'curr_process_display_name' == column.prop" v-slot="scope">
          <div
            class="project-name can-click"
            @click="jumpDetail(scope.row.business_group_code, scope.row.patient_code)"
            :key="scope.row.patient_id">
            {{ scope.row.curr_process_display_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="75px" fixed="right">
        <template slot-scope="scope">
          <el-dropdown placement="bottom" trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-more" style="cursor: pointer;color: #0079fe"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="genetrials-common-dropdown">
              <el-dropdown-item @click.native="viewUserInfo(scope.row)">查看</el-dropdown-item>
              <el-dropdown-item @click.native="handleActionRemind('1', scope.row)">编辑</el-dropdown-item>
              <el-dropdown-item @click.native="handleActionRemind('2', scope.row)">删除</el-dropdown-item>
              <el-dropdown-item @click.native="handleActionRemind('3', scope.row)">
                {{ scope.row.process_status === '2' ? '继续研究' : '结束研究'}}
              </el-dropdown-item>
              <el-dropdown-item @click.native="viewIncidentInfo(scope.row.patient_id)">上报不良信息</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-on:size-change="onPageSizeChange" v-on:current-change="onPageIdxChange"
      :current-page="pagination.page" :page-sizes="[20, 50, 100]" :page-size="pagination.size" align="right"
      layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>
    <!-- 模态框 新建患者 -->
    <!-- <el-dialog v-if="modalAdd.isVisible" :visible.sync="modalAdd.isVisible" :title="modalAdd.title"
      :close-on-click-modal="false" :close-on-press-escape="false" width="1000px">
      <add-modify-patient :addOrEdit="modalAdd.addOrEdit" :patientInfo="modalAdd.patientInfo"
        @onSavedPatient="onSavedPatient">
      </add-modify-patient>
    </el-dialog> -->
    <dialog-patient-add
      v-if="modalAdd.isVisible"
      :dialogVisible="modalAdd.isVisible"
      :actionType="modalAdd.actionType"
      :fromPatientId="modalAdd.fromPatientId"
      @closeDialog="modalAdd.isVisible=false"
      @refresh="refresh"
    />

    <!-- 模态框 修改分组 -->
    <dialog-group
      v-if="modalChangeGroup.isVisible"
      :dialogVisible="modalChangeGroup.isVisible"
      :row="currentRow"
      @closeDialog="modalChangeGroup.isVisible = false"
      @refresh="refresh"
    />
    <dialog-questionaire
      v-if="showQuestionaire"
      :title="currentRow.name + '-' + currentRow.patient_code + '-' + currentRow.questionnaire_name"
      :dialogVisible="showQuestionaire"
      :questionnaireInfo="currentRow"
      @closeDialog="showQuestionaire = false"
      @refresh="refresh"
    />
    <dialog-notice
      v-if="showNotice"
      :dialogVisible="showNotice"
      :row="currentRow"
      @closeDialog="showNotice = false"
      @refresh="refresh"
    />
    <dialog-incident
      v-if="showIncident"
      hospitalName="北京肿瘤医院"
      :dialogVisible="showIncident"
      :patient-id="currentRow.patient_id"
      @closeDialog="showIncident = false"
    />
    <!-- 编辑受试者、删除受试者、继续/结束研究的动作提示 -->
    <dialog-action-remind
      v-if="actionRemindVisible"
      :dialogVisible="actionRemindVisible"
      :actionRemindInfo="actionRemindInfo"
      @close="handleActionRemindClose"
      @confirm="handleActionRemindConfirm"
    />
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import store from "@/store/index.js";
import defaultResponseNotify, { notifyError } from "../helpers/message.js";
import { getUrlParams } from "../helpers/urlHelper";
import DialogQuestionaire from './dialog/DialogQuestionaire.vue';
import DialogNotice from './dialog/DialogNotice'
import DialogIncident from './dialog/DialogIncident'
import DialogPatientAdd from './dialog/DialogPatientAdd'
import { getCatcUsers, updateChemotherapyType, modifyProcess } from '@/apis/user'
import GeneCol from './table/GeneCol.vue'
import StepCol from './table/StepCol.vue';
import ChemotherapyCol from './table/ChemotherapyCol.vue';
import VisitCol from './table/VisitCol.vue';
import DialogGroup from './dialog/DialogGroup.vue';
import dayjs from 'dayjs';
import ActionRemindMixin from '@/mixins/ActionRemindMixin'
export default {
  name: "PatientList",
  mixins: [ActionRemindMixin],
  components: {
    DialogQuestionaire,
    DialogNotice,
    GeneCol,
    StepCol,
    ChemotherapyCol,
    VisitCol,
    DialogIncident,
    DialogGroup,
    DialogPatientAdd
  },
  props: {},
  data() {
    return {
      questionnaireMenu: [
        { label: '家族史', prop: 'CTPQ00000012' },
        { label: '手术', prop: 'CTPQ00000007' },
        { label: '术后', prop: 'CTPQ00000006' },
        { label: '病理', prop: 'CTPQ00000005' },
      ],
      page_indexOrDetail: "",
      // 分页属性
      pagination: {
        size: 20,
        page: 1,
        total: 0,
      },
      loading: false,
      dtHeight: "100",
      // 表格数据
      dtDatum: [],
      // 搜索框
      searchForm: {
        name: "",
        patient_code: "",
        institute_id: "",
        principal_id: ""
      },
      // 负责人基础信息
      catcUsersList: [],
      // 负责人所属研究中心基础信息
      catcInstitutesList: [],
      // 模态框 add
      modalAdd: {
        isVisible: false,
        actionType: "1",
        patientInfo: {},
      },
      // 模态框 修改分组
      modalChangeGroup: {
        isVisible: false,
        patientId: "",
      },
      showQuestionaire: false,
      currentRow: {},
      currentPage: '',
      showChemotherapy: ['11', '12', '20', '21', '22', '23'],
      showVisit: ['11', '12', '13', '21', '22', '23'],
      showGene: ['11', '12', '13', '20', '21', '22', '23'],
      showCTDNA: ['20', '21', '22', '23'],
      showNotice: false,
      initCode: '',
      showIncident: false
    };
  },
  computed: {
    tableColumns: {
      get() {
        let columns = []
        if (!this.currentPage || this.currentPage == '10') {
          columns.push(...[
            {
              label: '研究中心',
              prop: 'institute_name',
              minWidth: '200px',
              width: ''
            },
            {
              label: '负责人',
              prop: 'principal_name',
              minWidth: '85px',
              width: ''
            },
            {
              label: '入组日期',
              prop: 'join_datetime',
              minWidth: '110px',
              width: ''
            }
          ])
        }
        if (this.showGene.includes(this.currentPage)) {
          columns.push(
            {
              label: '基因检测',
              prop: 'gene_state',
              minWidth: '',
              width: '170px'
            }
          )
          columns.push(
            {
              label: '家族史      手术          术后          病理',
              prop: 'questionnaire-state',
              minWidth: '',
              width: '260px'
            }
          )
        }
        if (this.showChemotherapy.includes(this.currentPage)) {
          columns.push(
            {
              label: '辅助化疗方式  辅助化疗周期',
              prop: 'first-chemotherapy-way',
              minWidth: '',
              width: '320px'
            }
          )
        }
        if (this.showCTDNA.includes(this.currentPage)) {
          columns.push(
            {
              label: '化疗后ctDNA',
              prop: 'second_ctdna_type',
              minWidth: '',
              width: '100px'
            }
          )
        }
        if (this.currentPage == '22') {
          columns.push(
            {
              label: '二线治疗',
              prop: 'second-treatment',
              minWidth: '',
              width: '300px'
            }
          )
        }
        if (this.currentPage == '21') {
          columns.push(
            {
              label: '辅助化疗方式  辅助化疗周期',
              prop: 'second-chemotherapy-way',
              minWidth: '',
              width: '320px'
            }
          )
        }
        if (this.showVisit.includes(this.currentPage)) {
          columns.push(
            {
              label: '术后访视（术后 * 个月）',
              prop: 'visit-date',
              minWidth: '',
              width: '660px'
            }
          )
        }
        if (!this.currentPage || this.currentPage === '10' || this.currentPage === '20') {
          columns.push(
            {
              label: '所属分组',
              prop: 'business_group_code',
              minWidth: '300px',
              width: ''
            }
          )
        }
        if (!this.currentPage || this.currentPage === '10') {
          columns.push({
            label: '当前进度',
            prop: 'curr_process_display_name',
            minWidth: '250px',
            width: ''
          })
        }
        return columns
      }
    }
  },
  methods: {
    // 分页方法
    onPageSizeChange(val) {
      this.pagination.size = val;
      this.goPage1ReloadDt();
    },
    onPageIdxChange(val) {
      console.log("page idx change");
      this.pagination.page = val;
      this.reloadDt();
    },
    reloadPage() {
      // 详细页面，功能有点不一样
      if (this.$route.path.includes("/CTPJ00000001/detail")) {
        this.page_indexOrDetail = "detail";
      } else {
        this.page_indexOrDetail = "index";
      }
      this.goPage1ReloadDt(true);
      this.reloadDic();
    },
    goPage1ReloadDt(isInit) {
      this.pagination.page = 1;
      this.reloadDt(isInit);
    },
    reloadDt(isInit) {
      this.loading = true;

      var _thisVue = this;

      if (this.page_indexOrDetail == "detail") {
        // 获取分组状态
        const currGroupStatus = getUrlParams("page");
        this.searchForm.groupStatus = currGroupStatus.replace(
          "ctac_group_",
          ""
        );
      }
      let patient_code = ''
      if (isInit && this.initCode) {
        patient_code = this.initCode
        this.searchForm.patient_code = this.initCode
      } else {
        patient_code = this.searchForm.patient_code
      }
      const params =
        `page=${_thisVue.pagination.page}&size=${_thisVue.pagination.size}`
      const body = {
        name: _thisVue.searchForm.name ? _thisVue.searchForm.name.trim() : '',
        patient_code: patient_code ? patient_code.trim() : '',
        join_status: this.currentPage,
        institute_id: this.searchForm.institute_id || undefined,
        principal_id: this.searchForm.principal_id || undefined
      };
      const payload = {
        params: params,
        body: body,
      }

      // 加载患者列表
      store.dispatch("getPagedPatients", payload).then(() => {
        _thisVue.loading = false;

        _thisVue.dtDatum = store.state.patients.data.map(item => {
          return {
            ...item,
            questionaireMap: this.formatQuestionaire(item.questionnaire_list, item.sms_alert_status)
          }
        });

        _thisVue.pagination.total = store.state.patients.total;
      }).finally(() => {
        this.loading = false
      })
    },
    buildMap(originList, code) {
      const map = {}
      originList.map(option => {
        if (map[option[code]]) {
          map[option[code]].push(option)
        } else {
          map[option[code]] = [option]
        }
      })
      
      return map
    },
    formatQuestionaire(questionaireList, sms_alert_status) {
      let map = this.buildMap(questionaireList, 'questionnaire_code')
      if (Object.keys(map).length === 0) {
        map = {
          CTPQ00000001: [],
          CTPQ00000005: [],
          CTPQ00000006: [],
          CTPQ00000007: [],
          CTPQ00000009: [],
          CTPQ00000011: []
        }
      } else {
        Object.keys(map).map(key => {
          if (key === 'CTPQ00000003' || key === 'CTPQ00000004') {
            map[`${key}_first`] = map[key].filter(item => {
              return item.questionnaire_business_type === 'first'
            }).sort((a, b) => {
              return a.number_of_fills > b.number_of_fills
            })
            map[`${key}_second`] = map[key].filter(item => {
              return item.questionnaire_business_type === 'second'
              }).sort((a, b) => {
              return a.number_of_fills > b.number_of_fills
            })
          } else if (key === 'CTPQ00000008') {
            // 术后访视状态判断
            map[key].map(ii => {
              let questionnaire_status_convert = 10
              if (sms_alert_status == 1) {
                // 开启消息提醒
                if (ii.questionnaire_status == 30) {
                  // 已完成
                  questionnaire_status_convert = 30
                } else if (ii.questionnaire_status == 10) {
                  // 未完成
                  if (ii.alert_except_time) {
                    if (dayjs().isAfter(dayjs(ii.alert_except_time))) {
                      // 已过期
                      questionnaire_status_convert = 90
                    }
                  }
                }
              } else {
                // 未开启消息提醒
                questionnaire_status_convert = ii.questionnaire_status
              }
              ii.questionnaire_status_convert = questionnaire_status_convert
              return ii
            })
            map[key].sort((a, b) => {
              return a.number_of_fills > b.number_of_fills
            })
          } else {
            map[key].sort((a, b) => {
              return a.number_of_fills > b.number_of_fills
            })
          }
        })
      }
      return map
    },
    reloadDic() {
      // 加载 基础数据
      getCatcUsers().then(res => {
        if (res.code === 200) {
          let list = res.entities || []
          this.catcUsersList = list.filter(ii => {
            if (ii.roles && ii.roles.some(yy => yy.role_code === 'user')) {
              return true
            } else return false
          })
          const temp = {}
          this.catcInstitutesList = this.catcUsersList.reduce((newList, value) => {
            if (value.institute_id && !temp[value.institute_id]) {
              temp[value.institute_id] = '1'
              newList.push({
                institute_code: value.institute_code,
                institute_id: value.institute_id,
                institute_name: value.institute_name
              })
            }
            return newList
          }, [])
        } else {
          this.catcUsersList = []
          this.catcInstitutesList = []
        }
      })
    },
    resizeDt() {
      const zoom = document.getElementsByTagName('body')[0].style.zoom;
      console.log('zoom', zoom)
      this.dtHeight = document.documentElement.clientHeight / zoom - 160;
      const tb = document.querySelector(
        "#patientTable .el-table__body-wrapper"
      );
      tb.style.height = `${this.dtHeight}px`;
      tb.style.overflowY = `auto`;
    },
    // 查询
    onSearch() {
      this.goPage1ReloadDt();
    },
    onReset() {
      this.searchForm = {
        name: "",
        patient_code: "",
        institute_id: "",
        principal_id: ""
      }
      this.onSearch();
    },
    // 添加患者信息
    onAddPatient() {
      this.modalAdd.title = "添加";
      this.modalAdd.actionType = "1";
      this.modalAdd.isVisible = true;
    },
    onSavedPatient() {
      this.modalChangeGroup.isVisible = false;
      this.modalAdd.isVisible = false;
      this.reloadDt();
    },
    // 修改患者信息
    onEditPatient(patientId) {
      this.modalAdd.title = "编辑";
      this.modalAdd.actionType = "3";
      this.modalAdd.isVisible = true;
      this.modalAdd.fromPatientId = patientId
    },
    onDeletePatient(patientId) {
      var _thisVue = this;
      const payload = {
        patient_id: patientId,
      };
      store.dispatch("deletePatient", payload).then((response) => {
        defaultResponseNotify(response);
        _thisVue.reloadDt();
        store.dispatch("getPatientCount");
      });
    },
    // 修改患者分组
    onChangePatientGroup(row) {
      this.modalChangeGroup.isVisible = true;
      this.currentRow = row
    },
    // 判断 手术、术后、病理、筛选 点击了哪个按钮，打开相应的弹窗
    questionnaireMenuHandle(prop) {
      console.log(prop)
    },
    // 修改化疗方式
    chemotherapyChangeHandle(index, patient_id, chemotherapy_type) {
      updateChemotherapyType({
        patient_id: patient_id,
        chemotherapy_index: index,
        chemotherapy_type: chemotherapy_type
      }).then(res => {
      })
    },
    openQuestionaire(item, rowInfo) {
      if (item.questionnaire_code === 'CTPQ00000002') {
        item.questionnaire_name_convert = '第' + item.questionnaire_abbr_name + '周期' + item.questionnaire_name.replace('周期', '')
      }
      this.currentRow = {
        questionnaire_name: item.questionnaire_name_convert || item.questionnaire_name,
        questionnaire_code: item.questionnaire_code,
        resultId: item.result_id,
        name: rowInfo.name || '',
        patient_code: rowInfo.patient_code || ''
      }
      // 筛选需要根据09的状态确认打开哪个问卷
      if (item.point === 100) {
        this.currentRow.questionnaire_name = '筛选2'
        this.currentRow.questionnaire_code = 'CTPQ00000011'
        this.currentRow.resultId = rowInfo.questionaireMap['CTPQ00000011'][0].result_id
        this.currentRow.name = rowInfo.name || '',
        this.currentRow.patient_code = rowInfo.patient_code || ''
      }
      
      this.showQuestionaire = true
    },
    
    getCurrentPage() {
      this.currentPage = this.$route.query?.page?.replace('ctac_group_', '')
      this.initCode = this.$route.query?.patient_code
    },
    refresh() {
      this.onSearch()
    },
    openNoticeDialog(row) {
      this.currentRow = row
      this.showNotice = true
    },
    endAll(row) {
      const params = {
        patient_id: row.patient_id,
        process_status: row.process_status === '1' ? 2 : 1
      }
      modifyProcess(params).then(res => {
        if (res.code === 200) {
          defaultResponseNotify(res);
          this.onSearch()
        }
      })
    },
    viewUserInfo(row) {
      this.modalAdd.title = "查看";
      this.modalAdd.actionType = "2";
      this.modalAdd.isVisible = true;
      this.modalAdd.fromPatientId = row.patient_id
    },
    jumpDetail(business_group_code, patient_code) {
      const map = {
        'CTPJ00000003': '10',
        'CTPJ00000004': '11',
        'CTPJ00000005': '12',
        'CTPJ00000006': '13',
        'CTPJ00000007': '20',
        'CTPJ00000008': '21',
        'CTPJ00000009': '22',
        'CTPJ00000010': '23',
      }
      if (patient_code) {
        // 点击当前进度
        // 新开界面
        window.open(`/CTPJ00000001/detail?page=ctac_group_${map[business_group_code]}&timestamp=${Date.now()}&patient_code=${patient_code}`, '_blank')
      } else {
        // 点击所属分组
        // 无patient_code
        if (this.currentPage === '20' && business_group_code === 'CTPJ00000007') return
        this.$router.push({
          path: "/CTPJ00000001/detail",
          query: {
            page: `ctac_group_${map[business_group_code]}`,
            timestamp: Date.now()
          },
        });
      }
    },
    viewIncidentInfo(patient_id) {
      this.currentRow.patient_id = patient_id
      this.showIncident = true
    }
  },
  watch: {
    $route(to, from) {
      // 每个页面，筛选条件不一样
      this.getCurrentPage()
      this.searchForm.patient_code = ''
      this.searchForm.institute_id = ''
      this.searchForm.principal_id = ''
      this.$nextTick(() => {
        this.$refs.patientTable.doLayout()
      })
      this.reloadPage();
    },
  },
  created() {
    var _thisVue = this;
  },
  mounted() {
    // 载入dt
    this.getCurrentPage()
    this.reloadPage();
    // resize
    this.$nextTick(() => {
      this.resizeDt();
      window.onresize = () => {
        this.resizeDt();
      };
    });
  },
};
</script>

<style lang="scss">
.patient-list-container {
  background: #fff;
  // margin: 10px;
  // border-radius: 8px;
  padding: 10px;
  .patient-name {
    color: #0079fe;
    cursor: pointer;
    &.grey {
      color: #999;
      cursor: default;
    }
  }
  .project-name {
    display: inline-block;
    &.can-click {
      color: #0079fe;
      cursor: pointer;
    }
  }
}

.patient-list-container .my-row {
  display: flex;
  align-items: start;
  justify-content: flex-end;
  padding: 0;
}

.patient-list-container .text-btn-in-dg {
  margin-right: 10px;
}

.patient-list-container .my-link {}

.patient-list-container .my-link:hover {
  cursor: pointer;
}

.option-out-div div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 24px;
  border-right: none;
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  cursor: pointer;
  img {
    margin-right: 3px;
  }
}

.visit-date div {
  width: 42px;
  .clock {
    width: 12px;
    margin: 0;
  }
}

.chemotherapy-way {
  display: flex;
}

.chemotherapy-way .el-select {
  border-right: 0px;
  height: 24px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  flex: 0 0 79px;
  width: 79px;
  padding: 0;
  .el-input {
    padding: 0;
    border-radius: 0;
    width: 100%;
  }
  .el-input__inner {
    height: 24px;
    font-size: 12px;
    padding: 0 6px;
    border: none;
    background: none;
    line-height: 24px;
  }
  .el-input__icon {
    line-height: 24px;
  }
  .el-input__suffix {
    right: 0;
  }
  &.option-inside-true .el-input__icon {
    color: #50b240;
  }
}
.option-inside-false.blank {
  width: 240px;
}
.second-group {
  border: 1px solid #d4d4d4;
  color: #333333;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  img {
    margin-right: 5px;
  }
  &.success {
    border: 1px solid #50b240;
    color: #50b240;
  }
}
.option-content {
  margin-right: -1px;
}
.option-inside-true {
  border: 1px solid #50b240;
  color: #50b240;
  .el-input__inner {
    color: #50b240;
  }
}
.option-inside-expired {
  border: 1px solid #F6CAAA;
  // color: #eb8127;
}

.option-inside-false {
  border: 1px solid #d4d4d4;
  color: #333333;
  flex: 1;
}

.option-out-div div:last-child {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.option-out-div div:first-child {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.option-out-div div:last-child.option-inside-false {
  border-right: 1px solid #d4d4d4;
}

.option-out-div div:last-child.option-inside-true {
  border-right: 1px solid #50b240;
}

.option-out-div div:last-child.option-inside-expired {
  border-right: 1px solid #F6CAAA;
}

.option-out-div .icon-submit {
  width: 10px;
}

.visit-date .icon-submit {
  margin-right: 3px;
}</style>
