import Vue, {h} from 'vue'
import ElementUI, { Notification } from 'element-ui'

Vue.prototype.$notify = Notification

export const notifySuccess = (message) => {
  Notification({
    title: 'Success',
    message: message ? message : '操作成功',
    type: 'success',
    position: 'bottom-right'
  })
}

export const notifyError = (message) => {
  Notification({
    title: 'Error',
    message: message ? message : '操作失败',
    type: 'error',
    position: 'bottom-right'
  })
}

export const notifyWarning = (message) => {
  Notification({
    title: 'Warning',
    message: message ? message : 'Warning',
    type: 'warning',
    position: 'bottom-right'
  })
}

const defaultResponseNotify = (response) => {

  if (!response) {
    notifyError('未正常返回请求结果')
    return;
  }

  const code = response.code;
  const message = response.message;

  if (code == 200) {
    notifySuccess(message ? message : '操作成功')
  }
  else if (code == 500) {
    notifyError(message ? message : '操作失败')
  }
  else {
    notifyWarning(message ? message : '异常')
  }
}

export default defaultResponseNotify

