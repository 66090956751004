<template>
  <div class="log-detail-vue-container">
    <div class="card">
      <div class="card-title">
        <i class="el-icon-setting"></i>
        入参
      </div>
      <div class="card-content">
        <json-viewer :value="inputParams"></json-viewer>
      </div>
    </div>
    <div class="card">
      <div class="card-title">
        <i class="el-icon-setting"></i>
        出参
      </div>
      <div class="card-content">
        <json-viewer :value="outputParams"></json-viewer>
      </div>
    </div>
  </div>
</template>

<script>
import vue from "vue";
import JsonViewer from "vue-json-viewer";
vue.use(JsonViewer);

export default {
  name: "logDetail",
  props: {
    logDetail: Object,
  },
  components: {
    JsonViewer,
  },
  data() {
    return {
      inputParams: "",
      outputParams: "",
    };
  },
  methods: {},
  created() {},
  mounted() {
    console.log("日志详情2345", this.logDetail);
    this.inputParams = JSON.parse(this.logDetail.method_input_params);
    this.outputParams = JSON.parse(this.logDetail.method_output_params);
  },
  watch: {},
};
</script>

<style>
.log-detail-vue-container {
}

.log-detail-vue-container .card {
  margin-bottom: 15px;
}

.log-detail-vue-container .card-title {
  padding: 10px 10px 10px 10px;
  border: 1px solid #008fdc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 14px;
  color: white;
  background-color: #0089d3;
}

.log-detail-vue-container .card-content {
  padding: 10px 10px 10px 10px;
  border: 1px solid #008fdc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 14px;
  min-height: 120px;
}
</style>