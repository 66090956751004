<template>
  <van-dialog v-model="show"
    class="dialog-add-user-mb"
    title="添加受试者入组"
    confirm-button-color="#0079fe"
    showCancelButton
    :before-close="onBeforeClose"
    @confirm="onSubmit"
    @close="closeDialog">
    <van-form ref="form">
      <van-field
        v-model="query.external_patient_code"
        name="external_patient_code"
        label="受试者编号"
        placeholder="请输入"
        required
        :rules="[{ required: true, message: '请输入' }]"
      />
      <van-field name="p1_value" label="P1" required
        :rules="[{ required: true, message: '请选择' }]"
      >
        <template #input>
          <van-radio-group v-model="query.p1_value" direction="horizontal">
            <van-radio name="10">阳性+</van-radio>
            <van-radio name="0">阴性-</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="p2_value" label="P2" required
        :rules="[{ required: true, message: '请选择' }]"
      >
        <template #input>
          <van-radio-group v-model="query.p2_value" direction="horizontal">
            <van-radio name="10">阳性+</van-radio>
            <van-radio name="0">阴性-</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="stage" label="临床分期" required
        :rules="[{ required: true, message: '请选择' }]"
      >
        <template #input>
          <van-radio-group v-model="query.stage" direction="horizontal">
            <van-radio name="20">II期</van-radio>
            <van-radio name="30">IIIA期</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="mutation_state" label="EGFR突变状态" required
        :rules="[{ required: true, message: '请选择' }]"
      >
        <template #input>
          <van-radio-group v-model="query.mutation_state" direction="horizontal">
            <van-radio name="10">19del</van-radio>
            <van-radio name="20">L858R</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="add-user-tips">受试者信息添加后不能修改，请确认信息无误</p>
    </van-form>
  </van-dialog>
</template>
<script>
import { apiAddPatient } from '@/apis/ECTOP/api'
import { Toast } from 'vant'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      query: {
        external_patient_code: '',
        p1_value: '',
        p2_value: '',
        stage: '',
        mutation_state: ''
      }
    }
  },
  watch: {
    showDialog(val) {
      this.show = val
      if (val) {
        this.$refs.form && this.$refs.form.resetValidation()
        this.query = {
          external_patient_code: '',
          p1_value: '',
          p2_value: '',
          stage: '',
          mutation_state: ''
        }
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    onBeforeClose(action, done) {
      if (action === "confirm") {
        return done(false);//直接return它即可阻止
      }
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    onSubmit() {
      this.$refs.form.validate().then((valid) => {
        apiAddPatient(this.query).then(res => {
          if (res.code === 200) {
            this.$emit('refresh', {
              type: 'addSuccess',
              msg: res.message
            })
          } else {
            Toast.fail(res.message)
          }
        })
      }).catch(err => {

      })
    }
  }
}
</script>
<style lang="scss">
.dialog-add-user-mb {
  &.van-dialog {
    top: 50%;
  }
  .van-dialog__header {
    padding-top: 16px;
  }
  .van-cell {
    flex-direction: column;
    padding: 5px 16px 10px;
  }
  .van-form {
    padding: 10px 20px 0 20px;
  }
  .van-radio-group--horizontal {
    width: 100%;
  }
  .van-field__label {
    width: 100%;
    margin-bottom: 4px;
  }
  .van-radio--horizontal {
    width: calc(50% - 6px);
    &:last-child {
      margin-right: 0;
    }
    .van-radio__label {
      background: #f5f5f5;
      color: #333;
      padding: 7px 15px;
      border-radius: 4px;
      margin-right: 10px;
      font-size: 14px;
      margin: 0;
      width: 100%;
      text-align: center;
    }
    .van-radio__icon {
      display: none;
    }
  }
  .van-radio--horizontal[aria-checked="true"] .van-radio__label {
    color: #0079fe;
    background: #e7f1ff;
  }

  .van-radio__label--disabled {
    color: #C0C4CC;
    background-color: #F5F7FA;
    cursor: not-allowed;
  }

  .van-radio--checked .van-radio__label {
    background-color: #409EFF;
    border-color: #409EFF;
    color: white;
  }
  .add-user-tips {
    text-align: center;
    color: #000;
    font-size: 14px;
  }
  .van-field__body {
    input {
      font-size: 14px;
      -webkit-text-size-adjust: none;
    }
  }
}
</style>