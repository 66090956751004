import { requestGet, requestPost, requestPostBlob } from "@/utils/request";
import store from '@/store'

// 修改化疗方式
export function updateChemotherapyType(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/patient/chemotherapy-type/modify', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 修改研究状态
export function modifyProcess(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/patient/process/modify', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 修改CtDNA
export function modifyCtDNA(params) {
  return new Promise((resolve, reject) => {
    requestPost('/patient/ctdna/modify', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 获取受试者信息
export function patientPaged(params) {
  return new Promise((resolve, reject) => {
    requestPost('/patient/paged?page=1&size=5', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 新增受试者
export function patientAdd(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/patient/add', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 修改受试者信息
export function patientModify(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/patient/modify', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 获取catc用户信息
export function getCatcUsers() {
  return new Promise((resolve, reject) => {
    requestGet('/user/list').then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 获取受试者详情
export function patientDetail(params) {
  return new Promise((resolve, reject) => {
    requestGet(store.state.currentPrefix + `/patient/detail?patient_id=${params}`).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 获取分组受试者信息
export function getGroupUserList(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/group?page=${params.page}&size=${params.size}`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 获取所有受试者信息
export function getAllUserList(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/index?page=${params.page}&size=${params.size}`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

//  修改P1P2
export function updateP1P2(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/P1P2/modify`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 修改MRD状态
export function updateMRD(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/ngs-report/lims`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 导出CRF
export function exportCRF(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/crf/export`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 添加受试者治疗记录
export function addChemotherapy(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/chemotherapy/add`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 获取治疗记录
export function getCureRecords(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/visitation-correlation/cure-records`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 查询受试者编号是否已存在
export function checkExternalPatientCode(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/patient-code/exist`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 编辑关联治疗
export function visitationCorrelationEdit(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/visitation-correlation/edit`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 查看关联治疗
export function visitationCorrelationView(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/visitation-correlation/view`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 查看生活质量问卷得分
export function qolScoreView(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/qol/score/view`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 校验筛查问卷是否通过
export function canJoinGroup(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/can-join-group`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}