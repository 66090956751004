import DialogActionRemind from '@/components/dialog/DialogActionRemind'
const mixin = {
  components: {
    DialogActionRemind
  },
  data() {
    return {
      actionRemindVisible: false,
      actionRemindInfo: {}
    }
  },
  methods: {
    handleActionRemind(actionType, row) {
      this.actionRemindInfo = {
        patient_id: row.patient_id,
        process_status: row.process_status,
        actionType
      }
      this.actionRemindVisible = true
    },
    handleActionRemindConfirm() {
      if (this.actionRemindInfo.actionType === '1') {
        // 编辑
        this.onEditPatient(this.actionRemindInfo.patient_id)
      } else if (this.actionRemindInfo.actionType === '2') {
        // 删除
        this.onDeletePatient(this.actionRemindInfo.patient_id)
      } else if (this.actionRemindInfo.actionType === '3') {
        // 继续/结束研究
        this.endAll(this.actionRemindInfo)
      }
      this.actionRemindVisible = false
    },
    handleActionRemindClose() {
      this.actionRemindVisible = false
    }
  }
}
export default mixin