/**
 * 网络请求配置
 */
 import axios from "axios";
 import throttle from 'lodash/throttle'
 import { notifyError } from '@/helpers/message'
 import store from '@/store'

 axios.defaults.timeout = 300000; // 单位毫秒
//  axios.defaults.baseURL = "/api";
 
const throttledMessageFunction = throttle(() => {
  // 执行需要被节流的函数
  notifyError('服务异常，请稍后重试')
}, 4000);

 /**
  * http request 拦截器
  */
const excludeUrls = ['/login/sms-code', '/login/login']
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken')
  const accessExpire = localStorage.getItem('accessExpire')
  if (!excludeUrls.includes(config.url) && Date.parse(accessExpire) < Date.now()) {
    // 已过期
    if (localStorage.getItem('loginType') === 'mb') {
      window.location = `${store.state.currentPrefix}/mb/login`
    } else {
      window.location = '/login'
    }
    return
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})
 
axios.interceptors.response.use((res) => {
  /** *
     * 系统错误码
     */
  if (res.code == '401') {
    res.data.message = '异常错误'
    if (localStorage.getItem('loginType') === 'mb') {
      window.location = `${store.state.currentPrefix}/mb/login`
    } else {
      window.location = '/login'
    }
  }
  return res
}, (error) => {
  if (error?.response?.status === 401) {
    if (localStorage.getItem('loginType') === 'mb') {
      window.location = `${store.state.currentPrefix}/mb/login`
    } else {
      window.location = '/login'
    }
  } else {
    throttledMessageFunction()
  }
  return Promise.reject(error)
})
 /**
  * 封装get方法
  * @param url  请求url
  * @param params  请求参数
  * @returns {Promise}
  */
 export function requestGet(url, params = {}) {
   return new Promise((resolve, reject) => {
     axios.get(url, {
         params: params,
       }).then((response) => {
         resolve(response.data);
       })
       .catch((error) => {
         reject(error);
       });
   });
 }
 
 /**
  * 封装post请求
  * @param url
  * @param data
  * @returns {Promise}
  */
 
 export function requestPost(url, data) {
   return new Promise((resolve, reject) => {
     axios.post(url, data).then(
       (response) => {
         resolve(response.data);
       },
       (err) => {
         reject(err);
       }
     );
   });
 }
 
 export function requestPut(url, data) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function requestDelete(url, data) {
  return new Promise((resolve, reject) => {
    axios.delete(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// 文件流post请求
export function requestPostBlob(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, {responseType: 'blob'}).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}