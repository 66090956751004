<template>
  <log-list></log-list>
</template>

<script>
import LogList from "@/components/LogList.vue";
export default {
  name: "LogView",
  components: {
    LogList,
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style>
</style>