
export const getBaseUrl = (url) => {
  if (!url) {
    return '';
  }

  const baseUrl = url
    .split('?')[0]
  return baseUrl;
}

export const getUrlParams = (name) => {
  const url = window.location.href
  const params = url.split('?')[1]
  const vars = params.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == name) {
      return pair[1];
    }
  }
  return '';
}

export const getUrlLastSegment = (url) => {
  if (!url) {
    return '';
  }

  const lastSeg = url
    .split('?')[0]
    .split('/')
    .filter(u => u !== '')
    .pop();
  return lastSeg;
}

