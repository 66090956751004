export const finishStatusArr = [{
  value: '10',
  label: '未完成'
},{
  value: '20',
  label: '进行中'
},{
  value: '30',
  label: '已完成'
}]

export const groupTypeArr = [{
  value: '00',
  label: '待分组'
},{
  value: '100',
  label: '标准组'
},{
  value: '200',
  label: 'MRD组'
},{
  value: '90',
  label: '不满足入组条件'
}]