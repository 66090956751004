import { requestGet, requestPost } from "@/utils/request"

// 通过订单号查询lims中的报告
export function getReportInfoFromLIMS(params) {
  return new Promise((resolve, reject) => {
    requestGet(`/ngs-report/lims?lims_order_code=${params}`).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 确认lims报告并信息补充
export function postReportLIMS(params) {
  return new Promise((resolve, reject) => {
    requestPost('/ngs-report/lims', params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 查看基因报告详情（已确认过报告）
export function getReportDetailFromLIMS(patient_id, number_of_fills) {
  return new Promise((resolve, reject) => {
    requestGet(`/ngs-report/detail?patient_id=${patient_id}&number_of_fills=${number_of_fills}`).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}