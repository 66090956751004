<template>
  <div>
    <el-dialog
      v-if="dialogVisible"
      title="ctDNA检测结果"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog modal-group"
      :width="dialogWidth"
      append-to-body
      @close="closeDialog"
    >
      <div class="modal-main">
        <el-radio v-model="checked" :label="1" border>
          ctDNA+
          <p class="radio-info" v-if="row.business_group_code && isShowTipsArray.includes(row.business_group_code)">
            选择该选项后，系统将会按照1:2的比例，将受试者随机分到“④辅助化疗”组和“⑤二线治疗”组
          </p>
        </el-radio>
        <el-radio v-model="checked" :label="2" border>
          ctDNA-
          <p class="radio-info" v-if="row.business_group_code && isShowTipsArray.includes(row.business_group_code)">
            选择该选项后，系统将会分到⑥ctNDA-，观察组
          </p>
        </el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <DialogMessage
      :dialogVisible="dialogMessageVisible"
      :message-info="dialogMessageMsg"
      :isShowCancel="true"
      @confirm="handleDialogMessageConfirm"
      @cancel="dialogMessageVisible=false"
    />
  </div>
</template>
<script>
import { modifyCtDNA } from '@/apis/user'
import defaultResponseNotify from "@/helpers/message.js";
import DialogMessage from './DialogMessage'
import store from "@/store/index.js";

export default {
  props: {
    dialogVisible: {
      default: false,
      type: Boolean
    },
    row: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  components: {
    DialogMessage
  },
  computed: {
    dialogWidth() {
      if (this.row.business_group_code && this.isShowTipsArray.includes(this.row.business_group_code)) {
        return '640px'
      } else {
        return '274px'
      }
    }
  },
  data() {
    return {
      checked: 0,
      isShowTipsArray: ['CTPJ00000007', 'CTPJ00000008', 'CTPJ00000009', 'CTPJ00000010'],
      dialogMessageMsg: {},
      dialogMessageVisible: false
    }
  },
  created() {
    if (this.row.second_ctdna_type) {
      this.checked = this.row.second_ctdna_type
    }
  },
  methods: {
    confirm() {
      if (this.row.second_ctdna_type) {
        this.dialogMessageMsg = {
          type: 'warning',
          msg: '提交后可能会导致受试者重新分组，是否确定需要提交？'
        }
        this.dialogMessageVisible = true
      } else {
        this.updateInfo()
      }
    },
    handleDialogMessageConfirm() {
      this.dialogMessageVisible = true
      this.updateInfo()
    },
    updateInfo() {
      const params = {
        patient_id: this.row.patient_id,
        ctdna_index: 'second',
        ctdna_type: this.checked
      }
      modifyCtDNA(params).then(res => {
        if (res.code === 200) {
          this.closeDialog()
          this.$emit('refresh')
          store.dispatch("getPatientCount");
          defaultResponseNotify(res);
        } else {
          defaultResponseNotify(res);
        }
      })
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
  }
}
</script>
<style lang="scss" scoped>
.modal-group {
  .modal-main {
    background: #fff;
    padding: 0 15px 10px;
    display: flex;
    flex-direction: column;
  }
  .el-radio.is-bordered {
    width: 100%;
    margin: 0 0 10px;
    height: 58px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }
  .radio-info {
    color: #999;
    font-size: 12px;
    margin: 8px 0px 0;
    font-weight: normal;
  }
}
</style>