import { requestPost, requestGet } from "@/utils/request"
import store from '@/store'

// 发送验证码
export function apiSendSMSCode(params) {
  return new Promise((resolve, reject) => {
    requestPost('/login/sms-code', params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// C端受试者列表
export function apiMbIndex(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/index-mb?page=${params.page}&size=${params.size}`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// B端受试者列表
export function apiPatientIndex(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/index?page=${params.page}&size=${params.size}`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 删除受试者
export function apiDeletePatient(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/delete`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 新建受试者
export function apiAddPatient(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/add`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

//  获取所有项目
export function apiGetProjects() {
  return new Promise((resolve, reject) => {
    requestGet(`/login/projects`, {}).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}