<template>
  <div class="login-view-container">
    <div class="login-block">
      <div class="left-block">
        <div>&nbsp;</div>
        <div>博瑞哲</div>
        <div>BRILLIANCY HEALTH</div>
        <div>临床科研管理系统</div>
      </div>
      <div class="right-block">
        <div class="tips">用户登录</div>
        <el-form :model="formLogin" :rules="rulesLogin" ref="formLogin" class="login-form">
          <el-form-item prop="username">
            <el-input
              v-model="formLogin.username"
              id="loginUserName"
              autocomplete="autocomplete"
              placeholder="手机号"
              v-on:keyup.enter.native="keyupSubmitForm('formLogin')"
            >
              <template slot="prepend">
                <img src="@/assets/image/phone-number.svg" />
              </template>
            </el-input>
            <span
              class="link-btn-send-code"
              autocomplete="off"
              v-on:click="onWantToSendSmsCode()"
            >
              {{ btnName_sendSmsCode }}
            </span>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="formLogin.password"
              autocomplete="new-password"
              placeholder="验证码"
              ref="password"
              v-on:keyup.enter.native="keyupSubmitForm('formLogin')"
            >
              <template slot="prepend">
                <img src="@/assets/image/password.svg" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item style="margin-top: 15px">
            <el-button
              type="primary"
              class="confirm-btn"
              :disabled="isLoginDisabled"
              v-on:click="submitForm('formLogin')"
            >
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <p class="copyright">© 2023 苏ICP备2023026406号-2 苏州博瑞哲生物科技有限公司版权所有</p>
    <el-dialog
      title=""
      :visible.sync="graphValidateDialog.isVisible"
      width="300px"
      append-to-body
      class="validate-code-dialog"
    >
      <div class="dialog-title">请输入图形验证码</div>
      <div style="margin-top: 15px">
        <el-input
          placeholder="请输入"
          v-model="graphValidateDialog.inputValidateCode"
          ref="inputValidateCode"
          class="input-validate-code"
          v-on:keyup.enter.native="onKeyupSendSmsCode()"
        >
          <canvas
            id="canvasImgVerify"
            :width="100"
            :height="44"
            slot="append"
            class="canvas-img-verify"
            v-on:click="onRefreshValidateCode()"
          ></canvas>
        </el-input>
        <p v-if="validateCodeError" class='captcha-tips'>验证码错误，请重新输入</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          ref="btnConfirmValidateCode"
          type="primary"
          v-on:click="onSendSmsCode()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store/index.js";
import defaultResponseNotify from "../helpers/message.js";
import router from "@/router/index.js";
import verifyImgCode from "../helpers/verifyImgCode";
import { mapMutations } from 'vuex'

export default {
  name: "LoginView",
  components: {},
  data() {
    const checkUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
        return;
      }

      if (value.trim().length != 11) {
        callback(new Error("手机号必须是11位数字"));
        return;
      }

      if (isNaN(value)) {
        callback(new Error("手机号必须是11位数字"));
        return;
      }

      callback();
    };
    const checkPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("验证码不能为空"));
      }

      callback();
    };
    return {
      formLogin: {
        username: "",
        password: "",
      },
      btnName_sendSmsCode: "发送验证码",
      timespanHasSendSmsCode: 0,
      isLoginDisabled: false,
      rulesLogin: {
        username: [{ validator: checkUsername, trigger: "blurqq" }],
        password: [{ validator: checkPassword, trigger: "blurqq" }],
      },
      graphValidateDialog: {
        isVisible: false,
        inputValidateCode: "",
        validateCode: "",
      },
      validateCodeError: false,
      timer: null
    };
  },
  watch: {
    // 监控已发送短信的时间
    timespanHasSendSmsCode: function (val) {
      if (val && !isNaN(val)) {
        this.btnName_sendSmsCode = `${val}s`;
      } else {
        this.btnName_sendSmsCode = "发送验证码";
        clearInterval(this.timer)
        this.timer = null
      }
    },
  },
  created() {
    this.resetAllProjects()
  },
  methods: {
    ...mapMutations(['resetAllProjects']),
    // 打算 获取sms验证码
    onWantToSendSmsCode() {
      if (this.timespanHasSendSmsCode && !isNaN(this.timespanHasSendSmsCode)) {
        return;
      }

      var _thisVue = this;
      this.graphValidateDialog.inputValidateCode = "";

      this.$refs["formLogin"].validateField("username", function (valid) {
        if (!valid) {
          _thisVue.validateCodeError = false
          _thisVue.graphValidateDialog.isVisible = true;
          setTimeout(function () {
            _thisVue.onRefreshValidateCode();

            _thisVue.$refs.inputValidateCode.focus();
          }, 200);
        } else {
          return false;
        }
      });
    },
    // 获取sms验证码
    onKeyupSendSmsCode() {
      this.onSendSmsCode();
    },
    onSendSmsCode() {
      if (this.graphValidateDialog.inputValidateCode == "") {
        this.onRefreshValidateCode();
        this.validateCodeError = true
        return;
      }

      if (
        this.graphValidateDialog.inputValidateCode.toLowerCase() !=
        this.graphValidateDialog.validateCode.toLowerCase()
      ) {
        this.onRefreshValidateCode();
        this.graphValidateDialog.inputValidateCode = "";
        this.validateCodeError = true
        return;
      }
      this.validateCodeError = false
      // 验证成功
      this.graphValidateDialog.isVisible = false;

      const payload = {
        phone_number: this.formLogin.username,
      };
      var _thisVue = this;
      store.dispatch("sendSmsCode", payload).then((data) => {
        console.log(data);
        defaultResponseNotify(data);
        if (data.code == 200) {
          this.$refs.password.focus();
          _thisVue.timespanHasSendSmsCode = 59;
          this.timer = setInterval(function () {
            if (_thisVue.timespanHasSendSmsCode > 0) {
              _thisVue.timespanHasSendSmsCode--;
            }
          }, 1000);
        }
      });
    },
    // 刷新验证码
    onRefreshValidateCode() {
      const code = verifyImgCode.makeCode(4);
      this.graphValidateDialog.validateCode = code;
      verifyImgCode.drawPic("canvasImgVerify", code, 100, 44);
    },
    keyupSubmitForm(formName) {
      this.submitForm(formName);
    },
    submitForm(formName) {
      // 禁止多次提交
      this.isLoginDisabled = true;
      var _thisVue = this;
      setTimeout(function () {
        _thisVue.isLoginDisabled = false;
      }, 2000);

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let username = this.formLogin.username;
          let password = this.formLogin.password;

          const payload = {
            phone_number: username,
            sms_code: password,
          };

          // 登录
          store.dispatch("login", payload).then((data) => {
            store
              .dispatch("fetchCurrUser")
              .then(() => {
                defaultResponseNotify(data);
                if (data.code == 200) {
                  localStorage.setItem('loginType', 'pc')
                  setTimeout(function () {
                    console.log("到主页");
                    router.push("/home");
                    console.log("到主页了");
                  }, 1000);
                }
              })
              .catch((e) => {
                console.log("失败");
              });
            console.log("test login");
          });
        } else {
          return false;
        }
      });
    },
  }
};
</script>

<style lang="scss">
.login-view-container {
  height: 100%;
  width: 100%;
  background-image: url(@/assets/image/bg-login-new.png);
  background-size: 100% 100%;
}
.login-view-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .login-block {
    width: 900px;
    height: 540px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 50px 0px rgb(8 65 131 / 20%);
    .left-block {
      box-sizing: border-box;
      width: 50%;
      height: 100%;
      background-image: url(@/assets/image/login-left.png);
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      padding-left: 40px;
      > div:nth-child(1) {
        height: 8px;
        width: 40px;
        background: #fff;
      }
      > div:nth-child(2) {
        margin-top: 20px;
        font-size: 32px;
        letter-spacing: 5px;
        font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      }
      > div:nth-child(3) {
        margin-top: 20px;
        font-size: 30px;
        letter-spacing: 5px;
        font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      }
      > div:nth-child(4) {
        margin-top: 20px;
        font-weight: 650;
        font-size: 42px;
        letter-spacing: 5px;
      }
    }
    .right-block {
      background: #fff;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .tips {
        font-weight: 500;
        color: #0079fe;
        font-size: 28px;
        margin-bottom: 40px;
      }
      .login-form {
        padding-left: 72px;
        padding-right: 60px;
        width: 100%;
        box-sizing: border-box;
        .el-form-item {
          margin-bottom: 18px;
        }
        .el-form-item.is-error {
          .el-input-group__prepend {
            border-color: #f56c6c;
          }
        }
        .el-form-item__content {
          .el-input-group--prepend {
            .el-input-group__prepend {
              border-width: 0 0 1px 0;
              background: #FFF;
              border-bottom-left-radius: 0;
              padding-left: 0;
            }
            .el-input__inner {
              border-width: 0 0 1px 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }
          .confirm-btn {
            border-radius: 80px;
            width: 100%;
            height: 36px;
            margin-top: 30px;
          }
        }
        .link-btn-send-code {
          position: absolute;
          top: 0;
          right: 5px;
          color: #1483fe;
          font-size: 16px;
          cursor: pointer;
        }
      }
      
    }
  }
}
/* 验证码对话框 */
.validate-code-dialog {
  // top: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.validate-code-dialog .el-dialog {
  top: 0px;
}

.validate-code-dialog .dialog-title {
  font-size: 16px;
  font-weight: bold;
}

.validate-code-dialog input {
  height: 58px;
  line-height: 58px;
  font-size: 16px;
}

.validate-code-dialog .el-input-group__append {
  width: 110px !important;
  height: 48px !important;
  padding-top: 4px;
  padding-left: 8px;
  padding-bottom: 4px;
  padding-right: 8px;
  background-color: white;
}

.validate-code-dialog .canvas-img-verify {
  cursor: pointer;
}

.gene-login-footer {
  position: absolute;
  z-index: 999;
  bottom: 10px;
  left:10%;
}

.validate-code-dialog .captcha-tips {
  color: red;
  margin: 10px 0 0;
}
.copyright {
  font-size: 12px;
  color: #fff;
  width: 100%;
  text-align: center;
  padding-bottom: 24px;
  position: fixed;
  bottom: -20px;
}
</style>