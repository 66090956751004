<template>
  <el-dialog
    v-if="dialogVisible"
    :title="title"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="ctms-modal modal-questionaire"
    width="800px"
    append-to-body
    @close="closeDialog"
  >
    <iframe
      ref="iframe"
      :src="questionaireUrl"
      frameBorder="no"
      border="0"
      marginWidth="0"
      marginHeight="0"
      style="width: 100%;height: 100%;background: #fff"
    />
  </el-dialog>
</template>
<script>
import store from '@/store'
export default {
  props: {
    dialogVisible: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    },
    questionnaireInfo: {
      type: Object,
      default: () => {
        return {
          questionnaire_code: '',
          resultId: '',
          hasNext: false,
          bodyParams: {},
          step_status: '10'
        }
      }
    },
    prefix: {
      type: String,
      default: ''
    }
  },
  computed: {
    questionaireUrl() {
      let questionaire_url = ''
      // 开发默认使用本地，可切换
      if (process.env.NODE_ENV === 'development') {
        // 本地问卷，需要确认其对应的后端
        questionaire_url = 'http://localhost:3000'
        // 测试问卷
        // questionaire_url = 'http://172.16.18.169'
      }
      // hasNext：0:没有下一步，1:有下一步
      let stepStatus = this.questionnaireInfo.step_status || ''
      let hasNext = this.questionnaireInfo.hasNext ? Number(this.questionnaireInfo.hasNext) : '0'
      return `${questionaire_url}/selfDeveloped/questionaire?code=${this.questionnaireInfo.questionnaire_code}&resultId=${this.questionnaireInfo.resultId}&isPC=1&hasNext=${hasNext}&stepStatus=${stepStatus}`
    }
  },
  mounted() {
    window.addEventListener('message', this.postMessage)
  },
  methods: {
    closeDialog() {
      window.removeEventListener('message', this.postMessage)
      this.$emit('closeDialog')
    },
    // 接收问卷传来的信息
    postMessage(e) {
      if (e.data === 'closeOne') {
        this.closeDialog()
      } else if (e.data === 'closeModal') {
        this.closeDialog()
        if (this.questionnaireInfo.hasNext) {
          this.$emit('nextStep')
        } else {
          this.$emit('refresh')
        }
      } else if (e.data === 'getToken') {
        setTimeout(() => {
          this.sendMsg()
        }, 500)
      } else if (e.data === 'nextStep') {
        this.closeDialog()
        this.$emit('nextStep')
      }
    },
    // 发消息给问卷
    sendMsg() {
      const iframe = this.$refs.iframe
      iframe.contentWindow.postMessage({
        accessToken: localStorage.getItem('accessToken'),
        prefixApi: store.state.currentProject || this.prefix,
        bodyParams: this.questionnaireInfo.bodyParams
      }, '*');
    }
  }
}
</script>
<style lang="scss">
.ctms-modal.modal-questionaire {
  .modal-main {
    width: 100%;
    height: 100%;
  }
  .el-dialog__body {
    height: 92vh;
    padding: 0;
  }
}
</style>