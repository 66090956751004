import Home from '@/views/CTAC/Home.vue'
import Index from '@/views/CTAC/Index.vue'
import Detail from '@/views/CTAC/Detail.vue'
import User from '@/views/CTAC/User.vue'
import Log from '@/views/CTAC/Log.vue'

const CTPJ00000001Routes = [
  {
    path: '/CTPJ00000001',
    component: {render: (h) => h('router-view')},
    children: [
      {
        path: 'home',
        component: Home,
        meta: { requireLogin: true }
      },
      {
        path: 'index',
        component: Index,
        meta: { requireLogin: true }
      },
      {
        path: 'detail',
        component: Detail,
        meta: { requireLogin: true }
      },
      {
        path: 'user',
        component: User,
        meta: { requireLogin: true }
      },
      {
        path: 'log',
        component: Log,
        meta: { requireLogin: true }
      }
    ]
  }
]

export default CTPJ00000001Routes
