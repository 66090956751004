<template>
  <van-button
    class="count-down-btn"
    :disabled="btnDisabled"
    @click="getCaptcha"
  >
    {{ !btnDisabled ? '获取验证码' : `${time}s后重发` }}
  </van-button>
</template>

<script>
import { apiSendSMSCode } from '@/apis/ECTOP/api';
import { Toast } from 'vant'
export default {
  name: 'CountDownBtn',
  props: {
    phone_number: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      time: 59,
      btnDisabled: false,
      timer: null
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getCaptcha() {
      const phone_number = this.phone_number;
      if (!phone_number || !/(^$|^1\d{10}$)/.test(phone_number)) {
        // 使用你的 Toast 组件来显示错误消息
        this.showToast('请输入有效的手机号', 'fail');
        return;
      }
      const params = { phone_number };
      this.btnDisabled = true;
      apiSendSMSCode(params).then(res => {
        if (res.code === 200) {
          this.showToast('发送成功', 'success');
          this.startTimer();
        } else {
          this.showToast(res.message, 'fail');
          this.btnDisabled = false;
        }
      })
      .catch(err => {
        console.log(err, 'err');
        this.btnDisabled = false;
      })
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.time > 1) {
          this.time -= 1;
        } else {
          this.resetTimer();
        }
      }, 1000);
    },
    resetTimer() {
      clearInterval(this.timer);
      this.time = 59;
      this.btnDisabled = false;
    },
    showToast(msg, type) {
      Toast({
        message: msg, // 提示内容
        duration: 2000, // 显示时间
        type: type // 自定义的消息类型
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.count-down-btn {
  background: none;
  color: #fff;
  height: 20px;
  border: none;
  padding: 0;
}

</style>