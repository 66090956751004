export function buildMap(originList, code) {
  const map = {}
  originList.map(option => {
    if (map[option[code]]) {
      map[option[code]].push(option)
    } else {
      map[option[code]] = [option]
    }
  })
  
  return map
}