<template>
  <div class="sys-menus-container">
    <div class="logo-container">
      <img
        class="menu-logo"
        src="@/assets/image/boruizhe.svg"
        @click="onGotoHome"
      />
    </div>
    <div class="patient-add-container">
      <el-button v-if="!user.disableNSCLCOperation" type="primary" v-on:click="onAddPatient">
        <div><img src="@/assets/image/add-new.svg" /></div>
        新建受试者信息
      </el-button>
    </div>
    <el-menu :default-active="pageIndex" class="el-menu-vertical">
      <el-menu-item
        v-for="menu in showMenus"
        :key="menu.index"
        :class="'menu-item-' + menu.level"
        :index="menu.index"
        @click="switchPage(menu.url, menu.index)"
      >
        <img v-if="menu.index == 'patientIndex'" src="@/assets/image/menu-patients.svg" />
        <img v-else-if="menu.index == 'userIndex'" src="@/assets/image/menu-user.svg" />
        <img v-else-if="menu.index == 'logIndex'" src="@/assets/image/menu-log.svg" />
        <img v-else-if="menu.index == 'CTPJ00000050_disenroll'" src="@/assets/image/menu-disenroll.svg" style="padding: 2px; transform: scale(0.9)" />
        <span slot="title"> {{ menu.label }} <span v-if="menu.count">（{{ menu.count }}）</span> </span>
      </el-menu-item>
    </el-menu>
    <component
      :is="currentAddDialog"
      v-if="modalAdd.isVisible"
      :dialogVisible="modalAdd.isVisible"
      :actionType="modalAdd.actionType"
      @closeDialog="modalAdd.isVisible=false"
      @refresh="handleRefresh"
    />
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import router from "../router";
import { getBaseUrl, getUrlParams } from "../helpers/urlHelper.js";
import store from "../store";
import { mapState } from 'vuex'
import DialogPatientAddCTAC from './dialog/DialogPatientAdd'
import DialogPatientAddNSCLC from '@/views/NSCLC/Dialog/DialogPatientAdd'
import DialogPatientAddECTOP from '@/views/ECTOP/Dialog/DialogPatientAdd'
import { getMenuInfo } from '@/utils/menuInfo'

export default {
  name: "SysMenu",
  components: {
    'CTPJ00000001-patient-add': DialogPatientAddCTAC,
    'CTPJ00000050-patient-add': DialogPatientAddNSCLC,
    'CTPJ00000051-patient-add': DialogPatientAddECTOP
  },
  props: {},
  data() {
    return {
      modalAdd: {
        isVisible: false,
        actionType: "1"
      }
    };
  },
  computed: {
    ...mapState(['currentProject', 'accessableMenus', 'patientsCount', 'allProjects', 'user']),
    visibleMenus: {
      get() {
        let info = {
          patientManage: false,
          userManage: false,
          logManage: false,
        }
        if (this.accessableMenus.length > 0 && this.currentProject) {
          let menu = ['/index', '/user', '/log']
          menu.forEach(ii => {
            let find = this.accessableMenus.filter(yy => yy.url == ii)
            if (find.length > 0) {
              if (ii.includes('/index')) {
                info['patientManage'] = true
              } else if (ii.includes('/user')) {
                info['userManage'] = true
              } else if (ii.includes('/log')) {
                info['logManage'] = true
              }
            }
          })
        }
        return info
      }
    },
    showMenus: {
      get() {
        if (this.currentProject) {
          const info = getMenuInfo(this.currentProject)
          if (this.currentProject == 'CTPJ00000001') {
            info.map(ii => {
              if (ii.business_group_code) {
                ii.count = ' '
                let filter = this.patientsCount.filter(yy => yy.business_group_code == ii.business_group_code)
                if (filter.length > 0) {
                  ii.count = filter[0].count + ''
                }
              }
            })
          } else {
            info.map(ii => {
              if (ii.group_type) {
                ii.count = ' '
                let filter = this.patientsCount.filter(yy => yy.group_type == ii.group_type)
                if (filter.length > 0) {
                  ii.count = filter[0].count + ''
                } else {
                  ii.count = '0'
                }
              }
            })
          }
          let temp = []
          if (this.visibleMenus['patientManage']) {
            temp.push('^patientIndex')
            // 二级、三级界面
            if (this.currentProject == 'CTPJ00000001') {
              temp.push('^ctac_group_')
            } else {
              temp.push('^' + this.currentProject + '_')
            }
          }
          if (this.visibleMenus['userManage']) {
            temp.push('^userIndex')
          }
          if (this.visibleMenus['logManage']) {
            temp.push('^logIndex')
          }
          if (temp.length > 0) {
            const reg = new RegExp(temp.join('|'))
            return info.filter(ii => reg.test(ii.index))
          } else {
            return []
          }
        } else {
          return []
        }
      }
    },
    pageIndex: {
      get() {
        let url = this.$route.path
        if (url) {
          if (url == '/CTPJ00000001/detail') {
            return getUrlParams("page")
          } else {
            const find = this.showMenus.filter(ii => ii.url == url)
            if (find.length > 0) {
              return find[0].index
            } else {
              return ''
            }
          }
        } else {
          return ''
        }
      }
    },
    currentAddDialog: {
      get() {
        return this.currentProject + '-patient-add'
      }
    }
  },
  created() {
    // 获取当前登录人菜单
    store.dispatch("getAccessableMenus")
  },
  methods: {
    switchPage(url, targetName) {
      let query = {}
      if (targetName.includes('ctac_group_')) {
        query['page'] = targetName
      }
      query['timestamp'] = Date.now()
      router.push({
        path: url,
        query
      })
    },
    onGotoHome() {
      if (this.allProjects.length == 1) {
        const type = this.allProjects[0].project_type
        if (type == 'CTPJ00000001') {
          router.push("/CTPJ00000001/home")
        } else {
          router.push(`/${type}/index`)
        }
      } else {
        // 有多个项目的选择权限
        router.push("/home")
      }
    },
    onAddPatient() {
      this.modalAdd.isVisible = true
    },
    handleRefresh() {
      window.location.reload()
    }
  },
  mounted() {
    // 菜单显示
    this.$store.dispatch("getPatientCount")
  }
};
</script>

<style lang="scss">
.logo-container {
  width: 120px;
  height: 60px;
  text-align: center;
  margin: 20px auto;
}
.patient-add-container {
  padding-top: 5px;
  padding-bottom: 25px;
  text-align: center;
  > button.el-button {
    border-radius: 80px;
    background-image: linear-gradient(90deg, #005bfe 0%, #0099fb 100%);
    box-shadow: 0px 0px 10px 0px #3652bb;
    border: 1.5px solid #ffffff;
    padding: 2px 15px 2px 2px;
    height: 36px;
    > span {
      height: 100%;
      > div {
        height: 100%;
        background: #fff;
        border-radius: 50%;
        margin-right: 7px;
        display: flex;
        > img {
          margin-right: 0;
          transform: scale(0.6);
        }
      }
    }
  }
}
.sys-menus-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 200px;
  border-right: 1px solid #f2f2f2;
  background-color: #0079fe;
  color: #fff;
}

.sys-menus-container .menu-logo {
  // height: 30px;
  // margin: 14px 20px;
  width: 100px;
  height: 60px;
}

.sys-menus-container .menu-logo:hover {
  cursor: pointer;
}

.sys-menus-container .el-menu-vertical {
  border-right-width: 0px;
  background: #0079fe;
}

.sys-menus-container .el-menu-item {
  height: 35px;
  line-height: 35px;
  padding: 0px !important;
  border-left: 3px solid transparent;
}

.sys-menus-container .menu-item-1 {
  padding-left: 16px !important;
  padding-right: 0px !important;
  font-size: 12px;
  color: #fff;
}

.sys-menus-container .menu-item-1:last-child {
  /* border-bottom: 1px solid #f2f2f2; */
}

.sys-menus-container .menu-item-2 {
  /* margin-left: 20px;
  margin-right: 20px; */
  padding-left: 36px !important;
  padding-right: 0px !important;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 23px;
  }
  &::after {
    content: '';
    display: block;
    flex: 1 1;
    height: 1px;
    overflow: hidden;
    background-size: 100% 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), transparent 100%);
  }
}

.sys-menus-container .menu-item-3 {

  padding-left: 36px !important;
  padding-right: 0px !important;
  font-size: 12px;
  color: #fff;
}

.sys-menus-container .el-menu-item.is-active, .sys-menus-container .el-menu-item:hover {
  color: #fff;
  background: #3652bb;
}
.sys-menus-container .el-menu-item.is-active {
  border-left: 3px solid #00edff;
  box-sizing: border-box;
}
</style>
