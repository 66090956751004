<template>
  <user-list></user-list>
</template>

<script>
import UserList from "@/components/UserList.vue";

export default {
  name: "LoginView",
  components: {
    UserList,
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style>
</style>