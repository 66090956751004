import { render, staticRenderFns } from "./Disenroll.vue?vue&type=template&id=a9ecadd2"
import script from "./Disenroll.vue?vue&type=script&scoped=true&lang=js"
export * from "./Disenroll.vue?vue&type=script&scoped=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_npm_i_ctms_front/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports