<template>
  <div class="option-out-div">
    <div v-for="item in questionnaireMenu"
      :key="item.prop"
      class="option-content"
      @click="openQuestionaire(row.questionaireMap[item.prop])"
      :class="row.questionaireMap[item.prop] && row.questionaireMap[item.prop][0].questionnaire_status === 30 ?
        'option-inside option-inside-true' : 'option-inside option-inside-false'"
      >
      <div
        v-if="row.questionaireMap[item.prop]?.length > 0"
      >
        <img v-if="row.questionaireMap[item.prop][0].questionnaire_status === 30" class="icon-submit" src="@/assets/image/ok.svg" />
        <img v-else class="icon-submit" src="@/assets/image/gene-none.svg" />
        {{ item.label }}
      </div>
      <div v-else>
        <img class="icon-submit" src="@/assets/image/gene-none.svg" />
        {{ item.label }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    },
    questionnaireMenu: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data() {
    return {
    }
  },
  methods: {
    openQuestionaire(item) {
      if (item && item.length > 0) {
        this.$emit('openQuestionaire', item[0])
      } else {
        this.$message.error('问卷信息异常！')
      }
    }
  }
}
</script>