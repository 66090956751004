<template>
  <el-dialog
    title="提醒"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="genetrials-common-dialog patient-add-error-dialog step_error"
    width="637px"
    append-to-body
    :before-close="handleCancel"
  >
    <div class="modal-main">
      <div v-if="messageInfo.type === 'warning'">
        <img src="@/assets/image/warning_dialog.svg" />
        <div v-html="messageInfo.msg"></div>
      </div>
      <div v-else-if="messageInfo.type === 'addSuccess'" class="addSuccess">
        <!-- 新建受试者成功 -->
        <div>
          <img src="@/assets/image/success_dialog.svg" width="18" height="18" />
          新建受试者成功！
        </div>
        <div v-html="messageInfo.msg"></div>
      </div>
      <div v-else-if="messageInfo.type === 'disenroll'">
        <img src="@/assets/image/warning_dialog.svg" />
        <div style="line-height: 24px">
          您添加的受试者信息不符合入组条件，请确认输入是否有误。<br />
          如输入无误，请点击将该受试者<el-button type="primary" plain @click="handleAddDisenroll" style="margin: 0 5px" icon="el-icon-plus" :loading="actionLoading">添加至未入组</el-button>中。
        </div>
      </div>
      <div v-else-if="messageInfo.type === 'preoperative_blood_type'">
        <img src="@/assets/image/warning_dialog.svg" />
        <div style="line-height: 24px">
          您填写的信息中该受试者<span style="font-weight: 650;font-size: 16px">尚未采集术前血，请确认。</span>
        </div>
      </div>
      <div v-else>
        <img src="@/assets/image/success_dialog.svg" />
        <div v-html="messageInfo.msg"></div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="showCancel" @click="handleCancel">
        {{ messageInfo.type === 'preoperative_blood_type' ? '返回修改' : '取消' }}
      </el-button>
      <el-button v-if="messageInfo.type === 'disenroll'" type="primary" @click="handleCancel">关闭</el-button>
      <el-button v-else type="primary" @click="handleConfirm">确认</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'DialogMessage',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    messageInfo: {
      type: Object,
      default: () => {
        return {
          type: 'warning',
          msg: ''
        }
      }
    },
    isShowCancel: {
      type: Boolean,
      default: false
    },
    actionLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showCancel() {
      if (this.messageInfo.type === 'preoperative_blood_type') {
        return true
      } else {
        return this.isShowCancel
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleConfirm() {
      if (this.messageInfo.type === 'preoperative_blood_type') {
        this.$emit('action', 'preoperative_blood_type')
      } else {
        this.$emit('confirm')
      }
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleAddDisenroll() {
      this.$emit('action', 'disenroll')
    }
  }
}
</script>
<style lang="scss">
.genetrials-common-dialog.patient-add-error-dialog {
  .modal-main {
    padding-bottom: 15px;
    > div {
      background-color: #f8f9fb;
      border-radius: 2px;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      > img {
        margin-right: 13px;
      }
    }
    > div.addSuccess {
      flex-direction: column;
      font-size: 12px;
      padding: 0;
      background: #fff;
      > div {
        width: 100%;
        &:first-child {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 46px;
          background-color: #f8f9fb;
          margin-bottom: 20px;
          > img {
            margin-right: 13px;
          }
        }
        &:nth-child(2) {
          .common-label {
            border-radius: 2px;
            border: 1px solid #c9cdd4;
            background-color: #f8f9fb;
            font-size: 16px;
            font-weight: 500;
            color: #333;
            line-height: 33px;
            margin-top: 11px;
            margin-bottom: 15px;
            padding-left: 10px
          }
          > div.label-patient-danger-level {
            @extend .common-label;
          }
          > div.label-patient-proj-level {
            @extend .common-label;
          }
          > div.label-patient-process {
            @extend .common-label;
          }
          > div:last-child{
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
