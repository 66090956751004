<template>
  <div class="option-out-div chemotherapy-way nsclc">
    <div class="option-inside option-inside-false"
      style="line-height: 1"
      @click="handleOpenGene">
      {{ selectDriveGene }}</div>
    <div
      v-for="(item, index) in recordList"
      :key="index"
      :class="item.step_status === '30' ?
        'option-inside option-inside-true' : 'option-inside option-inside-false'"
      @click="openQuestionaire(item, index)"
      >
      <i v-if="item.step_status === '30'" class="el-icon-success icon-submit" />
      <span v-else-if="item.step_status === '20'" class="circle-flag green"></span>
      <span v-else class="circle-flag"></span>
      初次治疗
    </div>
  </div>
</template>
<script>
import driverGene from '../Dialog/customForm/config/driverGene'
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    },
    stepList: {
      default: () => [],
      type: Array
    },
    type: {
      default: '',
      type: String
    },
    field: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      driverGeneOptions: driverGene.config[0].options
    }
  },
  computed: {
    selectDriveGene() {
      const target = this.driverGeneOptions.find(item => item.label === this.row.driver_gene)
      if (target) {
        return target.showValue || target.value
      }
      return '请选择'
    },
    recordList() {
      const stepItem = this.stepList.filter(item => {
        return ['CTST00020060'].indexOf(item.step_code) !== -1
      })
      return stepItem
    }
  },
  methods: {
    openQuestionaire(item, index) {
      const targetItem = this.recordList[0].task_list.find(i => i.task_code === 'CTTK00020250')
      this.$emit('openMRD', this.row, item.step_code, targetItem)
    },
    handleOpenGene() {
      const driveGeneItem = this.recordList[0].task_list.find(i => i.task_code === 'CTTK00020240')
      this.$emit('openMRD', this.row, this.recordList[0].step_code, driveGeneItem)
    }
  }
}
</script>
<style lang="scss" scoped>
.chemotherapy-way {
  max-width: 100%;
  flex: 1;
  div {
    flex: 1;
  }
  .circle-flag {
    align-items: center;
    border: 1px solid #333;
    border-radius: 50%;
    display: flex;
    height: 8px;
    margin-right: 3px;
    justify-content: center;
    width: 8px;
    &.green {
      border-color: #50b240;
    }
  }
  .icon-submit {
    margin-right: 3px;
  }
  .not-allow {
    cursor: not-allowed;
    color: #d4d4d4;
    border-color: #d4d4d4;
    .circle-flag {
      border: 1px solid #d4d4d4;
    }
  }
  .add-btn {
    border: 1px solid #d4d4d4;
  }
}
.chemotherapy-way .el-select {
  border-right: 0px;
  height: 24px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  flex: 0 0 130px;
  width: 130px;
  padding: 0;
  
  .el-input {
    padding: 0;
    border-radius: 0;
    width: 100%;
  }
  .el-input__inner {
    height: 24px;
    font-size: 12px;
    padding: 0 6px;
    border: none;
    background: none;
    line-height: 24px;
    &::placeholder {
      color: red;
    }
  }
  .el-input__icon {
    line-height: 24px;
  }
  .el-input__suffix {
    right: 0;
  }
  &.option-inside-true .el-input__icon {
    color: #50b240;
  }
}
.stop-check {
  background: #34CC99;
  opacity: 0.7;
  color: #fff;
  position: absolute;
  right: 35px;
  top: -2px;
  height: 14px;
  width: 54px;
  line-height: 14px;
}
.negative-blank {
  flex: 1;
  background: #f1f1f1;
  color: #adadad;
  text-align: center;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
}
</style>
<style lang="scss">
.chemotherapy-way .el-select.option-inside-false {
  .el-input__inner {
    &::placeholder {
      color: #555;
    }
  }
  .el-input.is-disabled {
    .el-input__inner::placeholder {
      color: #d4d4d4;
    }
  }
}
</style>