<template>
  <div
    id="gzContainer"
    class="home-index-view-container"
    element-loading-spinner="el-icon-loading"
  >
    <div class="page-subTitle">肺癌研究</div>

    <div class="cards-container">
      <div class="card-container ctac">
        <div class="block-info">
          <h1>新建受试者</h1>
          <div class="description">添加一个新的受试者并进行随机入组</div>
        </div>
        <div class="block-info">
          <h1>管理受试者</h1>
          <div class="description">查看受试者分组及研究进度，录入受试者数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "nsclcHome",
  components: {
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="scss" scoped>
.home-index-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  background: #f8f9fb;
  overflow: auto;
  .page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    .spe {
      width: 1px;
      height: 48px;
      background: #c9cdd4;
      margin: 0 18px 0 10px;
    }
    .name {
      font-size: 36px;
      color: #333;
      font-weight: 650;
    }
    margin-bottom: 24px;
    margin-top: 70px;
  }
  .page-subTitle {
    font-size: 56px;
    color: #333;
    text-align: center;
    font-weight: 650;
    margin-bottom: 40px;
  }
}

.home-index-view-container .card-container {
  display: flex;
  justify-content: center;
  .block-info:first-child {
    background-image: url(@/assets/image/patient-new.png);
    margin-right: 60px;
  }
  .block-info:nth-child(2) {
    background-image: url(@/assets/image/patient-admin.png);
  }
  .block-info {
    background-size: 100% 100%;
    width: 400px;
    height: 288px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 155px;
    cursor: pointer;
    > .description {
      font-size: 14px;
      color: #666;
      text-align: center;
    }
    > h1 {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
}
</style>