import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { VuePlugin } from "vuera";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

Vue.use(VuePlugin);

import Vant from 'vant';
import 'vant/lib/index.css'; // 引入 Vant 的样式文件

Vue.use(Vant)

dayjs.locale('zh-cn');
Vue.config.productionTip = false


if (process.env.NODE_ENV === "development") {
  // axios.defaults.baseURL = 'http://127.0.0.1:7158/api'
  axios.defaults.baseURL = 'http://172.16.18.169/api'
} else {
  // 测试、生产环境，采用nginx
  axios.defaults.baseURL = '/api'
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
