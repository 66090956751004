import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import QuestionairePreview from '@/views/QuestionairePreview.vue'
import RegisterPage from '@/views/registerPage/Index.vue'
import store from '@/store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { requireLogin: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/questionairePreview',
    name: 'questionairePreview',
    component: QuestionairePreview,
    meta: { requireLogin: true }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage,
    meta: { requireLogin: true }
  },
]

const moduleFiles = require.context('./modules', true, /\.js$/)

moduleFiles.keys().forEach(modulePath => {
  const moduleRoutes = moduleFiles(modulePath).default || moduleFiles(modulePath)
  routes.push(...moduleRoutes)
})

const router = new VueRouter({
  mode: "history",
  routes: routes
})

router.beforeEach((to, from, next) => {
  const { accessToken, accessExpire } = store.state
  const { requireLogin } = to.meta

  if(accessToken
    && new Date(Date.parse(accessExpire)) > new Date(Date.now())) {
      next()
    }
  else{
    if(requireLogin){
      console.log('router login')
      next('login')
    }
    else{
      console.log('router dont need login')
      next()
    }
  }
})

export default router
