import Vue from 'vue'
import Vuex from 'vuex'
import axios, { AxiosRequestConfig } from 'axios'
import { notifyError } from '@/helpers/message'

Vue.use(Vuex)

const asyncAndCommit = async (prefix, url, mutationName, commit, config = { method: 'get' }, extraData) => {
  const { data } = await axios(prefix + url, config)
  .catch(e => {
    console.log('error', e)
    // notifyError(e)
    throw e
  })
  
  if (extraData) {
    commit(mutationName, { data, extraData })
  } else {
    commit(mutationName, data)
  }

  return data
}
const handlePrefix = (prefix) => {
  if (prefix) {
    if (prefix == 'CTPJ00000001') {
      // ctac => CTPJ00000001
      return ''
    } else {
      return '/' + prefix
    }
  } else {
    return ''
  }
}
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default new Vuex.Store({
  state: {
    user: {
      userId: '',
      userName: '',
      userRoles: [],
      disableNSCLCOperation: false // 肺癌项目研究人员很多按钮无法操作
    },
    accessToken: localStorage.getItem('accessToken') || '',
    accessExpire: localStorage.getItem('accessExpire') || '',
    accessableMenus: [],
    patients: {
      data: [],
      total: 0,
    },
    operationLogs: {
      data: [],
      total: 0,
    },
    patientsCount: [],
    allUsers: [],
    institutes: [],
    allProjects: [],
    projectAdmins: [],
    projectUsers: [],
    currentProject: localStorage.getItem('currentProject') || '',
    currentPrefix: handlePrefix(localStorage.getItem('currentProject') || '')
  },
  getters: {
  },
  mutations: {
    sendSmsCode(state, rawData) {
      if (rawData.code != "200") {
        return
      }
    },
    login(state, rawData) {
      if (rawData.code != "200") {
        return
      }
      console.log('login', rawData)
      const { accessToken, expire } = rawData.entity
      state.accessToken = accessToken
      state.accessExpire = expire
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('accessExpire', expire)
    },
    fetchCurrUser(state, rawData) {
      if (rawData.code != "200") {
        return
      }
      state.user.userId = rawData.entity.user_id
      state.user.userName = rawData.entity.display_name
      state.user.userRoles = rawData.entity.roles
      // 肺癌项目研究人员
      const isNSCLCResearcher = rawData.entity.roles.find(role => {
        return role.project_code === 'CTPJ00000050' && role.role_code === 'researcher'
      })
      state.user.disableNSCLCOperation = !!isNSCLCResearcher
      
      localStorage.setItem('userName', rawData.entity.display_name)
    },
    getAccessableMenus(state, rawData) {
      if (rawData.code == 200) {
        state.accessableMenus = rawData.entities
      } else {
        state.accessableMenus = []
      }
    },

    getAllUsers(state, rawData) {
      if (rawData.code != "200") {
        return
      }
      state.allUsers = rawData.entities
    },
    getProjectAdmins(state, rawData) {
      if (rawData.code != "200") {
        return
      }
      state.projectAdmins = rawData.entities
    },
    getProjectUsers(state, rawData) {
      if (rawData.code != "200") {
        return
      }
      state.projectUsers = rawData.entities
    },
    addUserToCtacProject(state, rawData) {
      if (rawData.code != "200") {
        return
      }
    },
    removeUserFromCtacProject(state, rawData) {
      if (rawData.code != "200") {
        return
      }
    },

    generateQaUrl(state, rawData) {
      if (rawData.code != "200") {
        return
      }
    },

    getinstitutes(state, rawData) {
      if (rawData.code != "200") {
        return
      }
      state.institutes = rawData.entities
    },
    getPagedPatients(state, rawData) {
      if (rawData.code != "200") {
        return
      }
      state.patients.data = rawData.entities
      state.patients.total = rawData.total
    },
    addNewPatient(state, rawData) {
      if (rawData.code != "200") {
        return
      }
    },
    modifyPatient(state, rawData) {
      if (rawData.code != "200") {
        return
      }
    },
    modifyPatientGroup(state, rawData) {
      if (rawData.code != "200") {
        return
      }
    },
    deletePatient(state, rawData) {
      if (rawData.code != "200") {
        return
      }
    },
    getPatientCount(state, rawData) {
      if (rawData.code != "200") {
        return
      }
      state.patientsCount = rawData.entities
      console.log('patient count', state.patientsCount)
    },
    getPagedLogs(state, rawData) {
      if (rawData.code != "200") {
        return
      }
      state.operationLogs.data = rawData.entities
      state.operationLogs.total = rawData.total
    },
    getAllProjects(state, rawData) {
      if (rawData.code == 200) {
        state.allProjects = rawData.entities
      } else {
        state.allProjects = []
      }
    },
    changeCurrentProject(state, project_code) {
      localStorage.setItem('currentProject', project_code)
      state.currentProject = project_code
      state.currentPrefix = handlePrefix(project_code)
    },
    resetAllProjects(state) {
      state.allProjects = []
    }
  },
  actions: {
    // 登录
    sendSmsCode({ commit }, payload) {
      console.log('send', payload)
      return asyncAndCommit('', '/login/sms-code', 'sendSmsCode', commit, { method: 'post', data: payload })
    },
    login({ commit }, payload) {
      return asyncAndCommit('', '/login/login', 'login', commit, { method: 'post', data: payload })
    },
    logout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('accessExpire')
    },
    fetchCurrUser({ commit }) {
      return asyncAndCommit('', '/login/user-roles', 'fetchCurrUser', commit)
    },
    getAccessableMenus({ commit, state }) {
      asyncAndCommit('', `/login/menus?project_code=${state.currentProject}`, 'getAccessableMenus', commit)
    },
    getAllProjects({ commit }) {
      asyncAndCommit('', '/login/projects', 'getAllProjects', commit)
    },
    // 用户管理
    getAllUsers({ commit }) {
      return asyncAndCommit('', '/user/list', 'getAllUsers', commit)
    },
    getProjectAdmins({ commit, state }) {
      let currentPrefix = ''
      if (state.currentProject !== 'CTPJ00000001') {
        currentPrefix = state.currentPrefix
      }
      return asyncAndCommit(currentPrefix, '/user/list', 'getProjectAdmins', commit)
    },
    getProjectUsers({ commit, state }) {
      let currentPrefix = ''
      if (state.currentProject !== 'CTPJ00000001') {
        currentPrefix = state.currentPrefix
      }
      return asyncAndCommit(currentPrefix, '/user/list', 'getProjectUsers', commit)
    },
    addUserToCtacProject({ commit }, payload) {
      return asyncAndCommit('', '/user/add-user-to-ctac-project', 'addUserToCtacProject', commit, { method: 'post', data: payload })
    },
    removeUserFromCtacProject({ commit }, payload) {
      return asyncAndCommit('', '/user/remove-user-from-ctac-project', 'removeUserFromCtacProject', commit, { method: 'post', data: payload })
    },
    // 机构
    getinstitutes({ commit, state }) {
      return asyncAndCommit(state.currentPrefix, '/institute?is_available=1', 'getinstitutes', commit)
    },
    // 患者
    getPagedPatients({ commit, state }, payload) {
      return asyncAndCommit(state.currentPrefix, `/patient/paged?${payload.params}`, 'getPagedPatients', commit, { method: 'post', data: payload.body })
    },
    addNewPatient({ commit, state }, payload) {
      return asyncAndCommit(state.currentPrefix, '/patient/add', 'addNewPatient', commit, { method: 'post', data: payload })
    },
    modifyPatient({ commit, state }, payload) {
      return asyncAndCommit(state.currentPrefix, '/patient/modify', 'modifyPatient', commit, { method: 'post', data: payload })
    },
    modifyPatientGroup({ commit, state }, payload) {
      return asyncAndCommit(state.currentPrefix, '/patient/group/modify', 'modifyPatientGroup', commit, { method: 'post', data: payload })
    },
    deletePatient({ commit, state }, payload) {
      return asyncAndCommit(state.currentPrefix, '/patient/delete', 'deletePatient', commit, { method: 'post', data: payload })
    },
    getPatientCount({ commit, state }) {
      return asyncAndCommit(state.currentPrefix, '/patient/count', 'getPatientCount', commit)
    },
    // sys
    getPagedLogs({ commit, state }, payload) {
      return asyncAndCommit(state.currentPrefix, `/log/paged?${payload.params}`, 'getPagedLogs', commit, { method: 'post', data: payload.body })
    }
  },
  modules: {
  }
})
