<template>
  <div class="preview-file-list">
    <div v-for="(item, index) in previewFileList" :key="index" class="preview-item">
      <i v-if="actionType !== '2'" class="el-icon-close" @click="handleRemove(index)"></i>
      <el-image v-if="item.isImage"
        fit="fit"
        :src="item.url"
        style="width: 148px; height: 148px"
        :preview-src-list="[item.url]"
      >
      </el-image>
      <a v-else :href="item.url" target="_blank">
        <img src="@/assets/image/file.png" height="148"/>
      </a>
    </div>
    <el-upload
      v-if="fileList.length < 5 && actionType !== '2'"
      class="upload-demo"
      ref="upload"
      action="#"
      list-type="picture-card"
      :on-change="handleChange"
      :file-list="fileList"
      :show-file-list="false"
      :auto-upload="false">
      <i slot="trigger" class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
const isImage = ['jpg', 'png', 'jpeg', 'gif', 'bmp']
export default {
  props: {
    initFileList: {
      type: Array,
      default: () => []
    },
    actionType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileList: [],
      previewFileList: [],
    }
  },
  watch: {
    initFileList(val) {
      if (val.length > 0) {
        val.map(file => {
          this.fileList.push({
            file_id: file.file_id,
            name: file.file_name,
            url: file.url,
            type: file.file_type
          })
        })
        this.previewFileList = this.fileList.reduce((prev, curr) => {
          prev.push({
            ...curr,
            isImage: isImage.includes(curr.type)
          })
          return prev
        }, [])
      } else {
        this.previewFileList = []
        this.fileList = []
      }
    }
  },
  methods: {
    validateFile(file) {
      const acceptedExtensions = /\.(pdf|doc|docx|bmp|jpeg|jpg|png|gif|tiff|pcx|raw)$/i;
      
      // 基于 file.type 进行初步验证
      const acceptedMimeTypes = /^(application\/pdf|application\/msword|application\/vnd.openxmlformats-officedocument.wordprocessingml.document|image\/bmp|image\/jpeg|image\/png|image\/gif|image\/tiff|image\/pcx|application\/octet-stream)$/i;

      // 初步检查 file.type，如果 file.type 为空或未识别，则基于文件后缀名进行补充验证
      const isAcceptedMimeType = acceptedMimeTypes.test(file.type);
      const hasAcceptedExtension = acceptedExtensions.test(file.name);
      
      if (!isAcceptedMimeType && !hasAcceptedExtension) {
        this.$message.error('仅支持 pdf, word, bmp, jpeg, jpg, png, gif, tiff, pcx, raw 格式的文件');
        return false;
      }
      if (this.fileList.length > 5) {
        this.isMaxFiles = true;
        this.$message.error('最多只能上传5个文件');
        return false;
      }
      return true;
    },
    handleChange(file, fileList) {
      const isValid = this.validateFile(file.raw);
      if (!isValid) {
        // 如果文件无效，则从 fileList 中移除该文件
        fileList.pop();
        this.fileList = fileList;
      } else {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          const isImage = file.raw.type.startsWith('image');
  
          // 创建 Blob 对象
          const blob = new Blob([e.target.result], { type: file.raw.type });
          // 生成 URL
          const url = URL.createObjectURL(blob);
  
          this.previewFileList.push({
            url: url,
            name: file.name,
            type: file.raw.type,
            isImage: isImage
          })
        }
        // 将文件读取为 ArrayBuffer 以适应多种文件类型
        reader.readAsArrayBuffer(file.raw)
        this.fileList = fileList;
        this.$emit('handleUpload', this.fileList)
      }
    },
    handleRemove(index) {
      this.fileList.splice(index, 1)
      this.previewFileList.splice(index, 1)
      this.$emit('handleUpload', this.fileList)
    }
  }
}
</script>
<style lang="scss" scoped>
.preview-file-list {
  display: flex;
  .preview-item {
    position: relative;
    margin-right: 10px;
  }
  .el-icon-close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    z-index: 11;
    cursor: pointer;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px
  }
}
</style>