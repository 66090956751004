import { requestPost } from "@/utils/request"
import store from '@/store'

// 获取不良事件
export function getIncidentList(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/incident/list', params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 上报不良事件
export function postIncidentAdd(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/incident/add', params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}