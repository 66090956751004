<template>
  <div class="ctms-home">
    <div>临床科研管理系统</div>
    <div>
      <div
        v-for="project in showProjects"
        :key="project.label"
        @click="handleClick(project.url, project.project_code)"
        class="card-main"
        :class="`${project.project_code}-icon`"
      >
        <div class="img-wrapper"><img :src="require('@/assets/home/' + project.project_code + '.svg')" /></div>
        <div class="desc">{{ project.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'HomeView',
  data() {
    return {
      projects: [
        {
          label: '肠癌MRD干预研究CTAC',
          project_code: 'CTPJ00000001',
          url: '/CTPJ00000001/home'
        },
        {
          label: '非小细胞肺癌MRD指导辅助治疗研究',
          project_code: 'CTPJ00000050',
          url: '/CTPJ00000050/index'
        },
        {
          label: 'ECTOP1022研究',
          project_code: 'CTPJ00000051',
          url: '/CTPJ00000051/index'
        },
      ],
      showProjects: []
    }
  },
  computed: {
    ...mapState(['allProjects'])
  },
  watch: {
    'allProjects': {
      handler(val) {
        if (val.length == 0) {
          this.showProjects = []
        } else {
          let temp = val.map(ii => {
            const find = this.projects.filter(jj => jj.project_code == ii.project_code)
            if (find.length > 0) {
              return {
                ...ii,
                ...find[0]
              }
            } else {
              return ii
            }
          })
          if (temp.length > 1) {
            this.showProjects = temp
          } else {
            this.handleChange(temp[0].project_code)
            this.$router.push(temp[0].url)
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['changeCurrentProject']),
    handleChange(project_code) {
      this.changeCurrentProject(project_code)
    },
    handleClick(url, project_code) {
      this.handleChange(project_code)
      this.$router.push(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.ctms-home {
  background: #f8f9fb;
  height: 91%;
  > div:first-child {
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    line-height: 150px;
  }
  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .card-main {
      margin-right: 20px;
    }
    .img-wrapper {
      width: 280px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      > img {
        width: 100%;
      }
    }
    div.desc {
      background: #fff;
      width: 280px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .CTPJ00000051-icon {
    border-radius: 10px;
    overflow: hidden;
    .img-wrapper {
      background: #C9F4E7;
      height: 131px;
    }
    img {
      height: 84px;
      margin-top: 25px;
    }
  }
}
</style>
