export default {
  type: 'demographic',
  config: [
  {
    key: 'gender',
    label: '性别',
    type: 2,
    span: 4,
    options: [
      {
        label: 1,
        value: '男'
      },
      {
        label: 2,
        value: '女'
      }
    ],
  },
  {
    key: 'date_of_birth',
    label: '出生时间',
    type: 3,
    initalDate: '1965'
  },
  {
    key: 'ethnic_group',
    label: '民族',
    type: 1
  },
  {
    key: 'height',
    label: '身高',
    type: 1,
    unit: '厘米',
    rules: {min: 50, max: 250, message: '请注意身高单位是厘米'},
    customValidator: (rule, value, callback) => {
      if (value >= 50 && value <= 250) {
        callback()
      } else {
        callback(new Error('请注意身高单位是厘米'))
      }
    }
  },{
    key: 'weight',
    label: '体重',
    type: 1,
    unit: '千克'
  },{
    key: 'blood_pressure',
    label: '血压',
    children: [{
      key: 'blood_pressure_high',
      label: '高压'
    },{
      key: 'blood_pressure_low',
      label: '低压'
    }],
    type: 4,
    unit: 'mmHg'
  },
  {
    key: 'heart_rhythm',
    label: '心率',
    type: 1,
    unit: '次/分',
    customValidator: (rule, value, callback) => {
      if (value > 0 && Number.isInteger(Number(value))) {
        callback()
      } else {
        callback(new Error('请输入有效数字'))
      }
    }
  }
]
}