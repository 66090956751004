<template>
  <div class="visit-date option-out-div">
    <div :class="row.sms_alert_status === 1 ? 'option-inside-true' : 'option-inside-false'" @click="openNoticeDialog(row)">
      <img v-if="row.sms_alert_status === 1" class="icon-submit clock" src="@/assets/image/clock-on.svg"/>
      <img v-else class="icon-submit clock" src="@/assets/image/clock-off.svg"/>
    </div>
    <div v-for="(item, index) in row.questionaireMap['CTPQ00000008']"
      :key="index"
      :class="handleClass(item.questionnaire_status_convert)"
      @click="openQuestionaire(row.questionaireMap['CTPQ00000008'][index])"
    >
      <el-popover
        trigger="hover"
        title=""
        popper-class="define-visit-popover-expired"
        class="define-visit-popover"
        placement="bottom"
        :disabled="item.questionnaire_status_convert != 90"
        :open-delay="300">
        <div class="define-content">
          <img src="@/assets/image/warning_dialog.svg" width="18px" />
          该受试者的术后访视时间已过，请及时记录术后访视数据
        </div>
        <template slot="reference">
          <div>
            <img v-if="item.questionnaire_status_convert === 30" class="icon-submit" src="@/assets/image/ok.svg" />
            <img v-else-if="item.questionnaire_status_convert === 10" class="icon-submit" src="@/assets/image/gene-none.svg" />
            <img v-else class="icon-submit" src="@/assets/image/gene-expired.svg" />
            {{ item.questionnaire_abbr_name }}
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  methods: {
    openQuestionaire(item) {
      item.questionnaire_name_convert = '术后' + item.questionnaire_abbr_name + '个月访视'
      this.$emit('openQuestionaire', item)
    },
    openNoticeDialog(row) {
      this.$emit('openNoticeDialog', row)
    },
    handleClass(status) {
      if (status == 30) {
        return 'option-inside-true'
      } else if (status == 90) {
        return 'option-inside-expired'
      } else {
        return 'option-inside-false'
      }
    }
  }
}
</script>
<style lang="scss">
.visit-date {
  display: inline-flex;
}
.define-visit-popover {
  height: 24px;
  line-height: 24px;
  .el-popover__reference-wrapper {
    display: inline-block;
    width: 42px;
  }
}
.define-visit-popover-expired {
  padding: 8px;
  font-size: 12px;
  &.el-popover[x-placement^=bottom] {
    margin-top: 8px;
  }
  > .define-content {
    display: flex;
    align-items: center;
    > img {
      margin-right: 2px;
    }
  }
}
</style>