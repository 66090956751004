<template>
  <div class="random-group-wrapper" v-loading="initLoading">
    <div v-if="prevTaskStatus === '10'" class="empty-wrapper">
      <img src="@/assets/image/no-image.png" width="120px"/>
      {{ isRandomResultPage ? '请提交入排问卷然后进行随机化，即可查看随机结果。' : '请先提交入排问卷后再进行随机化。'}}
    </div>
    <div v-else-if="isRandomResultPage && !finishRandomForm" class="empty-wrapper">
      <img src="@/assets/image/no-image.png" width="120px"/>
      请提交入排问卷然后进行随机化，即可查看随机结果。
    </div>
    <div v-else-if="!isCanJoin" class="empty-wrapper">
      <img src="@/assets/image/no-image.png" width="120px"/>
        入排问卷不符合入组标准，无法进行随机化分组。
    </div>
    <template v-else>
      <div v-if="isRandomResultPage" class="empty-wrapper">
        <i class="el-icon-circle-check"></i>
        <p class="result-tips">随机分组成功</p>
        <p class="result-tips bold">随机日期为：{{ join_date }}</p>
        <p class="result-tips">据分期和病理亚型进行分层随机，标准治疗组:MRD指导组比例为1:2</p>
        <p class="result-tips">该受试者被分配到</p>
        <p class="result-tips bigger">{{ group_type_name }}</p>
      </div>
      <template v-else>
        <el-form
          class="q-custom-form"
          label-position="top"
          size="mini"
          :model="questionaireForm"
          :disabled="disableEdit"
          ref="questionaireForm">
          <template v-for="(question, index) in questionnaireConfig">
            <div v-if="question.type === 2" :key="question.key" class="define-form-item">
              <span class="sortNo">{{ handleSortNo(index) }}</span>
              <el-form-item :label="question.label" :prop="question.key"
                :rules="[
                  { required: true, message: '请选择', trigger: 'change' },
                ]">
                <el-radio-group v-model="questionaireForm[question.key]" style="width: 100%">
                  <el-row style="display: flex; align-items: center">
                    <el-col :span="question.span" v-for="radio in question.options" :key="radio.label">
                      <el-radio :label="radio.label">{{ radio.value }}</el-radio>
                      <template v-if="radio.desc">
                        <el-form-item :prop="questionaireForm[question.key] === radio.label ? radio.desc : radio.desc + '1111'" style="display: inline-block">
                          <el-input
                            :disabled="!(questionaireForm[question.key] === radio.label)"
                            style="display: inline-block;width: 160px"
                            v-model="questionaireForm[radio.desc]"
                            :placeholder="radio.placeholder"
                            clearable
                          />
                        </el-form-item>
                      </template>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
        </el-form>
        <div class="random-tips">
          <p>根据分期和病理亚型进行分层随机，标准治疗组：MRD干预组比例为1:2</p>
          <p>请注意：为了保证分组的客观性，不被人为控制，只有在首次提交的时候才会进行随机分组，一旦已经进行了分组，则分组不能改变，编辑此页面提交不会重新分组。</p>
        </div>
        <div class="custom-form-footer" >
          <template v-if="!disableNSCLCOperation">
            <el-button v-if="disableEdit" class="custom-submit-btn" @click="changeEditStatus">编辑</el-button>
            <el-button v-else class="custom-submit-btn" type="primary" @click="handleConfirm" :loading="loading">提交</el-button>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import {
  apiModifyTask,
  apiGetTaskQuestionnaire,
  apiGetTaskDetail,
  apiGetGroupResult
} from '@/apis/NSCLC/modalQuestionaire'
import {
  getQuestionnareResult
} from '@/apis/questionnaire'
import store from '@/store'
import randomGroup from './config/randomGroup'
import dayjs from 'dayjs'

export default {
  props: {
    taskCode: {
      type: String,
      default: ''
    },
    patientId: {
      type: String,
      default: ''
    },
    taskStatus: {
      type: String,
      default: ''
    },
    tasks: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      questionaireForm: {},
      loading: false,
      disableEdit: false,
      isCanJoin: false, // 是否符合入组条件
      group_type_name: '',
      initLoading: false,
      join_date: ''
    }
  },
  computed: {
    questionnaireConfig() {
      return randomGroup && randomGroup.config
    },
    apiType() {
      return randomGroup && randomGroup.type
    },
    enrollForm() {
      return this.tasks.find(item => item.task_code === 'CTTK00020110')
    },
    prevTaskStatus() {
      // 入组问卷的状态
      const item = this.tasks.find(item => item.task_code === 'CTTK00020110')
      return item && item.task_status
    },
    isRandomResultPage() {
      // 随机化结果页面
      return this.taskCode === 'CTTK00020130'
    },
    disableNSCLCOperation() {
      return store.state.user.disableNSCLCOperation
    }
  },
  created() {
    if (this.taskCode && this.prevTaskStatus === '30') {
      this.checkCanJoinGroup()
    }
  },
  watch: {
    taskCode(val) {
      if (val) {
        if (this.prevTaskStatus === '30') {
          this.checkCanJoinGroup()
        }
      }
    },
  },
  methods: {
    async getDetail() {
      const params = {
        apiType: this.apiType,
        patient_id: this.patientId,
        task_code: this.taskCode
      }
      const res = await apiGetTaskDetail(params)
      if (res.code === 200) {
        Object.keys(res.entity).map(key => {
          this.$set(this.questionaireForm, key, res.entity[key] || '')
        })
      } else {
        this.$message.error(res.message)
      }
      this.$nextTick(() => {
        this.$refs.questionaireForm && this.$refs.questionaireForm.clearValidate()
      })
      if (this.taskStatus === '30') {
        this.disableEdit = true
      } else {
        this.disableEdit = false
      }
      this.initLoading = false
    },
    handleSortNo(index) {
      return index + 1 > 9 ? index + 1 : '0' + (index + 1)
    },
    handleConfirm() {
      this.$refs['questionaireForm'].validate(async valid => {
        if (valid) {
          await this.handleSubmit()
        } else {
          this.$message({
            type: 'error',
            message: '您有必填字段未填写。'
          })
        }
      })
    },
    async handleSubmit() {
      this.loading = true
      const params = {
        apiType: this.apiType,
        patient_id: this.patientId,
        task_code: this.taskCode,
        ...this.questionaireForm
      }
      const res = await apiModifyTask(params)
      this.loading = false
      if (res.code === 200) {
        this.$message.success('提交成功')
        this.disableEdit = true
        this.$emit('goNext')
      } else {
        this.$message.error(res.message)
      }
    },
    changeEditStatus() {
      this.disableEdit = false
    },
    async checkCanJoinGroup() {
      this.initLoading = true
      const params = {
        patient_id: this.patientId,
        task_code: this.enrollForm.task_code
      }
      const res = await apiGetTaskQuestionnaire(params)
      if (res.code === 200) {
        const result = await getQuestionnareResult(res.entity.result_id)
        this.isCanJoin = result.entities[0].questionnaire_info.point === 100
        if (this.isRandomResultPage) {
          const item = this.tasks.find(item => item.task_code === 'CTTK00020120')
          this.finishRandomForm = item.task_status === '30'
          this.getResult()
        } else {
          this.getDetail()
        }
      }
    },
    async getResult() {
      const params = {
        patient_id: this.patientId,
        task_code: this.taskCode
      }
      const res = await apiGetGroupResult(params)
      if (res.code === 200) {
        this.group_type_name = res.entity.group_type_name
        this.join_date = res.entity.join_datetime ? dayjs(res.entity.join_datetime).format('YYYY-MM-DD') : ''
      } else {
        this.$message.error(res.message)
      }
      this.initLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.random-group-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 93%;
  .random-tips {
    padding: 16px;
  }
  .el-icon-circle-check {
    font-size: 60px;
    color: #50b240;
    margin-bottom: 10px;
  }
  .result-tips {
    font-size: 14px;
    margin-top: 0;
    font-weight: 500;
    &.bold {
      color: #000;
    }
    &.bigger {
      font-size: 30px;
      color: #000;
    }
  }
}
.custom-submit-btn {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-60px);
  width: 120px;
}
.empty-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>