<template>
  <div id="app">
    <div class="app-container">
      <sys-menu v-if="isMenuSideVisible" />
      <div class="app-content-container" :style="{'margin-left': !isMenuSideVisible ? '0' : ''}">
        <sys-header v-if="isSysHeaderVisible" />
        <router-view />
        <p v-if="!isMb" :class="{'copyright':true, ' grey': !isLogin}">© 2023 苏ICP备2023026406号-2 苏州博瑞哲生物科技有限公司版权所有</p>
      </div>
    </div>
  </div>
</template>

<script>
import SysMenu from "@/components/SysMenu.vue";
import SysHeader from "@/components/SysHeader.vue";
const mbRouters = ['/CTPJ00000051/mb/login', '/CTPJ00000051/mb/index']
const noLayoutUrl = ['/login', '/home', '/register', '/questionairePreview', '/CTPJ00000001/home'] // 没有头部和侧边栏的地址
export default {
  name: "CtmsApp",
  components: {
    SysMenu,
    SysHeader,
  },
  computed: {
    isMenuSideVisible: {
      get() {
        let currUrl = this.$route.path
        if (noLayoutUrl.includes(currUrl) || mbRouters.includes(currUrl)) {
          return false
        }
        return true
      }
    },
    isSysHeaderVisible: {
      get() {
        let currUrl = this.$route.path
        if (currUrl === '/login' || mbRouters.includes(currUrl)) {
          return false
        }
        return true
      }
    },
    isLogin() {
      return this.$route.path === '/login'
    },
    isMb() {
      return mbRouters.includes(this.$route.path)
    }
  },
  mounted() {
    // 适配百分比调整 chrome浏览器
    document.getElementsByTagName('body')[0].style.zoom = 1
  },
};
</script>


<style lang="scss">
body {
  height: 100%;
  overflow: hidden;
}
.app-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.sys-header-bottom-line {
  height: 1px;
  width: 100%;
  border-top: solid 1px #f2f2f2;
}

.app-content-container {
  margin-left: 200px;
  height: 100%;
  background: #FFF;
}

/************************** 全局通用样式 暂时放这里 **************************/
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}


/************************** 颜色 **************************/

/* primary */
.el-button {
  border-radius: 2px;
  padding: 4px 10px;
  font-size: 12px;
  height: 24px;
  > span {
    display: inline-flex;
    align-items: center;
    // line-height: 14px;
    height: 100%;
    img {
      transform: scale(0.9);
      margin-right: 5px;
    }
  }
  
}
.el-button--default {
  padding: 4px 10px;
  height: 24px;
}
.el-button--primary {
  background: #0079FE;
}


/************************** 弹窗 **************************/
.el-dialog {
  border-radius: 8px;
  margin-top: 0 !important;
  margin-bottom: 0;
  margin: 0 auto;
}

/************************** 表单 **************************/
.el-form-item{
  margin-bottom: 4px;
  
}
.el-form-item--mini {
  .el-form-item__label, .el-form-item__content {
    line-height: 24px;
  }
  .el-range-editor--mini.el-input__inner {
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
  }
  .el-range-editor--mini .el-range__icon {
    line-height: 18px;
  }
}
.el-form-item__label {
  font-size: 12px;
  padding-right: 10px;
}

.el-textarea__inner {
  border-radius: 2px;
}
.el-input--mini {
  .el-input__inner {
    border-radius: 2px;
    height: 24px;
    line-height: 24px;
  }
  .el-input__icon {
    line-height: 24px;
  }
}

/************************** 表格 **************************/

/* 表头不加粗 */
.el-table .el-table__header .cell {
  font-weight: normal;
  color: #606266;
}
.el-table .el-table__cell {
  padding: 5px 0;
  font-size: 12px;
}
.el-table th.el-table__cell>.cell {
  white-space: pre;
}
.el-radio__label {
  font-size: 12px;
}
.el-input-number--mini {
  line-height: 22px;
}
.el-message {
  padding: 8px;
}
.ctms-modal {
  display: flex;
  align-items: center;
  .el-dialog__header {
    padding: 8px 30px;
    .el-dialog__headerbtn {
      top: 10px;
      right: 15px;
    }
    border-bottom: 1px solid #DCDFE6;
  }
  .el-dialog__title {
    font-size: 14px;
    line-height: 14px;
    color: #333;
    font-weight: 650;
    &::before {
      content: '.';
      display: inline-block;
      margin-right: 6px;
      width: 3px;
      height: 15px;
      background: #333;
    }
  }
  .el-dialog__body {
    background: #f0f2f7;
    padding: 15px;
    // max-height: 77vh;
    // overflow: auto;
  }
  .modal-main {
    background: #fff;
    border-radius: 8px;
    padding: 15px;
    font-size: 12px;
  }
  .el-dialog__footer {
    text-align: center;
  }
}
.genetrials-common-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-dialog__header {
    padding: 8px 20px;
    border-bottom: 1px solid #d4d4d4;
    > .el-dialog__headerbtn {
      right: 15px;
      top: 13px;
    }
  }
  .el-dialog__title {
    font-size: 12px;
    line-height: 14px;
    color: #333;
    font-weight: 650;
    &::before {
      content: '.';
      display: inline-block;
      margin-right: 6px;
      width: 3px;
      height: 15px;
      background: #333;
    }
  }
  .el-dialog__body {
    padding: 15px 15px 0;
    max-height: 83vh;
    overflow: auto;
  }
  .modal-main {
    background: #fff;
    border-radius: 8px;
    font-size: 12px;
  }
  .el-dialog__footer {
    border-top: 1px solid #d4d4d4;
    text-align: center;
    padding: 10px 20px;
  }
}
.genetrials-common-dropdown.el-popper[x-placement^=bottom] {
  margin-top: 0;
}
.el-select-dropdown__item {
  padding: 0 10px;
}
.el-notification {
  z-index: 20010 !important;
}
.copyright {
  font-size: 12px;
  color: #fff;
  width: 100%;
  text-align: center;
  padding-bottom: 24px;
  position: fixed;
  bottom: -20px;
  &.grey {
    color: #bbb;
    position: relative;
    margin: 0;
    bottom: 0;
  }
}
.el-pagination__editor.el-input .el-input__inner {
  height: 24px;
}
</style>

