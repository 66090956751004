import { requestGet, requestPost, requestPostBlob } from "@/utils/request";

// 获取所有项目
export function apiGetAllProjects() {
  return new Promise((resolve, reject) => {
    requestGet('/pc-user/all-projects').then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 获取所有研究中心
export function apiGetAllGroups() {
  return new Promise((resolve, reject) => {
    requestGet('/pc-user/all-groups?is_available=1').then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 获取所有角色
export function apiGetAllRoles() {
  return new Promise((resolve, reject) => {
    requestGet('/pc-user/all-roles').then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 获取所有用户
export function apiPostUserList(params) {
  return new Promise((resolve, reject) => {
    requestPost(`/pc-user/list?page=${params.page}&size=${params.size}`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 新增用户
export function apiPostAddUser(params) {
  return new Promise((resolve, reject) => {
    requestPost(`/pc-user/add`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 修改用户
export function apiPostModifyUser(params) {
  return new Promise((resolve, reject) => {
    requestPost(`/pc-user/modify`, params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}
// 查看用户
export function apiGetUserDetail(user_id) {
  return new Promise((resolve, reject) => {
    requestGet(`/pc-user/detail?user_id=${user_id}`).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}