<template>
  <div class="mb-index-container">
    <p class="mb-index-title">博瑞哲-ECTOP1022研究</p>
    <van-search
      v-model="filter_condition"
      placeholder="输入受试者编号或者其他信息"
      show-action
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-list
      class="user-list"
      v-model="loading"
      :finished="finished"
      :finished-text="list.length === 0 ? '未找到结果' : '没有更多了'"
      @load="onLoad"
    >
      <div v-for="item in list" :key="item.patient_id" class="user-item">
        <p class="user-header">
          {{ item.external_patient_code }}
          <span class="user-date">{{ item.joinDate }}</span></p>
        <p class="user-info">{{ item.detail_content }}</p>
      </div>
    </van-list>
    <div class="footer-buttons">
      <van-button type="info" @click="openDialog">添加受试者入组</van-button>
      <van-button type="default" @click="logout">退出登录</van-button>
    </div>
    <dialog-add-user
      :show-dialog="showDialog"
      @refresh="refresh"
      @closeDialog="showDialog = false"/>
      <dialog-message
        :dialogVisible="showMessageDialog"
        :messageInfo="messageInfo"
        @closeDialog="showMessageDialog = false"
      />
  </div>
</template>
<script>
import { Toast, Dialog } from 'vant'
import { apiMbIndex } from '@/apis/ECTOP/api'
import router from "@/router/index.js";
import store from "@/store/index.js";
import DialogAddUser from './DialogAddUser.vue';
import DialogMessage from './DialogMessage.vue';
import dayjs from 'dayjs';
export default {
  data() {
    return {
      filter_condition: '',
      list: [],
      loading: false,
      finished: false,
      page: 1,
      size: 20,
      showDialog: false,
      showMessageDialog: false,
      messageInfo: {}
    };
  },
  components: {
    DialogAddUser,
    DialogMessage
  },
  methods: {
    onSearch() {
      this.page = 1; // 重置为第一页
      this.list = []; // 清空列表数据，以便加载新的搜索结果
      this.finished = false; // 重置完成状态，允许加载逻辑正常运行
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      const params = {
        page: this.page,
        size: this.size,
        filter_condition: this.filter_condition
      }
      apiMbIndex(params).then(res => {
        if (res.code === 200) {
          this.loading = false
          if (res.entities.length === 0) {
            this.finished = true; // 没有数据，结束加载更多的动作
          }
          res.entities.map(item => {
            item.joinDate = item.join_datetime ? dayjs(item.join_datetime).format('YYYY-MM-DD') : ''
          })
          this.list = [...this.list, ...res.entities]
          if (this.list.length < res.total) {
            this.page += 1; // 准备加载下一页
            this.finished = false; // 还没结束，可以继续加载
          } else {
            // 没有更多数据可加载
            this.finished = true;
          }
        } else {
          this.loading = false
          this.finished = true
          Toast.fail(res.message)
        }
      })
    },
    refresh(messageInfo) {
      this.showDialog = false
      this.showMessageDialog = true
      this.messageInfo = messageInfo
      if (messageInfo.type === 'addSuccess') {
        this.onSearch()
      }
    },
    openDialog() {
      this.showDialog = true
    },
    logout() {
      Dialog.confirm({
        title: '提示',
        message: '确定退出？',
        confirmButtonColor: '#0079fe'
      })
        .then(() => {
          store.dispatch("logout");
          router.push("/CTPJ00000051/mb/login");
        })
        .catch(() => {
        });
    }
  },
}
</script>
<style lang="scss" scoped>
.mb-index-container {
  .mb-index-title {
    text-align: center;
    font-weight: bold;
  }
  .user-list {
    background: #f2f2f2;
    padding: 10px;
    overflow-y: auto;
    height: calc(100vh - 176px);
    .van-list__error-text, .van-list__finished-text, .van-list__loading {
      height: 30px;
    }
  }
  .user-item {
    background: #fff;
    border-radius: 8px;
    padding: 0 12px;
    margin-bottom: 12px;
  }
  .user-header {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    position: relative;
    margin: 0;
    border-bottom: .5px solid #eee;
  }
  .user-date {
    color: #999;
    font-size: 14px;
  }
  .user-info {
    color: #999;
    font-size: 14px;
    line-height: 28px;
    margin: 0;
    padding: 12px 0;
    font-weight: 300;
  }
  .footer-buttons {
    position: fixed;
    background: #f2f2f2;;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    .van-button {
      height: 40px;
      border-radius: 20px;
      flex: 1;
      &:last-child {
        margin-left: 16px;
      }
    }
  }
}
</style>
<style lang="scss">
.mb-index-container {
  .van-field__body {
    input {
      font-size: 14px;
      -webkit-text-size-adjust: none;
    }
  }
}
</style>