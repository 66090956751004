<template>
  <div class="option-out-div chemotherapy-way">
    <DialogMessage
      :dialogVisible="dialogMessageVisible"
      :message-info="dialogMessageMsg"
      :isShowCancel="true"
      @confirm="handleDialogMessageConfirm"
      @cancel="handleDialogMessageCancel"
    />
    <el-select :value="fieldNew"
      @input="handleCheckChemotherapy"
      :class="fieldNew ? 'option-inside-true' : 'option-inside-false'">
      <el-option :value="1" label="CAPEOX"></el-option>
      <el-option :value="2" label="FOLFOX"></el-option>
    </el-select>
    <template>
      <div
        v-for="(item, index) in !fieldNew ? [] :
        (fieldNew === 2) ? row.questionaireMap[`CTPQ00000003_${type}`]
          : row.questionaireMap[`CTPQ00000004_${type}`]"
        :key="index"
        :class="item.questionnaire_status === 30 ? 'option-inside-true' : 'option-inside-false'"
        @click="openChemotherapy(row, index)"
        >
        <img v-if="item.questionnaire_status === 30" class="icon-submit" src="@/assets/image/ok.svg" />
        <img v-else class="icon-submit" src="@/assets/image/gene-none.svg" />
        {{ item.questionnaire_abbr_name }}
      </div>
      <div class="option-inside-false blank" v-if="!fieldNew"></div>
    </template>
  </div>
</template>
<script>
import { updateChemotherapyType } from '@/apis/user'
import DialogMessage from '../dialog/DialogMessage'
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    },
    type: {
      default: '',
      type: String
    },
    field: {
      default: '',
      type: String
    }
  },
  components: {
    DialogMessage
  },
  data() {
    return {
      fieldTemp: '',
      fieldNew: '',
      dialogMessageMsg: {
        type: 'warning',
        msg: '该受试者已经进入后续流程，请再次确认是否要切换治疗方式？'
      },
      dialogMessageVisible: false
    }
  },
  mounted() {
    this.fieldNew = this.row[`${this.type}_chemotherapy_type`]
    this.fieldTemp = this.fieldNew
  },
  methods: {
    // 修改化疗方式
    chemotherapyChangeHandle() {
      updateChemotherapyType({
        patient_id: this.row.patient_id,
        chemotherapy_index: this.type,
        chemotherapy_type: this.fieldNew
      }).then(res => {
      })
    },
    openChemotherapy(row, index) {
      const targetQ = this.fieldNew === 1 ? row.questionaireMap[`CTPQ00000004_${this.type}`][index] : row.questionaireMap[`CTPQ00000003_${this.type}`][index]
      targetQ.questionnaire_name_convert = '第' + targetQ.questionnaire_abbr_name + '周期' + targetQ.questionnaire_name.replace('周期', '')
      this.$emit('openQuestionaire', targetQ)
    },
    handleDialogMessageConfirm() {
      this.fieldNew = this.fieldTemp
      this.dialogMessageVisible = false
      this.chemotherapyChangeHandle()
    },
    handleDialogMessageCancel() {
      this.dialogMessageVisible = false
    },
    handleCheckChemotherapy(val) {
      if (this.fieldNew) {
        // 有值了，判断是否存在提交过问卷
        const questionaire = this.fieldNew === 2 ? this.row.questionaireMap[`CTPQ00000003_${this.type}`] : this.row.questionaireMap[`CTPQ00000004_${this.type}`]
        let isSubmit = false
        if (questionaire) {
          questionaire.map(ii => {
            if (ii.questionnaire_status === 30) {
              isSubmit = true
            }
          })
        }
        if (isSubmit) {
          // 需要提示
          this.fieldTemp = val
          this.dialogMessageVisible = true
        } else {
          this.fieldNew = val
          this.chemotherapyChangeHandle()
        }
      } else {
        this.fieldNew = val
        this.chemotherapyChangeHandle()
      }
    }
  }
}
</script>