import { requestGet, requestPost } from "@/utils/request"
import store from '@/store'

// 查询受试者公共页面导航信息
export function apiTaskNavigation(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/step-task/navigation`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 查询受试者任务对应的问卷详情
export function apiGetTaskQuestionnaire(params) {
  return new Promise((resolve, reject) => {
    requestGet(`${store.state.currentPrefix}/patient/step-task/questionnaire`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}
// 确认人口学资料、确认驱动基因、确认MRD状态
export function apiModifyTask(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/step-task/${params.apiType}/modify`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 查询人口学资料、查询驱动基因、查询MRD状态
export function apiGetTaskDetail(params) {
  return new Promise((resolve, reject) => {
    requestGet(`${store.state.currentPrefix}/patient/step-task/${params.apiType}/detail?patient_id=${params.patient_id}&task_code=${params.task_code}`, {}).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 校验筛查问卷是否通过
export function apiCanJoinGroup(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/patient/step-task/can-join-group`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}
// 查看分组结果
export function apiGetGroupResult(params) {
  return new Promise((resolve, reject) => {
    requestGet(`${store.state.currentPrefix}/patient/step-task/business-group/detail?patient_id=${params.patient_id}&task_code=${params.task_code}`, {}).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}