<template>
  <div class="incident">
    <el-dialog
      v-if="dialogVisible"
      title="不良事件上报记录"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog modal-incident"
      width="825px"
      append-to-body
      @close="closeDialog"
    >
      <div class="modal-main">
        <div v-for="(incident, index) in incidentList" :key="incident.incident_id" class="incident-block">
          <div class="left">{{ index + 1 > 9 ? index + 1 : '0' + (index + 1)}}</div>
          <div class="right">
            <div>
              <div>受试者：{{ incident.patient_name }}</div>
              <div>上报人：{{ incident.reporter_name }}</div>
              <div>上报时间：{{ handleFormat(incident.report_time) }}</div>
            </div>
            <div>
              <el-tooltip :open-delay="400" effect="dark" :content="incident.incident_content" placement="top">
                <div class="incident-content">不良事件内容：{{ incident.incident_content }}</div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div v-if="incidentList.length === 0" style="text-align: center;line-height: 100px">
          暂无不良事件上报记录
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="incidentAddVisible=true">上报不良事件</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="上报不良事件"
      :visible="incidentAddVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog modal-incident-add"
      width="437px"
      append-to-body
      @close="incidentAddVisible=false"
      @closed="incidentContent=''"
    >
      <div class="modal-main">
        <div>输入不良事件内容：</div>
        <el-input type="textarea" v-model="incidentContent" placeholder="请输入" maxlength="400" show-word-limit />
        <div><i class="el-icon-warning-outline" style="color: #008fdc;margin-right: 5px"/>
          {{ msg || `系统将会上报不良事件给本研究中心负责人、${hospitalName}负责人，以及研究总负责人。`}}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleIncidentAdd" style="border-radius">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getIncidentList, postIncidentAdd } from '@/apis/incident'
import { notifyError, notifySuccess } from "../../helpers/message"
import dayjs from 'dayjs'
export default {
  name: 'dialogIncident',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    patientId: {
      type: String,
      default: ''
    },
    hospitalName: {
      type: String,
      default: '北京肿瘤医院'
    },
    msg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      incidentList: [],
      incidentAddVisible: false,
      incidentContent: '',
      loading: false
    }
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    handleSearch() {
      if (this.patientId) {
        getIncidentList({ patient_ids: this.patientId }).then(res => {
          if (res.code === 200) {
            this.incidentList = res.entities
          }
        })
      }
    },
    handleIncidentAdd() {
      if (this.incidentContent) {
        const params = {
          patient_id: this.patientId,
          incident_content: this.incidentContent
        }
        this.loading = true
        postIncidentAdd(params).then(res => {
          if (res.code === 200) {
            notifySuccess('上报成功')
            this.incidentAddVisible = false
            this.handleSearch()
          }
        }).finally(() => {
          this.loading = false
        })
      } else {
        notifyError('请先输入')
      }
    },
    handleFormat(val) {
      if (val) {
        return dayjs(val).format('YYYY.MM.DD')
      } else {
        return '-'
      }
    }
  }
}
</script>
<style lang="scss">
.genetrials-common-dialog.modal-incident {
  .modal-main {
    max-height: 380px;
    min-height: 100px;
    overflow: auto;
    padding-bottom: 20px;
    box-sizing: border-box;
    border-radius: 0;
    .incident-block {
      display: flex;
      flex-direction: row;
      padding: 10px 23px;
      color: #333333;
      font-size: 12px;
      .left {
        width: 5%;
        font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
        font-weight: 650;
        margin-right: 11px;
        white-space: nowrap;
      }
      .right {
        width: 94%;
        display: flex;
        flex-direction: column;
        > div:first-child {
          display: flex;
          margin-bottom: 11px;
          > div:not(:last-of-type)::after {
            content: '|';
            color: #c9cdd4;
            margin-inline: 15px;
          }
        }
        > div:nth-child(2) {
          width: 100%;
          div.incident-content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      &:nth-child(2n+1) {
        background-color: #f8f9fb;
      }
      &:nth-child(2n) {
        background-color: #ffffff;
      }
    }
  }
}
.genetrials-common-dialog.modal-incident-add {
  .modal-main {
    padding-bottom: 30px;
    > div:nth-child(1) {
      margin-bottom: 10px;
      color: #333333;
    }
    > div:nth-child(3) {
      margin-top: 10px;
      color: #333333;
    }
  }
}
</style>
