const ctacHeaderName = {
  '/CTPJ00000001/home': '',
  '/CTPJ00000001/index': 'CTAC所有受试者',
  '/CTPJ00000001/detail/10': '高危2期低危3期',
  '/CTPJ00000001/detail/11': '高危2期低危3期 - ①ctDNA+, 辅助化疗',
  '/CTPJ00000001/detail/12': '高危2期低危3期 - ②ctDNA-, 辅助化疗',
  '/CTPJ00000001/detail/13': '高危2期低危3期 - ③ctDNA-, 观察',
  '/CTPJ00000001/detail/20': '高危3期',
  '/CTPJ00000001/detail/21': '高危3期 - ④ctDNA+, 辅助化疗',
  '/CTPJ00000001/detail/22': '高危3期 - ⑤ctDNA+, 二线治疗',
  '/CTPJ00000001/detail/23': '高危3期 - ⑥ctDNA-, 观察',
  '/CTPJ00000001/user': 'CTAC项目成员管理',
  '/CTPJ00000001/log': '日志记录'
}
const ctacSubHeaderName =  {
  '/CTPJ00000001/detail/11': '该组受试者将接受3个月化疗，3个月后复查ctDNA',
  '/CTPJ00000001/detail/12': '该组受试者将接受3个月化疗，3个月后复查ctDNA',
  '/CTPJ00000001/detail/13': '该组受试者将接受3个月观察，3个月后复查ctDNA',
  '/CTPJ00000001/detail/20': '该组受试者将在进行3个月的辅助化疗后再次检测ctDNA，根据结果再进行分组',
  '/CTPJ00000001/detail/21': '该组受试者在进行3个月辅助化疗后再次检测ctDNA为阳性，随机至本组，标准辅助化疗3个月，然后复查ctDNA',
  '/CTPJ00000001/detail/22': '该组受试者在进行3个月辅助化疗后再次检测ctDNA为阳性，随机至本组，二线方案化疗3个月，然后复查ctDNA',
  '/CTPJ00000001/detail/23': '该组受试者在进行3个月的辅助化疗后再次检测ctDNA，结果为阴性，停止化疗，观察3个月后复查ctDNA'
}
const ctacMenus = [
  {
    level: 1,
    label: 'CTAC所有受试者',
    url: '/CTPJ00000001/index',
    index: 'patientIndex',
    business_group_code: 'CTPJ00000001'
  },
  {
    level: 2,
    label: '高危2期低危3期',
    url: '/CTPJ00000001/detail',
    index: 'ctac_group_10',
    business_group_code: 'CTPJ00000003'
  },
  {
    level: 3,
    label: '①ctDNA+, 辅助化疗',
    url: '/CTPJ00000001/detail',
    index: 'ctac_group_11',
    business_group_code: 'CTPJ00000004'
  },
  {
    level: 3,
    label: '②ctDNA-, 辅助化疗',
    url: '/CTPJ00000001/detail',
    index: 'ctac_group_12',
    business_group_code: 'CTPJ00000005'
  },
  {
    level: 3,
    label: '③ctDNA-, 观察',
    url: '/CTPJ00000001/detail',
    index: 'ctac_group_13',
    business_group_code: 'CTPJ00000006'
  },
  {
    level: 2,
    label: '高危3期',
    url: '/CTPJ00000001/detail',
    index: 'ctac_group_20',
    business_group_code: 'CTPJ00000007'
  },
  {
    level: 3,
    label: '④ctDNA+, 辅助化疗',
    url: '/CTPJ00000001/detail',
    index: 'ctac_group_21',
    business_group_code: 'CTPJ00000008'
  },
  {
    level: 3,
    label: '⑤ctDNA+, 二线治疗',
    url: '/CTPJ00000001/detail',
    index: 'ctac_group_22',
    business_group_code: 'CTPJ00000009'
  },
  {
    level: 3,
    label: '⑥ctDNA-, 观察',
    url: '/CTPJ00000001/detail',
    index: 'ctac_group_23',
    business_group_code: 'CTPJ00000010'
  },
  {
    level: 1,
    label: 'CTAC项目成员管理',
    url: '/CTPJ00000001/user',
    index: 'userIndex'
  },
  {
    level: 1,
    label: '操作记录',
    url: '/CTPJ00000001/log',
    index: 'logIndex'
  }
]
const nsclcMenus = [
  {
    level: 1,
    label: '所有受试者',
    url: '/CTPJ00000050/index',
    index: 'patientIndex',
    group_type: '0'
  },
  {
    level: 2,
    label: '标准治疗组',
    url: '/CTPJ00000050/standard',
    index: 'CTPJ00000050_standard',
    group_type: '100'
  },
  {
    level: 2,
    label: 'MRD指导治疗组',
    url: '/CTPJ00000050/MRD',
    index: 'CTPJ00000050_mrd',
    group_type: '200'
  },
  {
    level: 1,
    label: '项目成员管理',
    url: '/CTPJ00000050/user',
    index: 'userIndex'
  },
  {
    level: 1,
    label: '操作记录',
    url: '/CTPJ00000050/log',
    index: 'logIndex'
  }
]
const ectopMenus = [
  {
    level: 1,
    label: '所有受试者',
    url: '/CTPJ00000051/index',
    index: 'patientIndex',
    group_type: '0'
  },
  {
    level: 1,
    label: '项目成员管理',
    url: '/CTPJ00000051/user',
    index: 'userIndex'
  },
  {
    level: 1,
    label: '操作记录',
    url: '/CTPJ00000051/log',
    index: 'logIndex'
  }
]
const info = {
  CTPJ00000001: {
    headerName: ctacHeaderName,
    subHeaderName: ctacSubHeaderName,
    menus: ctacMenus
  },
  CTPJ00000050: {
    menus: nsclcMenus
  },
  CTPJ00000051: {
    menus: ectopMenus
  }
}
export function getHeaderInfo(type) {
  return {
    headerName: info[type]?.headerName || {},
    subHeaderName: info[type]?.subHeaderName || {}
  }
}
export function getMenuInfo(type) {
  return info[type]?.menus || []
}