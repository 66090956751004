<template>
  <el-dialog
    title="添加研究中心（权限）"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="genetrials-common-dialog modal-edit-group"
    width="700px"
    append-to-body
    @close="closeDialog"
  >
    <el-checkbox-group v-if="groupObj && Object.keys(groupObj).length > 0" v-model="selectGroupIds">
      <el-checkbox v-for="key in Object.keys(groupObj)" :key="key" :label="key">
        {{ groupObj[key] && groupObj[key][0] && groupObj[key][0].group_name }}
      </el-checkbox>
    </el-checkbox-group>
    <p style="text-align: center;" v-else>暂无数据</p>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    currentSelectGroupIds: {
      type: String,
      default: ''
    },
    groupObj: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectGroupIds: []
    }
  },
  watch: {
    currentSelectGroupIds(val) {
      if (val) {
        this.selectGroupIds = val.split(', ')
      } else {
        this.selectGroupIds = []
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    handleConfirm() {
      this.$emit('handleConfirm', this.selectGroupIds)
      this.closeDialog()
      this.selectGroupIds = []
    }
  }
}
</script>

<style lang="scss">
.genetrials-common-dialog.modal-edit-group {
  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    .el-checkbox {
      flex: 0 0 33.3%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
</style>