<template>
  <div class="patient-add-new">
    <el-dialog
      :title="step_1_title"
      :visible="step_1"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog patient-add-dialog step_1"
      width="953px"
      append-to-body
      :before-close="closeAllDialog"
    >
      <div class="modal-main">
        <el-form
          label-position="top"
          class="patient-add-form"
          size="mini"
          :model="step_1_form"
          :rules="step_1_rules"
          ref="step_1_form"
          :disabled="actionType === '2'"
        >
          <div class="define-form-item">
            <span class="sortNo">01</span>
            <el-form-item label="受试者姓名(缩写)" prop="name">
              <el-input style="display: inline-block;width: 220px" v-model="step_1_form.name" /><span class="tips">限输入四个大写字母，例如李明：LIMI；刘兴明：LXMI；欧阳舒淇：OYSQ</span>
            </el-form-item>
          </div>
          <div class="define-form-item">
            <span class="sortNo">02</span>
            <el-form-item label="负责人" prop="principal_id">
              <el-radio-group v-model="step_1_form.principal_id" style="width: 100%">
                <el-row>
                  <el-col :span="8" v-for="user in catcUsersList" :key="user.user_id" style="line-height: 22px">
                    <el-radio :label="user.user_id">{{ user.display_name + '(' + (user.institute_name || '-') + ')' }}</el-radio>
                  </el-col>
                </el-row>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="define-form-item">
            <span class="sortNo">03</span>
            <el-form-item label="受试者性别" prop="gender">
              <el-radio-group v-model="step_1_form.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="define-form-item">
            <span class="sortNo">04</span>
            <el-form-item label="出生时间" prop="date_of_birth">
              <el-date-picker @focus="handleDatePickerFocus" placeholder="年-月-日" v-model="step_1_form.date_of_birth" value-format="yyyy-MM-dd" :default-value="Date.parse('1965')" popper-class="define-patient-add-date-picker" />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="actionType !== '3'" type="primary" @click="handleStep('1', '2', 'next')">下一步</el-button>
        <el-button v-else type="primary" @click="handlePatientModify(true)">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="step_2_title"
      :visible="step_2"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog patient-add-dialog step_2"
      width="953px"
      append-to-body
      :before-close="closeAllDialog"
    >
      <div class="modal-main">
        <el-form
          label-position="top"
          class="patient-add-form"
          size="mini"
          :model="step_2_form"
          :rules="step_2_rules"
          ref="step_2_form"
          :disabled="actionType === '2'"
          :validate-on-rule-change="false"
        >
          <template v-for="(question, index) in question_2.question_list">
            <div v-if="question.frontend_question_type === 1" :key="question.question_id" class="define-form-description">
              <div v-html="question.question_description"></div>
            </div>
            <div v-else class="define-form-item" :key="question.question_id">
              <span class="sortNo">{{ handleSortNo('2', index) }}</span>
              <el-form-item :label="question.question_title" :prop="question.question_code">
                <template v-if="question.frontend_question_type === 10">
                  <el-radio-group v-model="step_2_form[question.question_code]">
                    <el-radio v-for="option in question.option_list" :key="option.option_id" :label="option.option_code">{{ option.option_name }}</el-radio>
                  </el-radio-group>
                </template>
                <template v-else-if="question.frontend_question_type === 200">
                  <el-date-picker placeholder="年-月-日" v-model="step_2_form[question.question_code]" value-format="yyyy-MM-dd" :picker-options="['CTQQ00090021', 'CTQQ00090022'].includes(question.question_code) ? pickerOptions : null" />
                </template>
              </el-form-item>
            </div>
          </template>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleStep('2', '1', 'back')">上一步</el-button>
        <el-button type="primary" @click="handleStep('2', '3', 'next')" :loading="loading">下一步</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="step_3_title"
      :visible="step_3"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog patient-add-dialog step_3"
      width="953px"
      append-to-body
      :before-close="closeAllDialog"
    >
      <div class="modal-main">
        <el-form
          label-position="top"
          class="patient-add-form"
          size="mini"
          :model="step_3_form"
          :rules="step_3_rules"
          ref="step_3_form"
          :disabled="actionType === '2'"
        >
          <template v-for="(question, index) in question_3.question_list">
            <div v-if="question.frontend_question_type === 1" :key="question.question_id" class="define-form-description">
              <div v-html="question.question_description"></div>
            </div>
            <div v-else class="define-form-item" :key="question.question_id">
              <span class="sortNo">{{ handleSortNo('3', index) }}</span>
              <el-form-item :label="question.question_title" :prop="question.question_code">
                <template v-if="question.frontend_question_type === 10">
                  <el-radio-group v-model="step_3_form[question.question_code]" :class="handleFrontendCoordDetail(question)">
                    <el-radio v-for="option in question.option_list" :key="option.option_id" :label="option.option_code">{{ option.option_name }}</el-radio>
                  </el-radio-group>
                </template>
                <template v-else-if="question.frontend_question_type === 20">
                  <el-checkbox-group v-model="step_3_form[question.question_code]">
                    <el-checkbox v-for="option in question.option_list" :key="option.option_id" :label="option.option_code">{{ option.option_name }}</el-checkbox>
                  </el-checkbox-group>
                </template>
                <template v-else-if="question.frontend_question_type === 100">
                  <el-input-number :min="0" step-strictly v-model="step_3_form[question.question_code]" />
                </template>
                <template v-else-if="question.frontend_question_type === 510">
                  <div class="define-matrix-radio">
                    <el-row>
                      <el-col :span="6" key="empty">&nbsp;</el-col>
                      <el-col :span="6" v-for="option in question.children_list[0].option_list" :key="option.option_id" class="col-justify-center">
                        {{ option.option_name }}
                      </el-col>
                    </el-row>
                    <el-row v-for="child in question.children_list" :key="child.question_id">
                      <el-col :span="6">{{ child.question_title}}</el-col>
                      <el-col :span="18">
                        <el-radio-group v-model="step_3_form[child.question_code]" style="width: 100%">
                          <el-col :span="8" v-for="option in child.option_list" :key="option.option_id" class="col-justify-center">
                            <el-radio :label="option.option_code">&nbsp;</el-radio>
                          </el-col>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </el-form-item>
            </div>
          </template>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleStep('3', '2', 'back')">上一步</el-button>
        <el-button type="primary" @click="handleConfirm" v-if="actionType === '1'" :loading="loading">确定</el-button>
      </span>
    </el-dialog>
    <DialogMessage
      :dialogVisible="step_error"
      :message-info="step_error_msg"
      @confirm="handleDailogMessageConfirm"
      @cancel="handleDailogMessageCancel"
    />
  </div>
</template>
<script>
import { getQuestionaireInfo, saveResult, submitResult, getQuestionnareResult } from '@/apis/questionnaire'
import { getCatcUsers, patientPaged, patientAdd, patientModify } from '@/apis/user'
import { notifyError, notifySuccess} from '@/helpers/message'
import DialogMessage from './DialogMessage'
import dayjs from 'dayjs'
import store from "@/store/index.js";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    actionType: {
      type: String,
      default: '1' // 1：新建；2：查看；3：编辑
    },
    fromPatientId: {
      type: String,
      default: ''
    }
  },
  components: {
    DialogMessage
  },
  data() {
    return {
      catcUsersList: [],
      step_1: true,
      step_2: false,
      step_3: false,
      question_2: {},
      question_3: {},
      step_1_form: {
        name: '',
        principal_id: '',
        gender: '',
        date_of_birth: ''
      },
      step_2_form: {},
      step_3_form: {},
      step_2_exclude: [],
      step_3_exclude: [],
      step_1_rules: {
        name: [
          {
            required: true, message: '请输入', trigger: 'change'
          }
        ],
        principal_id: [
          {
            required: true, message: '请选择', trigger: 'change'
          }
        ],
        gender: [
          {
            required: true, message: '请选择', trigger: 'change'
          }
        ],
        date_of_birth: [
          {
            required: true, message: '请输入', trigger: 'change'
          }
        ]
      },
      step_2_questionnaire_code: 'CTPQ00000009',
      step_2_result_id: '',
      step_3_questionnaire_code: 'CTPQ00000011',
      step_3_result_id: '',
      patient_id: '',
      step_error: false,
      step_error_msg: {},
      question_type_set: {},
      loading: false,
      isAddSuccess: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  computed: {
    step_2_rules() {
      if (this.question_2?.question_list) {
        let info = {}
        this.question_2.question_list.map(ii => {
          if (ii.frontend_question_type !== 1) {
            if (ii.required_type === 1) {
              info[ii.question_code] = [
                {
                  required: true, message: this.handleTips(ii.frontend_question_type), trigger: 'change'
                }
              ]
            } else if (ii.required_type === 3 && ii.question_code === 'CTQQ00090021') {
              if (this.step_2_form['CTQQ00090020'] === '10') {
                info[ii.question_code] = [
                  {
                    required: true, message: this.handleTips(ii.frontend_question_type), trigger: 'change'
                  }
                ]
              } else {
                info[ii.question_code] = [
                  {
                    required: false, message: this.handleTips(ii.frontend_question_type), trigger: 'change'
                  }
                ]
              }
            } else {
              info[ii.question_code] = []
            }
          }
        })
        return info
      } else {
        return {}
      }
    },
    step_3_rules() {
      if (this.question_3?.question_list) {
        let info = {}
        this.question_3.question_list.map(ii => {
          if (ii.required_type === 1) {
            let trigger = 'change'
            if (ii.frontend_question_type === 100) {
              trigger = 'blur'
            }
            info[ii.question_code] = [
              {
                required: true, message: this.handleTips(ii.frontend_question_type), trigger: trigger
              }
            ]
            if (ii.frontend_question_type === 510) {
              info[ii.question_code] = [
                {
                  required: true, validator: this.validateRadioGroup, trigger: 'qwer'
                }
              ]
            }
          } else {
            info[ii.question_code] = []
          }
        })
        return info
      } else {
        return {}
      }
    },
    step_1_title() {
      if (this.actionType === '1') {
        return '新建受试者（1/3）'
      } else if (this.actionType === '2') {
        return '受试者（1/3）'
      } else {
        return '编辑受试者'
      }
    },
    step_2_title() {
      if (this.actionType === '1') {
        return '新建受试者（2/3）'
      } else if (this.actionType === '2') {
        return '受试者（2/3）'
      } else {
        return ''
      }
    },
    step_3_title() {
      if (this.actionType === '1') {
        return '新建受试者（3/3）'
      } else if (this.actionType === '2') {
        return '受试者（3/3）'
      } else {
        return ''
      }
    }
  },
  watch: {
    step_2_result_id: {
      handler(val) {
        if (val) {
          // 新建受试者2/3
          this.handleQuestionaire('2', val)
        }
      }
    },
    step_3_result_id: {
      handler(val) {
        if (val) {
          // 新建受试者3/3
          this.handleQuestionaire('3', val)
        }
      }
    },
    patient_id: {
      handler(val) {
        if (val) {
          patientPaged({ patient_id: this.patient_id }).then(result => {
            if (result.code === 200) {
              const info = result.entities[0]
              if (this.actionType !== '3') {
                // 编辑时不可查看问卷信息
                info.questionnaire_list.map(ii => {
                  if (ii.questionnaire_code === this.step_2_questionnaire_code) {
                    this.step_2_result_id = ii.result_id
                  } else if (ii.questionnaire_code === this.step_3_questionnaire_code) {
                    this.step_3_result_id = ii.result_id
                  }
                })
              }
              if (this.actionType !== '1') {
                // 查看、编辑时获取受试者基本信息
                this.step_1_form['name'] = info['name']
                this.step_1_form['principal_id'] = info['principal_id']
                this.step_1_form['gender'] = info['gender']
                this.step_1_form['date_of_birth'] = info['date_of_birth']
              }
            } else {
              notifyError('受试者问卷信息获取失败')
            }
          })
        }
      },
      immediate: true
    }
  },
  created() {
    // 新建受试者1/3
    getCatcUsers().then(res => {
      if (res.code === 200) {
        let list = res.entities || []
        this.catcUsersList = list.filter(ii => {
          if (ii.roles && ii.roles.some(yy => yy.role_code === 'user')) {
            return true
          } else return false
        })
        if (this.actionType == '1') {
          // 新建时，设置默认值
          const find = this.catcUsersList.filter(ii => ii.user_id === store.state.user.userId)
          if (find.length > 0) {
            this.step_1_form.principal_id = store.state.user.userId
          }
        }
      }
    })
  },
  mounted() {
    if (this.actionType !== '1') {
      this.patient_id = this.fromPatientId
    }
  },
  methods: {
    handleConfirm() {
      this.$refs['step_3_form'].validate(async valid => {
        if (valid) {
          await this.handleStep3Submit()
        } else {
          this.handleErrorMsg()
        }
      })
    },
    closeAllDialog() {
      this.$emit('closeDialog')
    },
    handleStep(from, to, direction) {
      if (this.actionType === '1') {
        // 新建时，下一步需要校验
        if (direction === 'next') {
          this.$refs[`step_${from}_form`].validate(async valid => {
            if (valid) {
              if (this.actionType === '1') {
                // 新建流程
                if (from === '1') {
                  if (this.patient_id) {
                    // 已经创建过
                    // 进行修改
                    const result = await this.handlePatientModify()
                    if (!result) return
                  } else {
                    // 新建受试者
                    const resultNew = await patientAdd(this.step_1_form)
                    if (resultNew.code === 200) {
                      this.patient_id = resultNew.entity.patient_id
                    } else {
                      notifyError('创建受试者失败')
                      return
                    }
                  }
                  // 计算周岁
                  const age = this.handleCalAge(this.step_1_form['date_of_birth'])
                  if (age >= 18 && age <= 75) {
                    this.$set(this.step_2_form, 'CTQQ00090002', '10')
                  } else {
                    this.$set(this.step_2_form, 'CTQQ00090002', '20')
                  }
                } else if (from === '2') {
                  let validate = await this.handleStep2Submit()
                  if (!validate) {
                    return
                  }
                }
              }
              this.handleNextOrBackStep(from, to)
            } else {
              this.handleErrorMsg()
            }
          })
        } else {
          this.handleNextOrBackStep(from, to)
        }
      } else {
        this.handleNextOrBackStep(from, to)
      }
    },
    handleNextOrBackStep(from, to) {
      this['step_' + from] = false
      this['step_' + to] = true
    },
    handleSortNo(step, index) {
      if (step === '3') {
        return index + 1 > 9 ? index + 1 : '0' + (index + 1)
      } else {
        let findIndex = this.step_2_exclude.findIndex((val, ii, arr) => {
          if (ii + 1 >= arr.length) {
            return true
          } else if (val < index && index < arr[ii + 1]) {
            return true
          } else {
            return false
          }
        })
        let temp = index + 1 - (findIndex + 1)
        return temp > 9 ? temp : '0' + temp
      }
    },
    handleTips(type) {
      if (type === 100 || type === 200) {
        return '请输入'
      } else {
        return '请选择'
      }
    },
    handleFrontendCoordDetail(question) {
      if (question.frontend_question_type === 10) {
        if (question.frontend_coord_detail) {
          const info = JSON.parse(question.frontend_coord_detail)
          if (info['max_col_count'] && info['max_col_count'] == 1) {
            return 'define-radio-group-vertical'
          } else {
            return ''
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    validateRadioGroup(rule, value, callback) {
      const question = this.question_3.question_list.filter(ii => ii.question_code === rule.field)
      let isTrue = true
      question[0].children_list.map(ii => {
        if (!this.step_3_form[ii.question_code]) {
          isTrue = false
        }
      })
      if (isTrue) {
        callback()
      } else {
        callback(new Error('请选择'))
      }
    },
    async handleStep2Submit() {
      this.loading = true
      const params = {
        result_info: {
          result_id: this.step_2_result_id
        },
        answer_list: []
      }
      let temp = []
      Object.keys(this.step_2_form).forEach(key => {
        if (this.question_type_set[key] == 100 || this.question_type_set[key] == 200) {
          temp.push({
            multiple_index: 0,
            filled_key: "",
            question_code: key,
            filled_description: "",
            filled_value: this.step_2_form[key],
            parent_code: "",
            sort_no: 0
          })
        } else {
          if (Array.isArray(this.step_2_form[key])) {
            // 多选
            this.step_2_form[key].map(ii => {
              temp.push({
                multiple_index: 0,
                filled_key: ii,
                question_code: key,
                filled_description: "",
                filled_value: "",
                parent_code: "",
                sort_no: 0
              })
            })
          } else {
            // 单选
            temp.push({
              multiple_index: 0,
              filled_key: this.step_2_form[key],
              question_code: key,
              filled_description: "",
              filled_value: "",
              parent_code: "",
              sort_no: 0
            })
          }
        }
      })
      params['answer_list'] = temp
      const result = await submitResult(params)
      this.loading = false
      if (result.code === 200) {
        if (result.entity?.questionnaire_info?.point == 100) {
          // 问卷1提交成功
          return true
        } else {
          this.step_error_msg = {
           type: 'warning',
           msg: result.message
          }
          this.step_error = true
          return false
        }
      } else {
        this.step_error_msg = {
          type: 'warning',
          msg: '提交失败'
        }
        this.step_error = true
        return false
      }
    },
    async handleStep3Submit() {
      this.loading = true
      const params = {
        result_info: {
          result_id: this.step_3_result_id
        },
        answer_list: []
      }
      let temp = []
      Object.keys(this.step_3_form).forEach(key => {
        if (this.question_type_set[key] == 100 || this.question_type_set[key] == 200) {
          temp.push({
            multiple_index: 0,
            filled_key: "",
            question_code: key,
            filled_description: "",
            filled_value: this.step_3_form[key] + '',
            parent_code: "",
            sort_no: 0
          })
        } else {
          if (Array.isArray(this.step_3_form[key])) {
            // 多选
            this.step_3_form[key].map(ii => {
              temp.push({
                multiple_index: 0,
                filled_key: ii,
                question_code: key,
                filled_description: "",
                filled_value: "",
                parent_code: "",
                sort_no: 0
              })
            })
          } else {
            // 单选
            temp.push({
              multiple_index: 0,
              filled_key: this.step_3_form[key],
              question_code: key,
              filled_description: "",
              filled_value: "",
              parent_code: "",
              sort_no: 0
            })
          }
        }
      })
      params['answer_list'] = temp
      const result = await submitResult(params)
      this.loading = false
      if (result.code !== 200) {
        this.isAddSuccess = false
        this.step_error_msg = {
          type: 'warning',
          msg: result.message
        }
        this.step_error = true
        return false
      } else {
        this.isAddSuccess = true
        this.step_error_msg = {
          type: 'addSuccess',
          msg: result.message
        }
        this.step_error = true
        return true
      }
    },
    async handlePatientModify(isNeed = false) {
      const bodyParams = {
        ...this.step_1_form,
        patient_id: this.patient_id
      }
      if (isNeed) {
        this.$refs['step_1_form'].validate(async valid => {
          if (valid) {
            const resultUpdate = await patientModify(bodyParams)
            if (resultUpdate.code !== 200) {
              notifyError('更新受试者失败')
            } else {
              notifySuccess('更新受试者成功')
              this.$emit('refresh')
              this.closeAllDialog()
            }
          } else {
            this.handleErrorMsg()
          }
        })
      } else {
        const resultUpdate = await patientModify(bodyParams)
        if (resultUpdate.code !== 200) {
          notifyError('更新受试者失败')
          return false
        } else {
          notifySuccess('更新受试者成功')
          return true
        }
      }
    },
    async handleQuestionaire(kind, result_id) {
      // kind: 2、3
      // 新建受试者2/3
      const result = await getQuestionaireInfo(this[`step_${kind}_questionnaire_code`], result_id)
      if (result.code === 200) {
        this[`question_${kind}`] = result.entity.questionnaire_info || []
        this[`question_${kind}`].question_list.map((ii, index) => {
          if (ii.frontend_question_type === 1) {
            this[`step_${kind}_exclude`].push(index)
          }
          if (ii.frontend_question_type === 20 && this.actionType === '1') {
            // 新建时，多选设置初始值为[]
            this.$set(this[`step_${kind}_form`], ii.question_code, [])
          }
          this.question_type_set[ii.question_code] = ii.frontend_question_type
        })
      }
      if (this.actionType === '2') {
        // 获取问卷已提交答案
        const otherResult = await getQuestionnareResult(result_id)
        if (otherResult.code === 200) {
          const answerInfo = otherResult.entities[0].answer_list
          const answerKeyValue = {}
          answerInfo.map(ii => {
            let newVal = ii.filled_key || ii.filled_value
            if (answerKeyValue[ii.question_code]) {
              // 值已记录
              let temp = answerKeyValue[ii.question_code]
              if (typeof temp === 'string') {
                // 表示原题是个多选
                answerKeyValue[ii.question_code] = [temp, newVal]
              } else if (Array.isArray(temp)) {
                // 表示原题是个多选
                temp.push(newVal)
                answerKeyValue[ii.question_code] = temp
              }
            } else {
              // 值没记录
              answerKeyValue[ii.question_code] = newVal
            }
          })
          Object.keys(answerKeyValue).forEach(key => {
            if (this.question_type_set[key] && this.question_type_set[key] === 20) {
              // 多选值类型必须为[]
              if (Array.isArray(answerKeyValue[key])) {
                this.$set(this[`step_${kind}_form`], key, answerKeyValue[key])
              } else {
                this.$set(this[`step_${kind}_form`], key, [answerKeyValue[key]])
              }
            } else {
              this.$set(this[`step_${kind}_form`], key, answerKeyValue[key])
            }
          })
        }
      }
    },
    handleDailogMessageConfirm() {
      this.step_error = false
      if (this.isAddSuccess) {
        this.$emit('refresh')
        this.closeAllDialog()
      }
    },
    handleDailogMessageCancel() {
      this.step_error = false
      if (this.isAddSuccess) {
        this.$emit('refresh')
        this.closeAllDialog()
      }
    },
    handleCalAge(birthday) {
      // 计算周岁
      const birth = dayjs(birthday)
      const now = dayjs()
      const diffYear = now.diff(birth, "year")
      const isBirthdayPassed = now.diff(dayjs(`${now.year()}-${birth.month() + 1}-${birth.date()}`), "day") >= 0
      const age = isBirthdayPassed ? diffYear : diffYear - 1
      if (age < 0) return 0
      return age
    },
    handleDatePickerFocus(e) {
      if (this.step_1_form['date_of_birth']) return
      this.$nextTick(() => {
        e.picker && e.picker.showYearPicker()
      })
    },
    handleErrorMsg() {
      this.$message({
        type: 'error',
        message: '您有必填字段未填写。'
      })
    }
  }
}
</script>
<style lang="scss">
.genetrials-common-dialog.patient-add-dialog {
  .modal-main {
    background: #fff;
    border-radius: 8px;
    font-size: 12px;
    .patient-add-form {
      > .define-form-item {
        padding: 5px 20px 15px;
        font-size: 12px;
        display: flex;
        background: #ffffff;
        .el-form-item {
          flex: 1;
          margin-bottom: 0;
          .el-form-item__label {
            line-height: 16px;
            padding-bottom: 5px;
          }
          .el-form-item__content {
            line-height: 24px;
            .tips {
              color: #999;
              margin-left: 10px;
              font-size: 12px;
            }
            .define-matrix-radio {
              background: #ffffff;
              > .el-row {
                padding: 5px 20px;
                border-bottom: 1px solid #f2f2f2;
              }
              .col-justify-center {
                text-align: center;
              }
            }
            .define-radio-group-vertical {
              display: flex;
              flex-direction: column;
              > label.el-radio {
                line-height: 1.5;
              }
            }
          }
        }
        .sortNo {
          font-weight: 650;
          color: #0079fe;
          margin-right: 11px;
        }
      }
      > .define-form-item:nth-child(2n+1) {
        background: #f8f9fb;
      }
      > .define-form-description {
        padding-left: 20px;
        line-height: 36px;
        background-color: #0079fe;
        color: #ffffff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        p {
          margin: 0;
        }
      }
    }
  }
}
.el-date-picker.define-patient-add-date-picker {
  .el-year-table td {
    padding: 28px 3px;
  }
  .el-month-table td {
    padding: 21px 0;
  }
}
</style>
