<template>
  <patient-list pageType="4"></patient-list>
</template>

<script scoped>
import PatientList from "./components/PatientList";

export default {
  name: "dis-enroll",
  components: {
    PatientList,
  }
};
</script>

<style>
</style>