export default {
  type: 'enroll',
  config: [
  {
    key: 'stage',
    label: '受试者分期',
    type: 2,
    span: 7,
    options: [
      {
        label: '10',
        value: 'IA'
      },
      {
        label: '20',
        value: 'IB'
      }
    ],
  },
  {
    key: 'pathologic_subtype',
    label: '受试者病理亚型',
    type: 2,
    span: 7,
    options: [
      {
        label: '20',
        value: '鳞癌'
      },
      {
        label: '10',
        value: '非鳞癌'
      }
    ],
  }
]}